import { Page } from "@/app/types/page";
import { HttpClient } from "@/deputy/utils/httpClient";
import {
    DeputiesApi,
    DeputiesCursor,
    DeputiesPage,
    Deputy, DeputyAvatarRule,
    DeputyCreationRule,
    DeputyDeletingRule,
    DeputyEditingRule
} from "@/app/types/deputy";

export class DeputiesHttpApi implements DeputiesApi {
    private readonly client: HttpClient = new HttpClient();

    public async list(cursor: DeputiesCursor): Promise<DeputiesPage> {
        const response = await this.client.get(`/deputy?${cursor.toQuery()}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new DeputiesPage([], Page.fromJson(response));
        }

        return new DeputiesPage(
            response.data.map((deputy) => {
                return Deputy.fromJson(deputy);
            }),
            Page.fromJson(response)
        );
    }

    public async create(rule: DeputyCreationRule): Promise<Deputy> {
        const response = await this.client.post("/deputy", rule.toJson());
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new Deputy();
        }

        return Deputy.fromJson(response.data[0]);
    }

    public async edit(rule: DeputyEditingRule): Promise<Deputy> {
        const response = await this.client.put(
            `/deputy/${rule.id}`,
            rule.toJson()
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new Deputy();
        }

        return Deputy.fromJson(response.data[0]);
    }

    public async delete(rule: DeputyDeletingRule): Promise<boolean> {
        const response = await this.client.delete(`/deputy/${rule.id}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }

    public async avatar(rule: DeputyAvatarRule): Promise<boolean> {
        const response = await this.client.sendFile(`/deputy/${rule.deputyId}/avatar`, rule.avatar[0], new Headers());
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }
}
