














































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/app/components/common/Button.vue";
import { Manager, ManagerDeletingRule } from "@/app/types/manager";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import ManagerDeletingWindow from "@/app/components/popups/ManagerDeletingWindow.vue";
import { MANAGERS_ACTIONS } from "@/app/store/modules/managers";
import ManagerEditingWindow from "@/app/components/popups/ManagerEditingWindow.vue";

@Component({ components: { Button } })
export default class ManagerRow extends Vue {
    @Prop({ default: new Manager() })
    private readonly row: Manager;

    private async openEditingPopup(): Promise<void> {
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_ID_RULE,
            this.row.id
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_FIRST_NAME_RULE,
            this.row.firstName
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_MIDDLE_NAME_RULE,
            this.row.middleName
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_LAST_NAME_RULE,
            this.row.lastName
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE,
            this.row.isFinishedRegistration
        );

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(ManagerEditingWindow.componentName, true, false)
        );
    }

    private openDeletingPopup(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(ManagerDeletingWindow.componentName, true, false)
        );
        this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGER_DELETING_RULE,
            new ManagerDeletingRule(this.row.id)
        );
    }
}
