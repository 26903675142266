
























import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { LoaderState } from "@/app/types/loaderState";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";
import { PARTIES_ACTIONS, PARTIES_GETTERS } from "@/app/store/modules/parties";

@Component({ components: { Dropdown, CityFilterInputField, Button } })
export default class PartiesFilterDropdownInner extends Vue {
    private get cityOption(): DropdownItemModel {
        return this.$store.getters[PARTIES_GETTERS.GET_PARTIES_CURSOR_CITY];
    }

    private async selectCity(value): Promise<void> {
        if (this.cityOption.id === value.id) {
            return;
        }

        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            value
        );
    }

    private async filter(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            this.$emit("toggleLoader", true);
            await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.$emit("toggleLoader", false);
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState().Bounded()
        );
        this.$emit("toggleLoader", false);

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY,
            false
        );
    }
}
