export class File {
    public constructor(
        public id: string = "",
        public name: string = "",
        public created: Date = new Date(),
        public extension: string = "",
        public downloadURL: string = ""
    ) {}

    public static fromJson(json: any): File {
        if (json === null || json === undefined) {
            return new File();
        }

        return new File(
            json.id,
            json.name,
            json.created,
            json.extension,
            json.download_url
        );
    }
}
