












import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Loader extends Vue {
    @Prop({ default: false })
    private readonly visibility: boolean;

    @Prop({ default: false })
    private readonly lighterBackground: boolean;
}
