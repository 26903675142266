






import { Component, Prop, Vue } from "vue-property-decorator";

import { ErrorState } from "@/app/types/signInError";

@Component({})
export default class SignInErrorMessage extends Vue {
    @Prop({ default: new ErrorState() })
    private readonly errorState: ErrorState;
}
