












import { Component, Vue } from "vue-property-decorator";

import CitizensFilterDropdownInner from "@/app/components/citizens/CitizensFilterDropdownInner.vue";
import FilterDropdown from "@/app/components/common/FilterDropdown.vue";

@Component({ components: { FilterDropdown, CitizensFilterDropdownInner } })
export default class CitizensFilterPanel extends Vue {
    private get citizensCount(): number {
        return this.$store.state.citizensModule.page.totalCount;
    }

    private toggleLoader(value): void {
        if (value) {
            this.$emit("toggleLoader", true);

            return;
        }

        setTimeout(() => {
            this.$emit("toggleLoader", false);
        }, 700);
    }

    private get citizensCountLabel(): string {
        const citizens = String(this.citizensCount);

        if (this.citizensCount === 1) {
            return "мешканець";
        }

        if (this.citizensCount > 1 && this.citizensCount < 5) {
            return "мешканця";
        }

        if (
            citizens[citizens.length - 1] === "1" &&
            citizens[citizens.length - 2] !== "1"
        ) {
            return "мешканець";
        }

        if (
            +citizens[citizens.length - 1] > 1 &&
            +citizens[citizens.length - 1] < 5 &&
            citizens[citizens.length - 2] !== "1"
        ) {
            return "мешканця";
        }

        return "мешканців";
    }
}
