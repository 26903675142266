



































































import { Component, Vue, Watch } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";

import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";
import {
    DEPUTIES_ACTIONS,
    DEPUTIES_GETTERS,
} from "@/app/store/modules/deputies";
import { ConnectionStatus } from "@/app/types/deputy";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { LoaderState } from "@/app/types/loaderState";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";

@Component({ components: { Dropdown, CityFilterInputField, Button } })
export default class DeputiesFilterDropdownInner extends Vue {
    private partyOption: DropdownItemModel = this.currentPartyRule;
    private isConnectedOption: DropdownItemModel = new DropdownItemModel();

    private get currentCityRule(): DropdownItemModel {
        return this.$store.getters[DEPUTIES_GETTERS.GET_DEPUTIES_CURSOR_CITY];
    }

    private get currentPartyRule(): DropdownItemModel {
        return this.$store.getters[DEPUTIES_GETTERS.GET_DEPUTIES_CURSOR_PARTY];
    }

    private get partiesList(): DropdownItemModel[] {
        return this.$store.state.partiesModule.parties.map((party) => {
            return new DropdownItemModel(party.id, party.title);
        });
    }

    private get connectionStatusesList(): DropdownItemModel[] {
        return [
            new DropdownItemModel(ConnectionStatus.All, "Всі"),
            new DropdownItemModel(ConnectionStatus.Connected, "Приєднані"),
            new DropdownItemModel(
                ConnectionStatus.NotConnected,
                "Не приєднані"
            ),
        ];
    }

    private async selectCity(value): Promise<void> {
        if (this.currentCityRule.id === value.id) {
            return;
        }

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PARTY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            value
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CITY_RULE,
            value
        );
    }

    private async setPartyRule(value): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PARTY_RULE,
            value
        );
    }

    private async setIsConnectedRule(value): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CONNECTION_RULE,
            value
        );
    }

    private async resetFilters(): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CITY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PARTY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            new DropdownItemModel()
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CONNECTION_RULE,
            new DropdownItemModel(ConnectionStatus.All, "Всі")
        );

        await this.filter();
    }

    private async filter(): Promise<void> {
        await this.$store.dispatch(DEPUTIES_ACTIONS.RESET_DEPUTIES_CURSOR);

        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);
            this.$emit("toggleLoader", true);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.$emit("toggleLoader", false);
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState().Bounded()
        );
        this.$emit("toggleLoader", false);

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY,
            false
        );
    }

    async mounted() {
        this.isConnectedOption =
            this.$store.state.deputiesModule.cursor.connection;
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            this.currentCityRule
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
    }

    @Watch("currentPartyRule", { immediate: true, deep: true })
    resetParty() {
        this.partyOption = this.currentPartyRule;
    }
}
