















import { Component, Model, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Checkbox extends Vue {
    @Prop({ required: true })
    private readonly id: string;

    @Prop({ default: "" })
    private readonly label: string;

    @Model('change', { type: Boolean })
    private readonly checked: boolean;
}
