






































































import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

import DropdownItem from "@/app/components/common/DropdownItem.vue";

import { DropdownItemModel } from "@/app/types/dropdownItemModel";

@Component({ components: { DropdownItem } })
export default class Dropdown extends Vue {
    @Prop({ default: false })
    private readonly isRequired: boolean;

    @Prop({ default: "" })
    private readonly label: string;

    @Prop({ default: "" })
    private readonly initial: string;

    @Prop({ default: false })
    private readonly isDisabled: boolean;

    @Prop({ default: [] })
    private readonly options: DropdownItemModel[];

    @Prop({ default: false })
    private readonly linear: boolean;

    @Prop({ default: 'bottom right' })
    private readonly direction: string;

    @Prop({ default: false })
    private readonly allowClearing: boolean;

    @Model('select', { type: DropdownItemModel })
    private readonly currentOption: DropdownItemModel;

    @Prop({})
    private readonly selectedCallback: (
        dropdownItemModel: DropdownItemModel
    ) => void;

    private visibility: boolean | undefined = false;

    private get clearingVisibility(): boolean {
        return this.allowClearing && !!this.currentOption.name;
    }

    private toggleDropdown(): void {
        this.visibility = !this.visibility;
    }

    private closeDropdown(): void {
        this.visibility = false;
    }

    private selectOption(option): void {
        this.$emit('select', option);
        this.visibility = false;

        if (this.selectedCallback) {
            this.selectedCallback(option);
        }
    }

    private clear(): void {
        this.$emit('select', new DropdownItemModel());
        this.visibility = false;

        if (this.selectedCallback) {
            this.selectedCallback(new DropdownItemModel());
        }
    }

    @Watch("initial")
    resetDefault() {
        if (!String(this.initial)) {
            this.$emit('select', new DropdownItemModel());
        }
    }
}
