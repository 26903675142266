


















import { Component, Vue } from "vue-property-decorator";

import Popup from "@/app/components/common/Popup.vue";
import SidebarNav from "@/app/components/SidebarNav.vue";
import Alert from "@/app/components/common/Alert.vue";
import Loader from "@/app/components/common/Loader.vue";

@Component({ components: { SidebarNav, Popup, Alert, Loader } })
export default class App extends Vue {
    private get hasSidebar(): boolean {
        return this.$route.meta.sidebar;
    }

    private get loaderVisibility(): boolean {
        return (
            this.$store.state.appStateModule.loader.allowFullscreen &&
            this.$store.state.appStateModule.loader.isVisible
        );
    }
}
