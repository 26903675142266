import Vue, { VNode } from "vue";
import App from "./app/App.vue";
import { router } from "@/app/router";
import { store } from "@/app/store";
import VueCookies from "vue-cookies";
import { DirectiveBinding } from "vue/types/options";

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.$cookies.config("21d");

let clickOutsideEvent: EventListener;

/**
 * Binds closing action to outside popups area.
 */
Vue.directive('click-outside', {
    bind: function (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
        clickOutsideEvent = function (event: Event): void {
            if (el === event.target) {
                return;
            }

            if (vnode.context) {
                vnode.context[binding.expression](event);
            }
        };

        document.body.addEventListener('click', clickOutsideEvent);
    },
    unbind: function (): void {
        document.body.removeEventListener('click', clickOutsideEvent);
    },
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
