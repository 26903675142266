











import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";

@Component({ components: { Button } })
export default class DeputyDeletingWindow extends Vue {
    public static componentName: string | undefined = "DeputyDeletingWindow";

    private async deleteDeputy(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(DEPUTIES_ACTIONS.DELETE_DEPUTY);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Депутата не вдалося видалити")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Депутата успішно видалено")
        );
        await this.$store.dispatch(DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PAGE, 1);
        await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);
    }

    private cancel(): void {
        console.log("cancel deleting");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
