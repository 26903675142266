























































































































































import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Party, PartyDeletingRule, PartyEditingRule } from "@/app/types/party";
import Button from "@/app/components/common/Button.vue";
import PartyEditingWindow from "@/app/components/popups/PartyEditingWindow.vue";
import PartyDeletingWindow from "@/app/components/popups/PartyDeletingWindow.vue";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";
import { RoutePaths } from "@/app/router";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

@Component({ components: { Button } })
export default class PartyRow extends Vue {
    @Prop({ default: new Party() })
    private readonly row: Party;

    @Prop({ default: 0 })
    private readonly index: number;

    private openEditingPopup(): void {
        this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTY_EDITING_RULE,
            new PartyEditingRule(this.row.id, this.row.title, this.row.city)
        );
        this.$store.dispatch(
            PARTIES_ACTIONS.SET_EDITING_PARTY_AVATAR_PREVIEW_RULE,
            this.row.avatar
        );
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(PartyEditingWindow.componentName, true, false)
        );
    }

    private openDeletingPopup(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(PartyDeletingWindow.componentName, true, false)
        );
        this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTY_DELETING_RULE,
            new PartyDeletingRule(this.row.id)
        );
    }

    private openDeputiesPage(): void {
        this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PARTY_RULE,
            new DropdownItemModel(this.row.id, this.row.title)
        );
        this.$router.push({ name: RoutePaths.DEPUTIES.name });
    }
}
