import { Page } from "@/app/types/page";
import { Cursor } from "@/app/types/cursor";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

export class Party {
    public constructor(
        public id: string = "",
        public title: string = "",
        public deputyCount: number = 0,
        public city: DropdownItemModel = new DropdownItemModel(),
        public avatar: string = ""
    ) {}

    public static fromJson(obj: any): Party {
        return new Party(
            obj.id,
            obj.title,
            obj.deputy_count,
            new DropdownItemModel(obj.city_id, obj.city),
            obj.image && obj.image.preview_image ? obj.image.preview_image : ""
        );
    }
}

export class PartiesState {
    public constructor(
        public parties: Party[] = [],
        public creationRule: PartyCreationRule = new PartyCreationRule(),
        public editingRule: PartyEditingRule = new PartyEditingRule(),
        public deletingRule: PartyDeletingRule = new PartyDeletingRule(),
        public page: Page = new Page(),
        public cursor: PartiesCursor = new PartiesCursor()
    ) {}
}

export class PartyCreationRule {
    public constructor(
        public title: string = "",
        public city: DropdownItemModel = new DropdownItemModel(),
        public avatar: File[] = []
    ) {}

    public toJson(): string {
        const obj = {
            title: this.title,
            city_id: this.city.id,
        };

        return JSON.stringify(obj);
    }
}

export class PartyEditingRule {
    public constructor(
        public id: string = "",
        public title: string = "",
        public city: DropdownItemModel = new DropdownItemModel(),
        public avatar: File[] = [],
        public avatarPreview: string = ""
    ) {}

    public toJson(): string {
        const obj = {
            title: this.title,
            city_id: this.city.id,
        };

        return JSON.stringify(obj);
    }
}

export class PartyDeletingRule {
    public constructor(public id: string = "") {}
}

export class PartyAvatarRule {
    public constructor(
        public partyId: string = "",
        public avatar: File[] = []
    ) {}
}

export class PartiesPage {
    public constructor(
        public parties: Party[] = [],
        public page: Page = new Page()
    ) {}
}

export class PartiesCursor {
    public constructor(
        public cursor: Cursor = new Cursor(1, 50),
        public city: DropdownItemModel = new DropdownItemModel()
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        a.append("city_id", String(this.city.id));

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

function isEmpty(s: string | number): boolean {
    return !s || String(s).length === 0;
}

export interface PartiesApi {
    list(cursor: PartiesCursor): Promise<PartiesPage>;

    create(rule: PartyCreationRule): Promise<Party>;

    edit(rule: PartyEditingRule): Promise<Party>;

    delete(rule: PartyDeletingRule): Promise<boolean>;

    logo(rule: PartyAvatarRule): Promise<boolean>;
}
