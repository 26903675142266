































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Button from "@/app/components/common/Button.vue";
import {
    Report,
    ReportStatus,
    ReportType,
    statusChangingRule,
} from "@/app/types/report";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { REPORTS_ACTIONS } from "@/app/store/modules/reports";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import ReportApplicationWindow from "@/app/components/popups/ReportApplicationWindow.vue";
import ReportFullTextWindow from "@/app/components/popups/ReportFullTextWindow.vue";

@Component({ components: { Button, Dropdown } })
export default class ReportRow extends Vue {
    @Prop({ default: new Report() })
    private readonly row: Report;

    private statusOption: DropdownItemModel = new DropdownItemModel();

    private get applicationTitle(): string {
        return this.row.applicationTitle || `№ ${this.row.applicationPublicId}`;
    }

    private get typeItem(): string {
        switch (this.row.type) {
            case ReportType.Else:
                return "Інше";
            case ReportType.Spam:
                return "Спам";
            case ReportType.Insults:
                return "Помилка";
            case ReportType.Error:
                return "Помилка";
            case ReportType.Undefined:
            default:
                return "-";
        }
    }

    private get statusesList(): DropdownItemModel[] {
        return [
            new DropdownItemModel(ReportStatus.New, "Нова"),
            new DropdownItemModel(ReportStatus.Implemented, "Опрацьована"),
            new DropdownItemModel(ReportStatus.Irrelevant, "Не актуальна"),
            new DropdownItemModel(ReportStatus.Hidden, "Прихована"),
        ];
    }

    private get date(): string {
        const formatter = new Intl.DateTimeFormat("uk-UA", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        });

        return formatter.format(new Date(this.row.date));
    }

    private async changeReportStatus(status: DropdownItemModel): Promise<void> {
        try {
            await this.$store.dispatch(
                REPORTS_ACTIONS.CHANGE_REPORT_STATUS,
                new statusChangingRule(this.row.id, status)
            );
            await this.$store.dispatch(REPORTS_ACTIONS.GET_REPORTS);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    private openFullTextPopup(): void {
        this.$store.dispatch(REPORTS_ACTIONS.SET_REPORT, this.row.id);

        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(ReportFullTextWindow.componentName, true, false)
        );
    }

    private async openApplication(): Promise<void> {
        await this.$store.dispatch(
            REPORTS_ACTIONS.SET_REPORT_APPLICATION,
            this.row.id
        );

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(ReportApplicationWindow.componentName, true, false)
        );
    }

    @Watch("row", { immediate: true, deep: true })
    resetDefault() {
        this.statusOption = this.row.status;
    }
}
