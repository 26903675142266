import { Page } from "@/app/types/page";
import {
    Manager,
    ManagerDeletingRule,
    ManagersApi,
    ManagersState,
} from "@/app/types/manager";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

export const MANAGERS_MUTATIONS = {
    GET_MANAGERS: "GET_MANAGERS",
    SET_MANAGERS_CURSOR_PAGE: "SET_MANAGERS_CURSOR_PAGE",
    SET_MANAGERS_CURSOR_CITY_RULE: "SET_MANAGERS_CURSOR_CITY_RULE",
    SET_MANAGERS_CURSOR_DEPUTY_RULE: "SET_MANAGERS_CURSOR_DEPUTY_RULE",
    SET_MANAGERS_PAGINATION: "SET_MANAGERS_PAGINATION",

    SET_CREATION_MANAGER_LOGIN_RULE: "SET_CREATION_MANAGER_LOGIN_RULE",
    SET_CREATION_MANAGER_FIRST_NAME_RULE:
        "SET_CREATION_MANAGER_FIRST_NAME_RULE",
    SET_CREATION_MANAGER_MIDDLE_NAME_RULE:
        "SET_CREATION_MANAGER_MIDDLE_NAME_RULE",
    SET_CREATION_MANAGER_LAST_NAME_RULE: "SET_CREATION_MANAGER_LAST_NAME_RULE",
    SET_CREATION_MANAGER_DEPUTY_RULE: "SET_CREATION_MANAGER_DEPUTY_RULE",

    SET_EDITING_MANAGER_ID_RULE: "SET_EDITING_MANAGER_ID_RULE",
    SET_EDITING_MANAGER_FIRST_NAME_RULE: "SET_EDITING_MANAGER_FIRST_NAME_RULE",
    SET_EDITING_MANAGER_MIDDLE_NAME_RULE:
        "SET_EDITING_MANAGER_MIDDLE_NAME_RULE",
    SET_EDITING_MANAGER_LAST_NAME_RULE: "SET_EDITING_MANAGER_LAST_NAME_RULE",
    SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE:
        "SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE",

    SET_MANAGER_DELETING_RULE: "SET_MANAGER_DELETING_RULE",
};

export const MANAGERS_ACTIONS = {
    GET_MANAGERS: "GET_MANAGERS",
    SET_MANAGERS_CURSOR_PAGE: "SET_MANAGERS_CURSOR_PAGE",
    RESET_MANAGERS_CURSOR: "RESET_MANAGERS_CURSOR",
    SET_MANAGERS_CURSOR_CITY_RULE: "SET_MANAGERS_CURSOR_CITY_RULE",
    SET_MANAGERS_CURSOR_DEPUTY_RULE: "SET_MANAGERS_CURSOR_DEPUTY_RULE",

    SET_CREATION_MANAGER_LOGIN_RULE: "SET_CREATION_MANAGER_LOGIN_RULE",
    SET_CREATION_MANAGER_FIRST_NAME_RULE:
        "SET_CREATION_MANAGER_FIRST_NAME_RULE",
    SET_CREATION_MANAGER_MIDDLE_NAME_RULE:
        "SET_CREATION_MANAGER_MIDDLE_NAME_RULE",
    SET_CREATION_MANAGER_LAST_NAME_RULE: "SET_CREATION_MANAGER_LAST_NAME_RULE",
    SET_CREATION_MANAGER_DEPUTY_RULE: "SET_CREATION_MANAGER_DEPUTY_RULE",

    SET_EDITING_MANAGER_ID_RULE: "SET_EDITING_MANAGER_ID_RULE",
    SET_EDITING_MANAGER_FIRST_NAME_RULE: "SET_EDITING_MANAGER_FIRST_NAME_RULE",
    SET_EDITING_MANAGER_MIDDLE_NAME_RULE:
        "SET_EDITING_MANAGER_MIDDLE_NAME_RULE",
    SET_EDITING_MANAGER_LAST_NAME_RULE: "SET_EDITING_MANAGER_LAST_NAME_RULE",
    SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE:
        "SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE",

    SET_MANAGER_DELETING_RULE: "SET_MANAGER_DELETING_RULE",

    CREATE_MANAGER: "CREATE_MANAGER",
    EDIT_MANAGER: "EDIT_MANAGER",
    DELETE_MANAGER: "DELETE_MANAGER",
};

export const MANAGERS_GETTERS = {
    GET_MANAGERS_CURSOR_CITY: "GET_MANAGERS_CURSOR_CITY",
    GET_MANAGERS_CURSOR_DEPUTY: "GET_MANAGERS_CURSOR_DEPUTY",
    GET_MANAGERS_CREATION_RULE: "GET_MANAGERS_CREATION_RULE",
    GET_MANAGERS_EDITING_RULE: "GET_MANAGERS_EDITING_RULE",
};

export function makeManagersModule(api: ManagersApi) {
    return {
        state: new ManagersState(),
        mutations: {
            [MANAGERS_MUTATIONS.GET_MANAGERS](
                state: ManagersState,
                list: Manager[]
            ): void {
                state.managers = list;
            },
            [MANAGERS_MUTATIONS.SET_MANAGERS_CURSOR_PAGE](
                state: ManagersState,
                page: number
            ): void {
                state.cursor.cursor.page = page;
            },
            [MANAGERS_MUTATIONS.SET_MANAGERS_CURSOR_CITY_RULE](
                state: ManagersState,
                city: DropdownItemModel
            ): void {
                state.cursor.city = city;
            },
            [MANAGERS_MUTATIONS.SET_MANAGERS_CURSOR_DEPUTY_RULE](
                state: ManagersState,
                deputy: DropdownItemModel
            ): void {
                state.cursor.deputy = deputy;
            },
            [MANAGERS_MUTATIONS.SET_MANAGERS_PAGINATION](
                state: ManagersState,
                page: Page
            ): void {
                state.page = page;
            },

            [MANAGERS_MUTATIONS.SET_CREATION_MANAGER_LOGIN_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.creationRule.login = value;
            },
            [MANAGERS_MUTATIONS.SET_CREATION_MANAGER_FIRST_NAME_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.creationRule.firstName = value;
            },
            [MANAGERS_MUTATIONS.SET_CREATION_MANAGER_MIDDLE_NAME_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.creationRule.middleName = value;
            },
            [MANAGERS_MUTATIONS.SET_CREATION_MANAGER_LAST_NAME_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.creationRule.lastName = value;
            },
            [MANAGERS_MUTATIONS.SET_CREATION_MANAGER_DEPUTY_RULE](
                state: ManagersState,
                value: DropdownItemModel
            ): void {
                state.creationRule.deputy = value;
            },

            [MANAGERS_MUTATIONS.SET_EDITING_MANAGER_ID_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.editingRule.id = value;
            },
            [MANAGERS_MUTATIONS.SET_EDITING_MANAGER_FIRST_NAME_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.editingRule.firstName = value;
            },
            [MANAGERS_MUTATIONS.SET_EDITING_MANAGER_MIDDLE_NAME_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.editingRule.middleName = value;
            },
            [MANAGERS_MUTATIONS.SET_EDITING_MANAGER_LAST_NAME_RULE](
                state: ManagersState,
                value: string
            ): void {
                state.editingRule.lastName = value;
            },
            [MANAGERS_MUTATIONS.SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE](
                state: ManagersState,
                value: boolean
            ): void {
                state.editingRule.isFinishedRegistration = value;
            },

            [MANAGERS_MUTATIONS.SET_MANAGER_DELETING_RULE](
                state: ManagersState,
                rule: ManagerDeletingRule
            ): void {
                state.deletingRule = rule;
            },
        },
        actions: {
            [MANAGERS_ACTIONS.GET_MANAGERS]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const managersResponse = await api.list(state.cursor);
                commit(
                    MANAGERS_MUTATIONS.GET_MANAGERS,
                    managersResponse.managers
                );
                commit(
                    MANAGERS_MUTATIONS.SET_MANAGERS_PAGINATION,
                    managersResponse.page
                );
            },
            [MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_PAGE]: function (
                { commit, state }: any,
                page: number
            ): void {
                commit(MANAGERS_MUTATIONS.SET_MANAGERS_CURSOR_PAGE, page);
            },
            [MANAGERS_ACTIONS.RESET_MANAGERS_CURSOR]: function ({
                commit,
                state,
            }: any): void {
                commit(MANAGERS_MUTATIONS.SET_MANAGERS_PAGINATION, new Page());
                commit(MANAGERS_MUTATIONS.SET_MANAGERS_CURSOR_PAGE, 1);
            },
            [MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_CITY_RULE]: function (
                { commit, state }: any,
                city: DropdownItemModel
            ): void {
                commit(MANAGERS_MUTATIONS.SET_MANAGERS_CURSOR_CITY_RULE, city);
            },
            [MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_DEPUTY_RULE]: function (
                { commit, state }: any,
                deputy: DropdownItemModel
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_MANAGERS_CURSOR_DEPUTY_RULE,
                    deputy
                );
            },

            [MANAGERS_ACTIONS.SET_CREATION_MANAGER_LOGIN_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_CREATION_MANAGER_LOGIN_RULE,
                    value
                );
            },
            [MANAGERS_ACTIONS.SET_CREATION_MANAGER_FIRST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_CREATION_MANAGER_FIRST_NAME_RULE,
                    value
                );
            },
            [MANAGERS_ACTIONS.SET_CREATION_MANAGER_MIDDLE_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_CREATION_MANAGER_MIDDLE_NAME_RULE,
                    value
                );
            },
            [MANAGERS_ACTIONS.SET_CREATION_MANAGER_LAST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_CREATION_MANAGER_LAST_NAME_RULE,
                    value
                );
            },
            [MANAGERS_ACTIONS.SET_CREATION_MANAGER_DEPUTY_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_CREATION_MANAGER_DEPUTY_RULE,
                    value
                );
            },

            [MANAGERS_ACTIONS.SET_EDITING_MANAGER_ID_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(MANAGERS_MUTATIONS.SET_EDITING_MANAGER_ID_RULE, value);
            },
            [MANAGERS_ACTIONS.SET_EDITING_MANAGER_FIRST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_EDITING_MANAGER_FIRST_NAME_RULE,
                    value
                );
            },
            [MANAGERS_ACTIONS.SET_EDITING_MANAGER_MIDDLE_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_EDITING_MANAGER_MIDDLE_NAME_RULE,
                    value
                );
            },
            [MANAGERS_ACTIONS.SET_EDITING_MANAGER_LAST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    MANAGERS_MUTATIONS.SET_EDITING_MANAGER_LAST_NAME_RULE,
                    value
                );
            },
            [MANAGERS_ACTIONS.SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE]:
                function ({ commit }: any, value: boolean): void {
                    commit(
                        MANAGERS_MUTATIONS.SET_EDITING_MANAGER_REGISTRATION_STATUS_RULE,
                        value
                    );
                },

            [MANAGERS_ACTIONS.SET_MANAGER_DELETING_RULE]: function (
                { commit, state }: any,
                rule: ManagerDeletingRule
            ): void {
                commit(MANAGERS_MUTATIONS.SET_MANAGER_DELETING_RULE, rule);
            },

            [MANAGERS_ACTIONS.CREATE_MANAGER]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                await api.create(state.creationRule);
            },
            [MANAGERS_ACTIONS.EDIT_MANAGER]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                await api.edit(state.editingRule);
            },
            [MANAGERS_ACTIONS.DELETE_MANAGER]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                await api.delete(state.deletingRule);
            },
        },

        getters: {
            [MANAGERS_GETTERS.GET_MANAGERS_CURSOR_CITY]: (state) => {
                return state.cursor.city;
            },
            [MANAGERS_GETTERS.GET_MANAGERS_CURSOR_DEPUTY]: (state) => {
                return state.cursor.deputy;
            },
            [MANAGERS_GETTERS.GET_MANAGERS_CREATION_RULE]: (state) => {
                return state.creationRule;
            },
            [MANAGERS_GETTERS.GET_MANAGERS_EDITING_RULE]: (state) => {
                return state.editingRule;
            },
        },
    };
}
