import { File } from "@/app/types/file";
import { Image } from "@/app/types/image";

export class Document {
    public constructor(
        public documentType: DocumentType = DocumentType.Image,
        public image: Image = new Image(),
        public file: File = new File()
    ) {}

    public static fromJson(json: any): Document {
        const document = new Document();
        document.documentType = json.document_type;

        if (json.file !== null) {
            document.file = File.fromJson(json.file);
        }

        if (json.image !== null) {
            document.image = Image.fromJson(json.image);
        }

        return document;
    }
}

export enum DocumentType {
    Image = 1,
    Document = 2,
}
