



























import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import Dropdown from "@/app/components/common/Dropdown.vue";
import {
    ConstituenciesListRule,
    ConstituencyEditingRule,
} from "@/app/types/filters";
import { FILTERS_ACTIONS, FILTERS_GETTERS } from "@/app/store/modules/filters";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

@Component({ components: { Button, InputField, Dropdown } })
export default class ConstituencyEditingWindow extends Vue {
    public static componentName: string | undefined =
        "ConstituencyEditingWindow";
    private title: number = this.editingRule.constituencyNewNumber;
    private districtOption: DropdownItemModel = this.editingRule.district;

    private get editingRule(): ConstituencyEditingRule {
        return this.$store.getters[FILTERS_GETTERS.CONSTITUENCY_EDITING_RULE];
    }

    private get districtsList(): DropdownItemModel[] {
        return this.$store.state.filtersModule.districtsList;
    }

    private get cursorCity(): DropdownItemModel {
        return this.$store.state.filtersModule.cursor.city;
    }

    private async editConstituency(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(
                FILTERS_ACTIONS.SET_CONSTITUENCY_EDITING_RULE,
                new ConstituencyEditingRule(
                    String(this.editingRule.id),
                    this.districtOption,
                    this.title
                )
            );
            await this.$store.dispatch(FILTERS_ACTIONS.EDIT_CONSTITUENCY);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Округ не вдалося відредагувати")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Округ успішно змінено")
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            new ConstituenciesListRule(String(this.cursorCity.id), "")
        );
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
