import { Cursor } from "@/app/types/cursor";
import { Page } from "@/app/types/page";

export class PhonesState {
    public constructor(
        public phones: Phone[] = [],
        public cursor: PhonesCursor = new PhonesCursor(),
        public page: Page = new Page(),
        public deletingRule: PhonesDeletingRule = new PhonesDeletingRule()
    ) {}
}

export class Phone {
    public constructor(
        public id: string = "",
        public number: string = "",
        public createdAt: Date = new Date(),
        public token: string = "",
        public isVerified: boolean = false
    ) {}

    public static fromJson(obj: any): Phone {
        return new Phone(
            obj.id,
            obj.phone_number,
            obj.created_at,
            obj.token,
            obj.verified
        );
    }
}

export class PhonesDeletingRule {
    public constructor(public id: string = "") {}
}

export class PhonesPage {
    public constructor(
        public phones: Phone[] = [],
        public page: Page = new Page()
    ) {}
}

export class PhonesCursor {
    public constructor(public cursor: Cursor = new Cursor()) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

function isEmpty(s: string | number): boolean {
    return !s || String(s).length === 0;
}

export interface PhonesApi {
    list(cursor: PhonesCursor): Promise<PhonesPage>;

    delete(rule: PhonesDeletingRule): Promise<boolean>;
}
