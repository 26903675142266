










































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Loader from "@/app/components/common/Loader.vue";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { CitiesListRule } from "@/app/types/filters";
import { LoaderState } from "@/app/types/loaderState";
import { LoaderConstants } from "@/app/types/loaderConstants";

@Component({ components: { Loader } })
export default class CityFilterInputField extends Vue {
    @Prop({ default: "" })
    private readonly initial: string;

    @Prop({ default: "" })
    private readonly label: string;

    @Prop({ default: false })
    private readonly isRequired: boolean;

    @Prop({ default: false })
    private readonly disabled: boolean;

    @Prop({ default: "" })
    private readonly placeholder: string;

    private value: string | undefined = "";
    private listVisibility: boolean | undefined = false;
    private allowSearching: boolean | undefined = true;
    private loaderVisibility: boolean | undefined = false;

    private get citiesList(): DropdownItemModel[] {
        return this.$store.state.filtersModule.citiesState.list;
    }

    private get currentPage(): number {
        return this.$store.state.filtersModule.citiesState.cursor.cursor.page;
    }

    private get nextPage(): number {
        return this.$store.state.filtersModule.citiesState.page.nextPage;
    }

    private async openCitiesList(): Promise<void> {
        if (this.listVisibility) {
            return;
        }

        this.listVisibility = true;
        await this.searchCity();
    }

    private closeCitiesList(): void {
        this.listVisibility = false;
    }

    private selectCity(city: DropdownItemModel): void {
        this.value = city.name;
        this.listVisibility = false;
        this.$emit(
            "input",
            this.citiesList.find((item) => item.name === this.value)
        );
    }

    private async searchCity(): Promise<void> {
        let currentSearchValue: string | undefined = "";
        this.listVisibility = true;
        this.$emit(
            "input",
            this.citiesList.find((item) => item.name === this.value) ||
                new DropdownItemModel("", this.value)
        );
        await this.$store.dispatch(FILTERS_ACTIONS.SET_CITIES_CURSOR_PAGE, 1);

        if (!this.allowSearching) {
            return;
        }

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CITIES_LIST,
            new CitiesListRule(this.value)
        );

        currentSearchValue = this.value;
        this.allowSearching = false;

        setTimeout(async () => {
            this.allowSearching = true;

            if (currentSearchValue === this.value) {
                return;
            }

            await this.$store.dispatch(
                FILTERS_ACTIONS.GET_CITIES_LIST,
                new CitiesListRule(this.value)
            );

            currentSearchValue = this.value;
        }, LoaderConstants.CITIES_LIST_FETCHING_DELAY);
    }

    private async paginateCitiesList(e): Promise<void> {
        const column = e.target;
        const scrollPosition = column.scrollTop + column.offsetHeight;

        if (this.nextPage === -1 || this.currentPage === this.nextPage) {
            return;
        }

        if (scrollPosition < column.scrollHeight - 38) {
            return;
        }

        try {
            await this.$store.dispatch(
                FILTERS_ACTIONS.SET_CITIES_CURSOR_PAGE,
                this.nextPage
            );
            await this.$store.dispatch(
                FILTERS_ACTIONS.EXPAND_CITIES_LIST,
                new CitiesListRule(this.value)
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            this.loaderVisibility = true;
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.loaderVisibility = false;
            console.error(e);

            return;
        }

        setTimeout(async () => {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.loaderVisibility = false;
        }, LoaderConstants.LOADER_HIDING);
    }

    mounted() {
        this.value = this.initial;
    }

    @Watch("initial")
    resetDefault() {
        if (!this.initial) {
            this.value = "";
        }
    }
}
