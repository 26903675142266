import { Popup } from "@/app/types/popup";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";

export const APPSTATE_MUTATIONS = {
    SET_POPUP_STATE: "SET_POPUP_STATE",
    START_ALERT_STATE: "START_ALERT_STATE",
    STOP_ALERT_STATE: "STOP_ALERT_STATE",
    SET_LOADING_STATE: "SET_LOADING_STATE",
    SET_NOTIFICATIONS_VISIBILITY: "SET_NOTIFICATIONS_VISIBILITY",
    SET_FILTERS_PANEL_VISIBILITY: "SET_FILTERS_PANEL_VISIBILITY",
};

export const APPSTATE_ACTIONS = {
    SET_POPUP_STATE: "SET_POPUP_STATE",
    START_ALERT_STATE: "START_ALERT_STATE",
    STOP_ALERT_STATE: "STOP_ALERT_STATE",
    SET_LOADING_STATE: "SET_LOADING_STATE",
    SET_NOTIFICATIONS_VISIBILITY: "SET_NOTIFICATIONS_VISIBILITY",
    SET_FILTERS_PANEL_VISIBILITY: "SET_FILTERS_PANEL_VISIBILITY",
};

export const appStateModule = {
    state: {
        loader: new LoaderState(),
        popup: new Popup(),
        alert: new Alert(),
        alertTimer: null,
        filtersPanelVisibility: false,
    },
    mutations: {
        [APPSTATE_MUTATIONS.SET_POPUP_STATE](state: any, value: Popup): void {
            state.popup.component = value.component
                ? value.component
                : state.popup.component;
            state.popup.visibility = value.visibility;
            state.popup.shift = value.shift;
        },
        [APPSTATE_MUTATIONS.START_ALERT_STATE](state: any, value: Alert): void {
            state.alert.type = value.type ? value.type : state.alert.type;
            state.alert.text = value.text;

            if (!state.alert.visibility && value.visibility) {
                clearTimeout(state.alertTimer);
                state.alertTimer = null;
                state.alert.visibility = true;

                state.alertTimer = setTimeout(() => {
                    state.alert.visibility = false;
                }, 2000);
            }
        },
        [APPSTATE_MUTATIONS.STOP_ALERT_STATE](state: any): void {
            clearTimeout(state.alertTimer);
            state.alertTimer = null;
            state.alert.visibility = false;
        },
        [APPSTATE_MUTATIONS.SET_LOADING_STATE](
            state: any,
            value: LoaderState
        ): void {
            state.loader.allowFullscreen = value.allowFullscreen;

            switch (value.isVisible) {
                case true:
                    state.loader.isVisible = value.isVisible;
                    break;
                case false:
                    setTimeout(() => {
                        state.loader.isVisible = value.isVisible;
                    }, 700);
                    break;
                default:
                    break;
            }
        },
        [APPSTATE_MUTATIONS.SET_FILTERS_PANEL_VISIBILITY](
            state: any,
            value
        ): void {
            state.filtersPanelVisibility = value;
        },
    },
    actions: {
        [APPSTATE_ACTIONS.SET_POPUP_STATE]: function (
            { commit }: any,
            value: Popup
        ): void {
            commit(APPSTATE_MUTATIONS.SET_POPUP_STATE, value);
        },
        [APPSTATE_ACTIONS.START_ALERT_STATE]: function (
            { commit }: any,
            value: Alert
        ): void {
            commit(
                APPSTATE_MUTATIONS.START_ALERT_STATE,
                new Alert(value.type, true, value.text)
            );
        },
        [APPSTATE_ACTIONS.STOP_ALERT_STATE]: function ({ commit }: any): void {
            commit(APPSTATE_MUTATIONS.STOP_ALERT_STATE);
        },
        [APPSTATE_ACTIONS.SET_LOADING_STATE]: function (
            { commit }: any,
            value: LoaderState
        ): void {
            commit(APPSTATE_MUTATIONS.SET_LOADING_STATE, value);
        },
        [APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY]: function (
            { commit }: any,
            value: boolean
        ): void {
            commit(APPSTATE_MUTATIONS.SET_FILTERS_PANEL_VISIBILITY, value);
        },
    },
};
