import { Page } from "@/app/types/page";
import {
    PartiesApi,
    PartiesState,
    Party,
    PartyEditingRule,
    PartyDeletingRule,
    PartyAvatarRule,
} from "@/app/types/party";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

export const PARTIES_MUTATIONS = {
    GET_PARTIES: "GET_PARTIES",
    SET_PARTIES_CURSOR_PAGE: "SET_PARTIES_CURSOR_PAGE",
    SET_PARTIES_CURSOR_CITY: "SET_PARTIES_CURSOR_CITY",
    SET_PARTIES_PAGINATION: "SET_PARTIES_PAGINATION",

    SET_CREATION_PARTY_AVATAR_RULE: "SET_CREATION_PARTY_AVATAR_RULE",
    SET_CREATION_PARTY_TITLE_RULE: "SET_CREATION_PARTY_TITLE_RULE",
    SET_CREATION_PARTY_CITY_RULE: "SET_CREATION_PARTY_CITY_RULE",

    SET_EDITING_PARTY_AVATAR_RULE: "SET_EDITING_PARTY_AVATAR_RULE",
    SET_EDITING_PARTY_AVATAR_PREVIEW_RULE:
        "SET_EDITING_PARTY_AVATAR_PREVIEW_RULE",
    SET_EDITING_PARTY_CITY_RULE: "SET_EDITING_PARTY_CITY_RULE",

    SET_PARTY_EDITING_RULE: "SET_PARTY_EDITING_RULE",
    SET_PARTY_CREATION_RULE: "SET_PARTY_CREATION_RULE",
    SET_PARTY_DELETING_RULE: "SET_PARTY_DELETING_RULE",
};

export const PARTIES_ACTIONS = {
    GET_PARTIES: "GET_PARTIES",
    SET_PARTIES_CURSOR_PAGE: "SET_PARTIES_CURSOR_PAGE",
    SET_PARTIES_CURSOR_CITY: "SET_PARTIES_CURSOR_CITY",

    SET_CREATION_PARTY_AVATAR_RULE: "SET_CREATION_PARTY_AVATAR_RULE",
    SET_CREATION_PARTY_TITLE_RULE: "SET_CREATION_PARTY_TITLE_RULE",
    SET_CREATION_PARTY_CITY_RULE: "SET_CREATION_PARTY_CITY_RULE",

    SET_EDITING_PARTY_AVATAR_RULE: "SET_EDITING_PARTY_AVATAR_RULE",
    SET_EDITING_PARTY_AVATAR_PREVIEW_RULE:
        "SET_EDITING_PARTY_AVATAR_PREVIEW_RULE",
    SET_EDITING_PARTY_CITY_RULE: "SET_EDITING_PARTY_CITY_RULE",

    SET_PARTY_EDITING_RULE: "SET_PARTY_EDITING_RULE",
    SET_PARTY_DELETING_RULE: "SET_PARTY_DELETING_RULE",

    CREATE_PARTY: "CREATE_PARTY",
    EDIT_PARTY: "EDIT_PARTY",
    DELETE_PARTY: "DELETE_PARTY",
};

export const PARTIES_GETTERS = {
    GET_PARTIES_CURSOR_CITY: "GET_PARTIES_CURSOR_CITY",
    GET_PARTIES_CREATION_RULE: "GET_PARTIES_CREATION_RULE",
    GET_PARTIES_EDITING_RULE: "GET_PARTIES_EDITING_RULE",
};

export function makePartiesModule(api: PartiesApi) {
    return {
        state: new PartiesState(),
        mutations: {
            [PARTIES_MUTATIONS.GET_PARTIES](
                state: PartiesState,
                list: Party[]
            ): void {
                state.parties = list;
            },
            [PARTIES_MUTATIONS.SET_PARTIES_CURSOR_PAGE](
                state: PartiesState,
                page: number
            ): void {
                state.cursor.cursor.page = page;
            },
            [PARTIES_MUTATIONS.SET_PARTIES_CURSOR_CITY](
                state: PartiesState,
                city: DropdownItemModel
            ): void {
                state.cursor.city = city;
            },
            [PARTIES_MUTATIONS.SET_PARTIES_PAGINATION](
                state: PartiesState,
                page: Page
            ): void {
                state.page = page;
            },

            [PARTIES_MUTATIONS.SET_CREATION_PARTY_AVATAR_RULE](
                state: PartiesState,
                file: File
            ): void {
                state.creationRule.avatar = [file];
            },
            [PARTIES_MUTATIONS.SET_CREATION_PARTY_TITLE_RULE](
                state: PartiesState,
                value: string
            ): void {
                state.creationRule.title = value;
            },
            [PARTIES_MUTATIONS.SET_CREATION_PARTY_CITY_RULE](
                state: PartiesState,
                value: DropdownItemModel
            ): void {
                state.creationRule.city = value;
            },

            [PARTIES_MUTATIONS.SET_EDITING_PARTY_AVATAR_RULE](
                state: PartiesState,
                file: File
            ): void {
                state.editingRule.avatar = [file];
            },
            [PARTIES_MUTATIONS.SET_EDITING_PARTY_AVATAR_PREVIEW_RULE](
                state: PartiesState,
                value: string
            ): void {
                state.editingRule.avatarPreview = value;
            },
            [PARTIES_MUTATIONS.SET_EDITING_PARTY_CITY_RULE](
                state: PartiesState,
                value: DropdownItemModel
            ): void {
                state.editingRule.city = value;
            },

            [PARTIES_MUTATIONS.SET_PARTY_EDITING_RULE](
                state: PartiesState,
                rule: PartyEditingRule
            ): void {
                state.editingRule = rule;
            },
            [PARTIES_MUTATIONS.SET_PARTY_DELETING_RULE](
                state: PartiesState,
                rule: PartyDeletingRule
            ): void {
                state.deletingRule = rule;
            },
        },
        actions: {
            [PARTIES_ACTIONS.GET_PARTIES]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const partiesResponse = await api.list(state.cursor);
                commit(PARTIES_MUTATIONS.GET_PARTIES, partiesResponse.parties);
                commit(
                    PARTIES_MUTATIONS.SET_PARTIES_PAGINATION,
                    partiesResponse.page
                );
            },
            [PARTIES_ACTIONS.SET_PARTIES_CURSOR_PAGE]: function (
                { commit, state }: any,
                page: number
            ): void {
                commit(PARTIES_MUTATIONS.SET_PARTIES_CURSOR_PAGE, page);
            },
            [PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY]: function (
                { commit, state }: any,
                city: DropdownItemModel
            ): void {
                commit(PARTIES_MUTATIONS.SET_PARTIES_CURSOR_CITY, city);
            },

            [PARTIES_ACTIONS.SET_CREATION_PARTY_AVATAR_RULE]: function (
                { commit }: any,
                file: File
            ): void {
                commit(PARTIES_MUTATIONS.SET_CREATION_PARTY_AVATAR_RULE, file);
            },
            [PARTIES_ACTIONS.SET_CREATION_PARTY_TITLE_RULE]: function (
                { commit, state }: any,
                value: string
            ): void {
                commit(PARTIES_MUTATIONS.SET_CREATION_PARTY_TITLE_RULE, value);
            },
            [PARTIES_ACTIONS.SET_CREATION_PARTY_CITY_RULE]: function (
                { commit, state }: any,
                value: DropdownItemModel
            ): void {
                commit(PARTIES_MUTATIONS.SET_CREATION_PARTY_CITY_RULE, value);
            },

            [PARTIES_ACTIONS.SET_EDITING_PARTY_AVATAR_RULE]: function (
                { commit }: any,
                file: File
            ): void {
                commit(PARTIES_MUTATIONS.SET_EDITING_PARTY_AVATAR_RULE, file);
            },
            [PARTIES_ACTIONS.SET_EDITING_PARTY_AVATAR_PREVIEW_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    PARTIES_MUTATIONS.SET_EDITING_PARTY_AVATAR_PREVIEW_RULE,
                    value
                );
            },
            [PARTIES_ACTIONS.SET_EDITING_PARTY_CITY_RULE]: function (
                { commit, state }: any,
                value: DropdownItemModel
            ): void {
                commit(PARTIES_MUTATIONS.SET_EDITING_PARTY_CITY_RULE, value);
            },

            [PARTIES_ACTIONS.SET_PARTY_EDITING_RULE]: function (
                { commit, state }: any,
                rule: PartyEditingRule
            ): void {
                commit(PARTIES_MUTATIONS.SET_PARTY_EDITING_RULE, rule);
            },

            [PARTIES_ACTIONS.SET_PARTY_DELETING_RULE]: function (
                { commit, state }: any,
                rule: PartyDeletingRule
            ): void {
                commit(PARTIES_MUTATIONS.SET_PARTY_DELETING_RULE, rule);
            },

            [PARTIES_ACTIONS.CREATE_PARTY]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const response = await api.create(state.creationRule);

                if (response.id && state.creationRule.avatar.length) {
                    await api.logo(
                        new PartyAvatarRule(
                            response.id,
                            state.creationRule.avatar
                        )
                    );
                }
            },
            [PARTIES_ACTIONS.EDIT_PARTY]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const response = await api.edit(state.editingRule);

                if (response.id && state.editingRule.avatar.length) {
                    await api.logo(
                        new PartyAvatarRule(
                            response.id,
                            state.editingRule.avatar
                        )
                    );
                }
            },
            [PARTIES_ACTIONS.DELETE_PARTY]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                await api.delete(state.deletingRule);
            },
        },
        getters: {
            [PARTIES_GETTERS.GET_PARTIES_CURSOR_CITY]: (state) => {
                return state.cursor.city;
            },
            [PARTIES_GETTERS.GET_PARTIES_CREATION_RULE]: (state) => {
                return state.creationRule;
            },
            [PARTIES_GETTERS.GET_PARTIES_EDITING_RULE]: (state) => {
                return state.editingRule;
            },
        },
    };
}
