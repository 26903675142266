import {
    Phone,
    PhonesApi,
    PhonesDeletingRule,
    PhonesState,
} from "@/app/types/phone";
import { Page } from "@/app/types/page";

export const PHONES_MUTATIONS = {
    SET_PHONES_LIST: "SET_PHONES_LIST",
    SET_PHONES_PAGINATION: "SET_PHONES_PAGINATION",
    SET_PHONES_CURSOR_PAGE: "SET_PHONES_CURSOR_PAGE",
    SET_PHONES_DELETING_RULE: "SET_PHONES_DELETING_RULE",
};

export const PHONES_ACTIONS = {
    GET_PHONES_LIST: "GET_PHONES_LIST",
    RESET_PHONES_CURSOR: "RESET_PHONES_CURSOR",
    SET_PHONES_CURSOR_PAGE: "SET_PHONES_CURSOR_PAGE",
    SET_PHONES_DELETING_RULE: "SET_PHONES_DELETING_RULE",

    DELETE_PHONE: "DELETE_PHONE",
};

export function makePhonesModule(api: PhonesApi) {
    return {
        state: new PhonesState(),
        mutations: {
            [PHONES_MUTATIONS.SET_PHONES_LIST](
                state: PhonesState,
                phones: Phone[]
            ): void {
                state.phones = phones;
            },
            [PHONES_MUTATIONS.SET_PHONES_PAGINATION](
                state: PhonesState,
                page: Page
            ): void {
                state.page = page;
            },
            [PHONES_MUTATIONS.SET_PHONES_CURSOR_PAGE](
                state: PhonesState,
                page: number
            ): void {
                state.cursor.cursor.page = page;
            },
            [PHONES_MUTATIONS.SET_PHONES_DELETING_RULE](
                state: PhonesState,
                rule: PhonesDeletingRule
            ): void {
                state.deletingRule = rule;
            },
        },
        actions: {
            [PHONES_ACTIONS.GET_PHONES_LIST]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const response = await api.list(state.cursor);
                commit(PHONES_MUTATIONS.SET_PHONES_LIST, response.phones);
                commit(PHONES_MUTATIONS.SET_PHONES_PAGINATION, response.page);
            },
            [PHONES_ACTIONS.RESET_PHONES_CURSOR]: function ({
                commit,
            }: any): void {
                commit(PHONES_MUTATIONS.SET_PHONES_PAGINATION, new Page());
                commit(PHONES_MUTATIONS.SET_PHONES_CURSOR_PAGE, 1);
            },
            [PHONES_ACTIONS.SET_PHONES_CURSOR_PAGE]: function (
                { commit }: any,
                page: number
            ): void {
                commit(PHONES_MUTATIONS.SET_PHONES_CURSOR_PAGE, page);
            },
            [PHONES_ACTIONS.SET_PHONES_DELETING_RULE]: function (
                { commit }: any,
                rule: PhonesDeletingRule
            ): void {
                commit(PHONES_MUTATIONS.SET_PHONES_DELETING_RULE, rule);
            },

            [PHONES_ACTIONS.DELETE_PHONE]: async function ({
                state,
            }: any): Promise<void> {
                await api.delete(state.deletingRule);
            },
        },
    };
}
