























































































import { Component, Prop, Vue } from "vue-property-decorator";

import { Citizen } from "@/app/types/citizens";
import Button from "@/app/components/common/Button.vue";
import { CITIZENS_ACTIONS } from "@/app/store/modules/citizens";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";

@Component({ components: { Button } })
export default class CitizenRow extends Vue {
    @Prop({})
    private readonly row: Citizen;

    private get isBlockedLabel(): string {
        return this.row.isBlocked ? "Заблокований" : "Активний";
    }

    private get isBlockedClass(): string {
        return this.row.isBlocked ? "text-red" : "text-green";
    }

    private get addressMain(): string {
        return `${this.row.address.city}, ${this.row.address.district} р-н, вул. ${this.row.address.street}, ${this.row.address.number}`;
    }

    private get addressDistrict(): string {
        return `О. №${this.row.address.constituency} • Д. №${this.row.address.pollingStation}`;
    }

    private async blockCitizen(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(
                CITIZENS_ACTIONS.BLOCK_CITIZEN,
                this.row.id
            );
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Жителя не вдалося заблокувати")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Жителя успішно заблоковано")
        );
        await this.$store.dispatch(CITIZENS_ACTIONS.GET_CITIZENS_LIST);
    }

    private async unblockCitizen(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(
                CITIZENS_ACTIONS.UNBLOCK_CITIZEN,
                this.row.id
            );
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Жителя не вдалося розблокувати")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Жителя успішно розблоковано")
        );
        await this.$store.dispatch(CITIZENS_ACTIONS.GET_CITIZENS_LIST);
    }
}
