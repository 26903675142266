import { Page } from "@/app/types/page";
import {
    DeletedCitizen,
    DeletedCitizensApi,
    DeletedCitizensState,
} from "@/app/types/deletedCitizens";

export const DELETED_CITIZENS_MUTATIONS = {
    SET_DELETED_CITIZENS_LIST: "SET_DELETED_CITIZENS_LIST",
    SET_DELETED_CITIZENS_PAGINATION: "SET_DELETED_CITIZENS_PAGINATION",
    SET_DELETED_CITIZENS_CURSOR_PAGE: "SET_DELETED_CITIZENS_CURSOR_PAGE",
};

export const DELETED_CITIZENS_ACTIONS = {
    GET_DELETED_CITIZENS_LIST: "GET_DELETED_CITIZENS_LIST",
    RESET_DELETED_CITIZENS_CURSOR: "RESET_DELETED_CITIZENS_CURSOR",
    SET_DELETED_CITIZENS_CURSOR_PAGE: "SET_DELETED_CITIZENS_CURSOR_PAGE",
};

export function makeDeletedCitizensModule(api: DeletedCitizensApi) {
    return {
        state: new DeletedCitizensState(),
        mutations: {
            [DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_LIST](
                state: DeletedCitizensState,
                list: DeletedCitizen[]
            ): void {
                state.deletedCitizens = list;
            },
            [DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_PAGINATION](
                state: DeletedCitizensState,
                page: Page
            ): void {
                state.page = page;
            },
            [DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_CURSOR_PAGE](
                state: DeletedCitizensState,
                page: number
            ): void {
                state.cursor.cursor.page = page;
            },
        },
        actions: {
            [DELETED_CITIZENS_ACTIONS.GET_DELETED_CITIZENS_LIST]:
                async function ({ commit, state }: any): Promise<void> {
                    const response = await api.list(state.cursor);
                    commit(
                        DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_LIST,
                        response.deletedCitizens
                    );
                    commit(
                        DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_PAGINATION,
                        response.page
                    );
                },
            [DELETED_CITIZENS_ACTIONS.RESET_DELETED_CITIZENS_CURSOR]:
                function ({ commit }: any): void {
                    commit(
                        DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_PAGINATION,
                        new Page()
                    );
                    commit(
                        DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_CURSOR_PAGE,
                        1
                    );
                },
            [DELETED_CITIZENS_ACTIONS.SET_DELETED_CITIZENS_CURSOR_PAGE]:
                function ({ commit }: any, page: number): void {
                    commit(
                        DELETED_CITIZENS_MUTATIONS.SET_DELETED_CITIZENS_CURSOR_PAGE,
                        page
                    );
                },
        },
    };
}
