











































































































































































import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { ReportApplication } from "@/app/types/report";
import Tag from "@/app/components/common/Tag.vue";
import { Document } from "@/app/types/document";
import { Popup } from "@/app/types/popup";
import ReportActivationApprovalWindow from "@/app/components/popups/ReportActivationApprovalWindow.vue";
import ReportDeactivationApprovalWindow from "@/app/components/popups/ReportDeactivationApprovalWindow.vue";
import LinkDocument from "@/app/components/common/LinkDocument.vue";
import ReportMaterialRemovingApprovalWindow from "@/app/components/popups/ReportMaterialRemovingApprovalWindow.vue";
import { REPORTS_ACTIONS } from "@/app/store/modules/reports";

@Component({ components: { Button, InputField, Dropdown, Tag, LinkDocument } })
export default class ReportApplicationWindow extends Vue {
    public static componentName: string | undefined = "ReportApplicationWindow";

    private get application(): ReportApplication {
        return this.$store.state.reportsModule.application;
    }

    private get isActiveLabel(): string {
        return this.application.isDeactivated ? "Деактивована" : "Активна";
    }

    private get isActiveClass(): string {
        return this.application.isDeactivated
            ? "text text_red"
            : "text text_green";
    }

    private get applicationTitle(): string {
        return this.application.title || `№ ${this.application.publicID}`;
    }

    private get date(): string {
        const formatter = new Intl.DateTimeFormat("uk-UA", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        });

        return formatter.format(new Date(this.application.creationDate));
    }

    private get street(): string {
        return `${this.application.address.street}, ${this.application.address.number}`;
    }

    private get constituency(): string {
        return `№${this.application.address.constituency} / №${this.application.address.pollingStation}`;
    }

    private get documentsList(): Document[] {
        return this.application.documents;
    }

    private getPreviewImage(url): string {
        return `url(${url})`;
    }

    private getFileFormat(name: string): string {
        const array = name.split(".");
        if (array.length < 2) {
            return "";
        }

        return array[array.length - 1].toUpperCase();
    }

    private activateApplication(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(ReportActivationApprovalWindow.componentName, true, false)
        );
    }

    private deactivateApplication(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(
                ReportDeactivationApprovalWindow.componentName,
                true,
                false
            )
        );
    }

    private openRemovingMaterialPopup(id: string): void {
        this.$store.dispatch(REPORTS_ACTIONS.SET_CURRENT_REPORT_MATERIAL_ID, id);

        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(ReportMaterialRemovingApprovalWindow.componentName, true, false)
        );
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
