import {
    AuthorizationApi,
    AuthorizationStatus,
    Credentials,
} from "@/app/types/authorization";
import { CookieManager } from "@/app/utils/cookieManager";

export const AUTHORIZATION_MUTATIONS = {
    SET_AUTHORIZATION: "SET_AUTHORIZATION",
};

export const AUTHORIZATION_ACTIONS = {
    LOGIN: "login",
    LOGOUT: "logout",
    RESEND_INVITATION: "RESEND_INVITATION",
};

export function makeAuthorizationModule(api: AuthorizationApi) {
    return {
        state: new AuthorizationStatus(),
        mutations: {
            [AUTHORIZATION_MUTATIONS.SET_AUTHORIZATION](
                state: AuthorizationStatus,
                auth: AuthorizationStatus
            ): void {
                state = auth;
            },
        },
        actions: {
            [AUTHORIZATION_ACTIONS.LOGIN]: async function (
                { commit }: any,
                cred: Credentials
            ): Promise<void> {
                const token = await api.login(cred);
                CookieManager.setToken(token);
                commit(
                    AUTHORIZATION_MUTATIONS.SET_AUTHORIZATION,
                    new AuthorizationStatus(true)
                );
            },
            [AUTHORIZATION_ACTIONS.LOGOUT]: function ({ commit }: any): void {
                CookieManager.remove(CookieManager.COOKIE_KEY_TOKEN);
                commit(
                    AUTHORIZATION_MUTATIONS.SET_AUTHORIZATION,
                    new AuthorizationStatus(false)
                );
            },
            [AUTHORIZATION_ACTIONS.RESEND_INVITATION]: async function (
                { commit }: any,
                id: string
            ): Promise<boolean> {
                return await api.resendInvitation(id);
            },
        },
    };
}
