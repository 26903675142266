












































import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { PARTIES_ACTIONS, PARTIES_GETTERS } from "@/app/store/modules/parties";
import { PartyEditingRule } from "@/app/types/party";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import Dropdown from "@/app/components/common/Dropdown.vue";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";
import AvatarUpload from "@/app/components/common/AvatarUpload.vue";

@Component({
    components: {
        Button,
        InputField,
        Dropdown,
        CityFilterInputField,
        AvatarUpload,
    },
})
export default class PartyEditingWindow extends Vue {
    public static componentName: string | undefined = "PartyEditingWindow";
    private title: string = this.editingRule.title;
    private photo: File[] = [];

    private get editingRule(): PartyEditingRule {
        return this.$store.getters[PARTIES_GETTERS.GET_PARTIES_EDITING_RULE];
    }

    private get cityOption(): DropdownItemModel {
        return this.editingRule.city;
    }

    private get areAllFieldsFilled(): boolean {
        return !!this.title && !!this.cityOption.id;
    }

    private async selectCity(value: DropdownItemModel): Promise<void> {
        if (this.cityOption.id === value.id) {
            return;
        }

        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_EDITING_PARTY_CITY_RULE,
            value
        );
    }

    private async editParty(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(
                PARTIES_ACTIONS.SET_PARTY_EDITING_RULE,
                new PartyEditingRule(
                    this.editingRule.id,
                    this.title,
                    this.cityOption,
                    this.editingRule.avatar,
                    this.editingRule.avatarPreview
                )
            );
            await this.$store.dispatch(PARTIES_ACTIONS.EDIT_PARTY);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Партію не вдалося відредагувати")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Партію успішно змінено")
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
    }

    private selectPhoto(file: File) {
        this.photo = [];
        if (file !== null) {
            this.photo.push(file);
            this.$store.dispatch(
                PARTIES_ACTIONS.SET_EDITING_PARTY_AVATAR_RULE,
                this.photo[0]
            );
        }
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
