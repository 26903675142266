








import { Component, Vue } from "vue-property-decorator";

import FilterDropdown from "@/app/components/common/FilterDropdown.vue";
import AddressesFilterDropdownInner from "@/app/components/addresses/AddressesFilterDropdownInner.vue";

@Component({ components: { FilterDropdown, AddressesFilterDropdownInner } })
export default class AddressesFilterPanel extends Vue {
    private toggleLoader(value): void {
        if (value) {
            this.$emit("toggleLoader", true);

            return;
        }

        setTimeout(() => {
            this.$emit("toggleLoader", false);
        }, 700);
    }
}
