






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Table extends Vue {
    @Prop({ default: () => [] })
    private readonly columns: string[];

    @Prop({ default: () => [] })
    private readonly rows: string[];
}
