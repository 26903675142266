










import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import Dropdown from "@/app/components/common/Dropdown.vue";
import Tag from "@/app/components/common/Tag.vue";
import { Report } from "@/app/types/report";

@Component({ components: { Button, InputField, Dropdown, Tag } })
export default class ReportFullTextWindow extends Vue {
    public static componentName: string | undefined = "ReportFullTextWindow";

    private get currentReport(): Report {
        return this.$store.state.reportsModule.currentReport;
    }

    private get applicationTitle(): string {
        return (
            this.currentReport.applicationTitle ||
            `№ ${this.currentReport.applicationPublicId}`
        );
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
