export class Page {
    public constructor(
        public totalCount: number = 0,
        public nextPage: number = 0,
        public unfilteredTotalCount: number = 0
    ) {}

    public static fromJson(json: any): Page {
        return new Page(
            json.total_count,
            json.next_page ? json.next_page : -1,
            json.unfiltered_count
        );
    }
}
