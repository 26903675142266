












































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { PaginationModel } from "@/app/types/paginationModel";

@Component({})
export default class Pagination extends Vue {
    @Prop({ default: new PaginationModel() })
    private readonly pagination: PaginationModel;

    private readonly maxPagesVisible: number = 3;

    private get isPrevBtnDisabled(): boolean {
        return this.pagination.currentPage === 1;
    }

    private get isNextBtnDisabled(): boolean {
        return this.pagination.currentPage === this.pagination.totalCount;
    }

    private get isVisible(): boolean {
        return this.pagination.totalCount > 1;
    }

    private get currentPagesList(): number[] {
        let min = 1;
        let max = 1;
        const list: number[] = [];

        if (this.pagination.totalCount <= this.maxPagesVisible) {
            min = 1;
            max = this.pagination.totalCount;
        } else {
            min =
                this.pagination.currentPage + this.maxPagesVisible <
                this.pagination.totalCount
                    ? this.pagination.currentPage
                    : this.pagination.totalCount - this.maxPagesVisible;
            max =
                min + this.maxPagesVisible < this.pagination.totalCount
                    ? min + this.maxPagesVisible
                    : this.pagination.totalCount;
        }

        for (let i = min; i <= max; i++) {
            list.push(i);
        }

        return list;
    }

    private get showFirstPage(): boolean {
        return this.pagination.currentPage - this.maxPagesVisible > 0;
    }

    private get showLastPage(): boolean {
        return (
            this.pagination.currentPage + this.maxPagesVisible <
            this.pagination.totalCount
        );
    }

    private selectPage(newPage: number): void {
        this.$emit("select", newPage);
    }

    private selectPrevPage(): void {
        if (this.pagination.currentPage === 1) {
            return;
        }

        this.$emit("select", this.pagination.currentPage - 1);
    }

    private selectNextPage(): void {
        if (this.pagination.currentPage === this.pagination.totalCount) {
            return;
        }

        this.$emit("select", this.pagination.currentPage + 1);
    }
}
