















































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class InputField extends Vue {
    @Prop({ default: false })
    private readonly isRequired: boolean;

    @Prop({ default: "" })
    private readonly label: string;

    @Prop({ default: "" })
    private readonly value: string | number;

    @Prop({ default: false })
    private readonly disabled: boolean;

    @Prop({ default: false })
    private readonly hasIcon: boolean;

    @Prop({ default: "text" })
    private readonly type: string;

    @Prop({ default: "" })
    private readonly placeholder: string;

    @Prop({ default: "large" })
    private readonly size: string;

    @Prop({ default: "column" })
    private readonly direction: string;

    @Prop({ default: "" })
    private readonly display: string;

    @Prop({ default: false })
    private readonly hasError: boolean;

    @Prop({ default: false })
    private readonly hasCheck: boolean;

    public get classList(): string {
        const classList: string[] = [];

        switch (this.size) {
            case "middle":
                classList.push("middle");
                break;
            case "large":
                classList.push("large");
                break;
        }

        switch (this.direction) {
            case "column":
                classList.push("text-group_column");
                break;
            case "row":
                classList.push("text-group_row");
                break;
        }

        switch (this.display) {
            case "inline":
                classList.push("text-group_inline");
                break;
            case "full":
                classList.push("text-group_full");
                break;
        }

        return classList.join(" ");
    }
}
