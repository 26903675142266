













































import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import Dropdown from "@/app/components/common/Dropdown.vue";
import Checkbox from "@/app/components/common/Checkbox.vue";
import {
    MANAGERS_ACTIONS,
    MANAGERS_GETTERS,
} from "@/app/store/modules/managers";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import { ManagerEditingRule } from "@/app/types/manager";
import { AUTHORIZATION_ACTIONS } from "@/app/store/modules/authorization";

@Component({ components: { Button, InputField, Dropdown, Checkbox } })
export default class ManagerEditingWindow extends Vue {
    public static componentName: string | undefined = "ManagerEditingWindow";

    public firstName: string = this.editingRule.firstName;
    public middleName: string = this.editingRule.middleName;
    public lastName: string = this.editingRule.lastName;
    public isFinishedRegistration: boolean =
        this.editingRule.isFinishedRegistration;

    private get editingRule(): ManagerEditingRule {
        return this.$store.getters[MANAGERS_GETTERS.GET_MANAGERS_EDITING_RULE];
    }

    private get areAllFieldsFilled(): boolean {
        return !!this.firstName && !!this.lastName;
    }

    private async resendInvitation(): Promise<void> {
        try {
            await this.$store.dispatch(
                AUTHORIZATION_ACTIONS.RESEND_INVITATION,
                this.editingRule.id
            );
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert(
                    "error",
                    true,
                    "Не вдалося надіслати повторне запрошення"
                )
            );
            console.error(e);

            return;
        }
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Запрошення повторно надіслано")
        );
    }

    private async editManager(): Promise<void> {
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_FIRST_NAME_RULE,
            this.firstName
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_MIDDLE_NAME_RULE,
            this.middleName
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_EDITING_MANAGER_LAST_NAME_RULE,
            this.lastName
        );

        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(MANAGERS_ACTIONS.EDIT_MANAGER);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Менеджера не вдалося редагувати")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Менеджера успішно змінено")
        );
        await this.$store.dispatch(MANAGERS_ACTIONS.GET_MANAGERS);
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
