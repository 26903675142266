import { Page } from "@/app/types/page";
import { HttpClient } from "@/deputy/utils/httpClient";
import {
    Report,
    ReportApplication,
    ReportsApi,
    ReportsCursor,
    ReportsPage,
    statusChangingRule,
} from "@/app/types/report";

export class ReportsHttpApi implements ReportsApi {
    private readonly client: HttpClient = new HttpClient();

    public async list(cursor: ReportsCursor): Promise<ReportsPage> {
        const response = await this.client.get(`/report?${cursor.toQuery()}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new ReportsPage([], Page.fromJson(response));
        }

        return new ReportsPage(
            response.data.map((report) => {
                return Report.fromJson(report);
            }),
            Page.fromJson(response)
        );
    }

    public async getReport(id: string): Promise<Report> {
        const response = await this.client.get(`/report/${id}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new Report();
        }

        return Report.fromJson(response.data[0].report);
    }

    public async changeStatus(rule: statusChangingRule): Promise<void> {
        const response = await this.client.put(
            `/report/${rule.id}/update-status`,
            JSON.stringify({ status: rule.newStatus.id })
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }
    }

    public async getApplication(id: string): Promise<ReportApplication> {
        const response = await this.client.get(`/report/${id}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new ReportApplication();
        }

        return ReportApplication.fromJson(response.data[0].application);
    }

    public async activateApplication(id: string): Promise<void> {
        const response = await this.client.post(
            `/application/${id}/activation`,
            null
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }
    }

    public async deactivateApplication(id: string): Promise<void> {
        const response = await this.client.delete(
            `/application/${id}/activation`
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }
    }

    public async removeApplicationMaterial(id: string): Promise<void> {
        const response = await this.client.delete(`/files/${id}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }
    }
}
