



































import { Component, Vue } from "vue-property-decorator";

import FilterDropdown from "@/app/components/common/FilterDropdown.vue";
import DeputiesFilterDropdownInner from "@/app/components/deputies/DeputiesFilterDropdownInner.vue";
import Button from "@/app/components/common/Button.vue";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import DeputyCreatingWindow from "@/app/components/popups/DeputyCreatingWindow.vue";

@Component({
    components: { FilterDropdown, Button, DeputiesFilterDropdownInner },
})
export default class DeputiesFilterPanel extends Vue {
    private get totalCount(): number {
        return this.$store.state.deputiesModule.page.totalCount;
    }

    private toggleLoader(value): void {
        if (value) {
            this.$emit("toggleLoader", true);

            return;
        }

        setTimeout(() => {
            this.$emit("toggleLoader", false);
        }, 700);
    }

    private async openCreatingPopup(): Promise<void> {
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            new DropdownItemModel()
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(DeputyCreatingWindow.componentName, true, false)
        );
    }
}
