export class StreetModel {
    public constructor(
        public id: string | number = "",
        public street: string = "",
        public number: string = ""
    ) {}

    public static fromJson(obj: any): StreetModel {
        return new StreetModel(obj.id, obj.street, obj.number);
    }
}
