




import { Component, Prop, Vue } from "vue-property-decorator";
import { ApplicationStatus } from "@/app/types/report";

@Component({})
export default class Tag extends Vue {
    @Prop({ default: 0 })
    private readonly status: number;

    private get title(): string {
        switch (this.status) {
            case ApplicationStatus.New:
                return "Нове звернення";
            case ApplicationStatus.Issued:
                return "Оформлено";
            case ApplicationStatus.InProgress:
                return "Прийнято в роботу";
            case ApplicationStatus.ResponseReceived:
                return "Вiдповiдь отримано";
            case ApplicationStatus.Implemented:
                return "Виконано";
            case ApplicationStatus.Undefined:
            default:
                return "Без статусу";
        }
    }

    private get type(): string {
        switch (this.status) {
            case ApplicationStatus.New:
                return "new";
            case ApplicationStatus.Issued:
                return "issued";
            case ApplicationStatus.InProgress:
                return "in-progress";
            case ApplicationStatus.ResponseReceived:
                return "response-received";
            case ApplicationStatus.Implemented:
                return "implemented";
            case ApplicationStatus.Undefined:
            default:
                return "";
        }
    }
}
