



























import { Component, Vue } from "vue-property-decorator";
import PartyRow from "@/app/components/parties/PartyRow.vue";
import { Party } from "@/app/types/party";
import Table from "@/app/components/common/Table.vue";
import Button from "@/app/components/common/Button.vue";
import { PARTIES_ACTIONS, PARTIES_GETTERS } from "@/app/store/modules/parties";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { FILTERS_ACTIONS, FILTERS_GETTERS } from "@/app/store/modules/filters";
import { PaginationModel } from "@/app/types/paginationModel";
import Pagination from "@/app/components/common/Pagination.vue";
import { DefaultFilters } from "@/app/types/defaultFilters";
import { CitiesListRule } from "@/app/types/filters";
import PartiesFilterPanel from "@/app/components/parties/PartiesFilterPanel.vue";
import Loader from "@/app/components/common/Loader.vue";

@Component({
    components: {
        PartyRow,
        Table,
        Button,
        Dropdown,
        Pagination,
        PartiesFilterPanel,
        Loader,
    },
})
export default class PartiesPage extends Vue {
    private columns: string[] = [
        "#",
        "Лого",
        "Партія",
        "Місто",
        "К-ть депутатів",
        "Опції",
    ];
    private loaderVisibility: boolean | undefined = false;

    private get pagination(): PaginationModel {
        return new PaginationModel(
            Math.ceil(
                this.$store.state.partiesModule.page.totalCount /
                    this.$store.state.partiesModule.cursor.cursor.limit
            ),
            this.$store.state.partiesModule.cursor.cursor.page,
            this.$store.state.partiesModule.page.nextPage
        );
    }

    private get rows(): Party[] {
        return this.$store.state.partiesModule.parties;
    }

    private toggleLoader(value): void {
        this.loaderVisibility = value;
    }

    private async getNewParties(page) {
        try {
            await this.$store.dispatch(
                PARTIES_ACTIONS.SET_PARTIES_CURSOR_PAGE,
                page
            );
            await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    async mounted() {
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CITIES_LIST,
            new CitiesListRule(DefaultFilters.DEFAULT_CITY)
        );
        let cities = this.$store.state.filtersModule.citiesState.list;
        let defaultCity =
            cities.find((city) => city.name === DefaultFilters.DEFAULT_CITY) ||
            cities[0];

        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            defaultCity
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
    }
}
