
















import { Component, Prop, Vue } from "vue-property-decorator";
import { DeletedCitizen } from "@/app/types/deletedCitizens";

@Component({})
export default class DeletedCitizensRow extends Vue {
    @Prop({ default: new DeletedCitizen() })
    private readonly row: DeletedCitizen;

    private get date(): string {
        const formatter = new Intl.DateTimeFormat("uk-UA", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        });

        return formatter.format(new Date(this.row.deletedAt));
    }
}
