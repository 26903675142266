






















































import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import {
    MANAGERS_ACTIONS,
    MANAGERS_GETTERS,
} from "@/app/store/modules/managers";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { ManagerCreationRule } from "@/app/types/manager";
import DeputiesSearchInputField from "@/app/components/common/DeputiesSearchInputField.vue";

@Component({
    components: { Button, InputField, Dropdown, DeputiesSearchInputField },
})
export default class ManagerCreatingWindow extends Vue {
    public static componentName: string | undefined = "ManagerCreatingWindow";

    public login: string | undefined = "";
    public firstName: string | undefined = "";
    public middleName: string | undefined = "";
    public lastName: string | undefined = "";

    private get creationRule(): ManagerCreationRule {
        return this.$store.getters[MANAGERS_GETTERS.GET_MANAGERS_CREATION_RULE];
    }

    private get areAllFieldsFilled(): boolean {
        return (
            !!this.login &&
            !!this.firstName &&
            !!this.lastName &&
            !!this.creationRule.deputy.id
        );
    }

    private async selectDeputy(value: DropdownItemModel): Promise<void> {
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_CREATION_MANAGER_DEPUTY_RULE,
            value
        );
    }

    private async createManager(): Promise<void> {
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_CREATION_MANAGER_LOGIN_RULE,
            this.login
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_CREATION_MANAGER_FIRST_NAME_RULE,
            this.firstName
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_CREATION_MANAGER_MIDDLE_NAME_RULE,
            this.middleName
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_CREATION_MANAGER_LAST_NAME_RULE,
            this.lastName
        );

        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(MANAGERS_ACTIONS.CREATE_MANAGER);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Менеджера не вдалося створити")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Менеджера успішно створено")
        );
        await this.$store.dispatch(MANAGERS_ACTIONS.GET_MANAGERS);
    }

    private cancel(): void {
        console.log("cancel creation");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
