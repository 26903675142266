













































import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/app/components/common/Button.vue";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { DistrictEditingRule } from "@/app/types/filters";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import DistrictEditingWindow from "@/app/components/popups/DistrictEditingWindow.vue";

@Component({ components: { Button } })
export default class DistrictRow extends Vue {
    @Prop({ default: new DropdownItemModel() })
    private readonly row: DropdownItemModel;

    private async openEditingPopup(): Promise<void> {
        await this.$store.dispatch(
            FILTERS_ACTIONS.SET_DISTRICT_EDITING_RULE,
            new DistrictEditingRule(String(this.row.id), String(this.row.name))
        );

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(DistrictEditingWindow.componentName, true, false)
        );
    }
}
