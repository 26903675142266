











import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import Dropdown from "@/app/components/common/Dropdown.vue";
import Tag from "@/app/components/common/Tag.vue";
import { REPORTS_ACTIONS } from "@/app/store/modules/reports";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";

@Component({ components: { Button, InputField, Dropdown, Tag } })
export default class ReportMaterialRemovingApprovalWindow extends Vue {
    public static componentName: string | undefined =
        "ReportMaterialRemovingApprovalWindow";

    private async removeMaterial(): Promise<void> {
        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState().Visible()
        );

        try {
            await this.$store.dispatch(
                REPORTS_ACTIONS.REMOVE_REPORT_APPLICATION_MATERIAL
            );
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Матеріал не вдалося видалити")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Матеріал успішно видалено")
        );

        setTimeout(() => {
            this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
        }, 700);
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
