import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import {
    CitiesCursor,
    CitiesListRule,
    ConstituenciesListRule,
    ConstituencyDetailed,
    ConstituencyEditingRule,
    DistrictEditingRule,
    FiltersApi,
    FiltersListState,
    PollingStationsListRule,
    StreetsCursor,
    StreetsListRule,
} from "@/app/types/filters";
import { StreetModel } from "@/app/types/streetModel";
import { Page } from "@/app/types/page";

export const FILTERS_MUTATIONS = {
    SET_CITIES_LIST: "SET_CITIES_LIST",
    EXPAND_CITIES_LIST: "EXPAND_CITIES_LIST",
    SET_CITIES_CURSOR_PAGE: "SET_CITIES_CURSOR_PAGE",
    SET_CITIES_PAGINATION: "SET_CITIES_PAGINATION",
    SET_DISTRICTS_LIST: "SET_DISTRICTS_LIST",
    SET_CONSTITUENCIES_LIST: "SET_CONSTITUENCIES_LIST",
    SET_POLLING_STATIONS_LIST: "SET_POLLING_STATIONS_LIST",
    SET_STREETS_LIST: "SET_STREETS_LIST",
    EXPAND_STREETS_LIST: "EXPAND_STREETS_LIST",
    SET_STREETS_CURSOR_PAGE: "SET_STREETS_CURSOR_PAGE",
    SET_STREETS_PAGINATION: "SET_STREETS_PAGINATION",

    SET_ADDRESS_PAGE_CURSOR_CITY_RULE: "SET_ADDRESS_PAGE_CURSOR_CITY_RULE",
    SET_DISTRICT_EDITING_RULE: "SET_DISTRICT_EDITING_RULE",
    SET_CONSTITUENCY_EDITING_RULE: "SET_CONSTITUENCY_EDITING_RULE",

    EDIT_DISTRICT: "EDIT_DISTRICT",
    EDIT_CONSTITUENCY: "EDIT_CONSTITUENCY",
};

export const FILTERS_ACTIONS = {
    GET_CITIES_LIST: "GET_CITIES_LIST",
    EXPAND_CITIES_LIST: "EXPAND_CITIES_LIST",
    SET_CITIES_CURSOR_PAGE: "SET_CITIES_CURSOR_PAGE",
    GET_DISTRICTS_LIST: "GET_DISTRICTS_LIST",
    GET_CONSTITUENCIES_LIST: "GET_CONSTITUENCIES_LIST",
    GET_POLLING_STATIONS_LIST: "GET_POLLING_STATIONS_LIST",
    GET_STREETS_LIST: "GET_STREETS_LIST",
    EXPAND_STREETS_LIST: "EXPAND_STREETS_LIST",
    SET_STREETS_CURSOR_PAGE: "SET_STREETS_CURSOR_PAGE",
    SET_STREETS_PAGINATION: "SET_STREETS_PAGINATION",
    RESET_FILTERS_LIST: "RESET_FILTERS_LIST",

    SET_ADDRESS_PAGE_CURSOR_CITY_RULE: "SET_ADDRESS_PAGE_CURSOR_CITY_RULE",
    SET_DISTRICT_EDITING_RULE: "SET_DISTRICT_EDITING_RULE",
    SET_CONSTITUENCY_EDITING_RULE: "SET_CONSTITUENCY_EDITING_RULE",

    EDIT_DISTRICT: "EDIT_DISTRICT",
    EDIT_CONSTITUENCY: "EDIT_CONSTITUENCY",
};

export const FILTERS_GETTERS = {
    CITIES_LIST: "CITIES_LIST",

    DISTRICT_EDITING_RULE: "DISTRICT_EDITING_RULE",
    CONSTITUENCY_EDITING_RULE: "CONSTITUENCY_EDITING_RULE",
};

export function makeFiltersModule(api: FiltersApi) {
    return {
        state: new FiltersListState(),
        mutations: {
            [FILTERS_MUTATIONS.SET_CITIES_LIST](
                state: FiltersListState,
                list: DropdownItemModel[]
            ): void {
                state.citiesState.list = list;
            },
            [FILTERS_MUTATIONS.EXPAND_CITIES_LIST](
                state: FiltersListState,
                list: DropdownItemModel[]
            ): void {
                list.forEach((city) => {
                    state.citiesState.list.push(city);
                });
            },
            [FILTERS_MUTATIONS.SET_CITIES_CURSOR_PAGE](
                state: FiltersListState,
                page: number
            ): void {
                state.citiesState.cursor.cursor.page = page;
            },
            [FILTERS_MUTATIONS.SET_CITIES_PAGINATION](
                state: FiltersListState,
                page: Page
            ): void {
                state.citiesState.page = page;
            },
            [FILTERS_MUTATIONS.SET_DISTRICTS_LIST](
                state: FiltersListState,
                list: DropdownItemModel[]
            ): void {
                state.districtsList = list;
            },
            [FILTERS_MUTATIONS.SET_CONSTITUENCIES_LIST](
                state: FiltersListState,
                list: ConstituencyDetailed[]
            ): void {
                state.constituenciesList = list;
            },
            [FILTERS_MUTATIONS.SET_POLLING_STATIONS_LIST](
                state: FiltersListState,
                list: DropdownItemModel[]
            ): void {
                state.pollingStationsList = list;
            },
            [FILTERS_MUTATIONS.SET_STREETS_LIST](
                state: FiltersListState,
                list: StreetModel[]
            ): void {
                state.streetsState.list = list;
            },
            [FILTERS_MUTATIONS.EXPAND_STREETS_LIST](
                state: FiltersListState,
                list: StreetModel[]
            ): void {
                list.forEach((address) => {
                    state.streetsState.list.push(address);
                });
            },
            [FILTERS_MUTATIONS.SET_STREETS_CURSOR_PAGE](
                state: FiltersListState,
                page: number
            ): void {
                state.streetsState.cursor.cursor.page = page;
            },
            [FILTERS_MUTATIONS.SET_STREETS_PAGINATION](
                state: FiltersListState,
                page: Page
            ): void {
                state.streetsState.page = page;
            },

            [FILTERS_MUTATIONS.SET_ADDRESS_PAGE_CURSOR_CITY_RULE](
                state: FiltersListState,
                city: DropdownItemModel
            ): void {
                state.cursor.city = city;
            },
            [FILTERS_MUTATIONS.SET_DISTRICT_EDITING_RULE](
                state: FiltersListState,
                rule: DistrictEditingRule
            ): void {
                state.districtEditingRule = rule;
            },
            [FILTERS_MUTATIONS.SET_CONSTITUENCY_EDITING_RULE](
                state: FiltersListState,
                rule: ConstituencyEditingRule
            ): void {
                state.constituencyEditingRule = rule;
            },
        },
        actions: {
            [FILTERS_ACTIONS.GET_CITIES_LIST]: async function (
                { commit, state }: any,
                listRule: CitiesListRule
            ): Promise<void> {
                const response = await api.getCities(
                    new CitiesCursor(
                        listRule.inputValue,
                        state.citiesState.cursor.cursor
                    )
                );
                commit(FILTERS_MUTATIONS.SET_CITIES_LIST, response.list);
                commit(FILTERS_MUTATIONS.SET_CITIES_PAGINATION, response.page);
            },
            [FILTERS_ACTIONS.EXPAND_CITIES_LIST]: async function (
                { commit, state }: any,
                listRule: CitiesListRule
            ): Promise<void> {
                const response = await api.getCities(
                    new CitiesCursor(
                        listRule.inputValue,
                        state.citiesState.cursor.cursor
                    )
                );
                commit(FILTERS_MUTATIONS.EXPAND_CITIES_LIST, response.list);
                commit(FILTERS_MUTATIONS.SET_CITIES_PAGINATION, response.page);
            },
            [FILTERS_ACTIONS.SET_CITIES_CURSOR_PAGE]: function (
                { commit }: any,
                page: number
            ): void {
                commit(FILTERS_MUTATIONS.SET_CITIES_CURSOR_PAGE, page);
            },
            [FILTERS_ACTIONS.GET_DISTRICTS_LIST]: async function (
                { commit }: any,
                cityId: string
            ): Promise<void> {
                const list = await api.getDistricts(cityId);
                commit(FILTERS_MUTATIONS.SET_DISTRICTS_LIST, list);
            },
            [FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST]: async function (
                { commit }: any,
                listRule: ConstituenciesListRule
            ): Promise<void> {
                const list = await api.getConstituencies(listRule);
                commit(FILTERS_MUTATIONS.SET_CONSTITUENCIES_LIST, list);
            },
            [FILTERS_ACTIONS.GET_POLLING_STATIONS_LIST]: async function (
                { commit }: any,
                listRule: PollingStationsListRule
            ): Promise<void> {
                const list = await api.getPollingStations(listRule);
                commit(FILTERS_MUTATIONS.SET_POLLING_STATIONS_LIST, list);
            },
            [FILTERS_ACTIONS.GET_STREETS_LIST]: async function (
                { commit, state }: any,
                listRule: StreetsListRule
            ): Promise<void> {
                const response = await api.getStreets(
                    new StreetsCursor(
                        listRule.ids.cityId,
                        listRule.ids.districtId,
                        listRule.ids.constituencyId,
                        listRule.ids.pollingStationId,
                        listRule.inputValue,
                        state.streetsState.cursor.cursor
                    )
                );
                commit(FILTERS_MUTATIONS.SET_STREETS_LIST, response.list);
                commit(FILTERS_MUTATIONS.SET_STREETS_PAGINATION, response.page);
            },
            [FILTERS_ACTIONS.EXPAND_STREETS_LIST]: async function (
                { commit, state }: any,
                listRule: StreetsListRule
            ): Promise<void> {
                const response = await api.getStreets(
                    new StreetsCursor(
                        listRule.ids.cityId,
                        listRule.ids.districtId,
                        listRule.ids.constituencyId,
                        listRule.ids.pollingStationId,
                        listRule.inputValue,
                        state.streetsState.cursor.cursor
                    )
                );
                commit(FILTERS_MUTATIONS.EXPAND_STREETS_LIST, response.list);
                commit(FILTERS_MUTATIONS.SET_STREETS_PAGINATION, response.page);
            },
            [FILTERS_ACTIONS.SET_STREETS_CURSOR_PAGE]: function (
                { commit }: any,
                page: number
            ): void {
                commit(FILTERS_MUTATIONS.SET_STREETS_CURSOR_PAGE, page);
            },
            [FILTERS_ACTIONS.RESET_FILTERS_LIST]: function ({
                commit,
            }: any): void {
                commit(FILTERS_MUTATIONS.SET_CITIES_LIST, []);
                commit(FILTERS_MUTATIONS.SET_DISTRICTS_LIST, []);
                commit(FILTERS_MUTATIONS.SET_CONSTITUENCIES_LIST, []);
                commit(FILTERS_MUTATIONS.SET_POLLING_STATIONS_LIST, []);
                commit(FILTERS_MUTATIONS.SET_STREETS_LIST, []);
            },

            [FILTERS_ACTIONS.SET_ADDRESS_PAGE_CURSOR_CITY_RULE]: function (
                { commit, state }: any,
                city: DropdownItemModel
            ): void {
                commit(
                    FILTERS_MUTATIONS.SET_ADDRESS_PAGE_CURSOR_CITY_RULE,
                    city
                );
            },
            [FILTERS_ACTIONS.SET_DISTRICT_EDITING_RULE]: function (
                { commit }: any,
                rule: DistrictEditingRule
            ): void {
                commit(FILTERS_MUTATIONS.SET_DISTRICT_EDITING_RULE, rule);
            },
            [FILTERS_ACTIONS.SET_CONSTITUENCY_EDITING_RULE]: function (
                { commit }: any,
                rule: ConstituencyEditingRule
            ): void {
                commit(FILTERS_MUTATIONS.SET_CONSTITUENCY_EDITING_RULE, rule);
            },

            [FILTERS_ACTIONS.EDIT_DISTRICT]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                await api.editDistrict(state.districtEditingRule);
            },
            [FILTERS_ACTIONS.EDIT_CONSTITUENCY]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                await api.editConstituency(state.constituencyEditingRule);
            },
        },
        getters: {
            [FILTERS_GETTERS.CITIES_LIST]: (state) => {
                return state.citiesState.list;
            },

            [FILTERS_GETTERS.DISTRICT_EDITING_RULE]: (state) => {
                return state.districtEditingRule;
            },
            [FILTERS_GETTERS.CONSTITUENCY_EDITING_RULE]: (state) => {
                return state.constituencyEditingRule;
            },
        },
    };
}
