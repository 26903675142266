



















































import { Component, Prop, Vue } from "vue-property-decorator";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";

@Component({})
export default class Alert extends Vue {
    private get visibility(): string {
        return this.$store.state.appStateModule.alert.visibility;
    }

    private get type(): string {
        return this.$store.state.appStateModule.alert.type;
    }

    private get text(): string {
        return this.$store.state.appStateModule.alert.text;
    }

    private close(): void {
        this.$store.dispatch(APPSTATE_ACTIONS.STOP_ALERT_STATE);
    }
}
