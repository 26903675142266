import { Page } from "@/app/types/page";
import { HttpClient } from "@/deputy/utils/httpClient";
import {
    Manager,
    ManagerCreationRule,
    ManagerDeletingRule,
    ManagerEditingRule,
    ManagersApi,
    ManagersCursor,
    ManagersPage,
} from "@/app/types/manager";

export class ManagersHttpApi implements ManagersApi {
    private readonly client: HttpClient = new HttpClient();

    public async list(cursor: ManagersCursor): Promise<ManagersPage> {
        const response = await this.client.get(`/manager?${cursor.toQuery()}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new ManagersPage([], Page.fromJson(response));
        }

        return new ManagersPage(
            response.data.map((manager) => {
                return Manager.fromJson(manager);
            }),
            Page.fromJson(response)
        );
    }

    public async create(rule: ManagerCreationRule): Promise<boolean> {
        const response = await this.client.post("/manager", rule.toJson());
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }

    public async edit(rule: ManagerEditingRule): Promise<boolean> {
        const response = await this.client.put(
            `/manager/${rule.id}`,
            rule.toJson()
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }

    public async delete(rule: ManagerDeletingRule): Promise<boolean> {
        const response = await this.client.delete(`/manager/${rule.id}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }
}
