import { Page } from "@/app/types/page";
import {
    DeputiesApi,
    DeputiesState,
    Deputy,
    DeputyAvatarRule,
    DeputyCreationRule,
    DeputyDeletingRule,
} from "@/app/types/deputy";
import { Party } from "@/app/types/party";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

export const DEPUTIES_MUTATIONS = {
    GET_DEPUTIES: "GET_DEPUTIES",
    EXPAND_DEPUTIES_LIST: "EXPAND_DEPUTIES_LIST",
    SET_DEPUTIES_CURSOR_PAGE: "SET_DEPUTIES_CURSOR_PAGE",
    SET_DEPUTIES_CURSOR_CITY_RULE: "SET_DEPUTIES_CURSOR_CITY_RULE",
    SET_DEPUTIES_CURSOR_PARTY_RULE: "SET_DEPUTIES_CURSOR_PARTY_RULE",
    SET_DEPUTIES_CURSOR_CONNECTION_RULE: "SET_DEPUTIES_CURSOR_CONNECTION_RULE",
    SET_DEPUTIES_CURSOR_NAME_RULE: "SET_DEPUTIES_CURSOR_NAME_RULE",
    SET_DEPUTIES_PAGINATION: "SET_DEPUTIES_PAGINATION",

    SET_CREATION_DEPUTY_AVATAR_RULE: "SET_CREATION_DEPUTY_AVATAR_RULE",
    SET_CREATION_DEPUTY_FIRST_NAME_RULE: "SET_CREATION_DEPUTY_FIRST_NAME_RULE",
    SET_CREATION_DEPUTY_MIDDLE_NAME_RULE:
        "SET_CREATION_DEPUTY_MIDDLE_NAME_RULE",
    SET_CREATION_DEPUTY_LAST_NAME_RULE: "SET_CREATION_DEPUTY_LAST_NAME_RULE",
    SET_CREATION_DEPUTY_PARTY_RULE: "SET_CREATION_DEPUTY_PARTY_RULE",
    SET_CREATION_DEPUTY_CITY_RULE: "SET_CREATION_DEPUTY_CITY_RULE",
    SET_CREATION_DEPUTY_DISTRICT_RULE: "SET_CREATION_DEPUTY_DISTRICT_RULE",
    SET_CREATION_DEPUTY_CONSTITUENCY_RULE:
        "SET_CREATION_DEPUTY_CONSTITUENCY_RULE",
    SET_CREATION_DEPUTY_CONNECTED_RULE: "SET_CREATION_DEPUTY_CONNECTED_RULE",
    SET_CREATION_DEPUTY_RECEPTION_RULE: "SET_CREATION_DEPUTY_RECEPTION_RULE",
    SET_CREATION_DEPUTY_TWITTER_RULE: "SET_CREATION_DEPUTY_TWITTER_RULE",
    SET_CREATION_DEPUTY_TELEGRAM_RULE: "SET_CREATION_DEPUTY_TELEGRAM_RULE",
    SET_CREATION_DEPUTY_INSTAGRAM_RULE: "SET_CREATION_DEPUTY_INSTAGRAM_RULE",
    SET_CREATION_DEPUTY_FACEBOOK_RULE: "SET_CREATION_DEPUTY_FACEBOOK_RULE",
    SET_CREATION_DEPUTY_WEB_RULE: "SET_CREATION_DEPUTY_WEB_RULE",

    SET_EDITING_DEPUTY_ID_RULE: "SET_EDITING_DEPUTY_ID_RULE",
    SET_EDITING_DEPUTY_AVATAR_RULE: "SET_EDITING_DEPUTY_AVATAR_RULE",
    SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE:
        "SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE",
    SET_EDITING_DEPUTY_FIRST_NAME_RULE: "SET_EDITING_DEPUTY_FIRST_NAME_RULE",
    SET_EDITING_DEPUTY_MIDDLE_NAME_RULE: "SET_EDITING_DEPUTY_MIDDLE_NAME_RULE",
    SET_EDITING_DEPUTY_LAST_NAME_RULE: "SET_EDITING_DEPUTY_LAST_NAME_RULE",
    SET_EDITING_DEPUTY_PARTY_RULE: "SET_EDITING_DEPUTY_PARTY_RULE",
    SET_EDITING_DEPUTY_CITY_RULE: "SET_EDITING_DEPUTY_CITY_RULE",
    SET_EDITING_DEPUTY_DISTRICT_RULE: "SET_EDITING_DEPUTY_DISTRICT_RULE",
    SET_EDITING_DEPUTY_CONSTITUENCY_RULE:
        "SET_EDITING_DEPUTY_CONSTITUENCY_RULE",
    SET_EDITING_DEPUTY_CONNECTED_RULE: "SET_EDITING_DEPUTY_CONNECTED_RULE",
    SET_EDITING_DEPUTY_RECEPTION_RULE: "SET_EDITING_DEPUTY_RECEPTION_RULE",
    SET_EDITING_DEPUTY_TWITTER_RULE: "SET_EDITING_DEPUTY_TWITTER_RULE",
    SET_EDITING_DEPUTY_TELEGRAM_RULE: "SET_EDITING_DEPUTY_TELEGRAM_RULE",
    SET_EDITING_DEPUTY_INSTAGRAM_RULE: "SET_EDITING_DEPUTY_INSTAGRAM_RULE",
    SET_EDITING_DEPUTY_FACEBOOK_RULE: "SET_EDITING_DEPUTY_FACEBOOK_RULE",
    SET_EDITING_DEPUTY_WEB_RULE: "SET_EDITING_DEPUTY_WEB_RULE",

    SET_DEPUTY_DELETING_RULE: "SET_DEPUTY_DELETING_RULE",

    RESET_DEPUTY_CREATION_RULE: "RESET_DEPUTY_CREATION_RULE",
};

export const DEPUTIES_ACTIONS = {
    GET_DEPUTIES: "GET_DEPUTIES",
    EXPAND_DEPUTIES_LIST: "EXPAND_DEPUTIES_LIST",
    SET_DEPUTIES_CURSOR_PAGE: "SET_DEPUTIES_CURSOR_PAGE",
    SET_DEPUTIES_CURSOR_CITY_RULE: "SET_DEPUTIES_CURSOR_CITY_RULE",
    SET_DEPUTIES_CURSOR_PARTY_RULE: "SET_DEPUTIES_CURSOR_PARTY_RULE",
    SET_DEPUTIES_CURSOR_CONNECTION_RULE: "SET_DEPUTIES_CURSOR_CONNECTION_RULE",
    SET_DEPUTIES_CURSOR_NAME_RULE: "SET_DEPUTIES_CURSOR_NAME_RULE",
    RESET_DEPUTIES_CURSOR: "RESET_DEPUTIES_CURSOR",

    SET_CREATION_DEPUTY_AVATAR_RULE: "SET_CREATION_DEPUTY_AVATAR_RULE",
    SET_CREATION_DEPUTY_FIRST_NAME_RULE: "SET_CREATION_DEPUTY_FIRST_NAME_RULE",
    SET_CREATION_DEPUTY_MIDDLE_NAME_RULE:
        "SET_CREATION_DEPUTY_MIDDLE_NAME_RULE",
    SET_CREATION_DEPUTY_LAST_NAME_RULE: "SET_CREATION_DEPUTY_LAST_NAME_RULE",
    SET_CREATION_DEPUTY_PARTY_RULE: "SET_CREATION_DEPUTY_PARTY_RULE",
    SET_CREATION_DEPUTY_CITY_RULE: "SET_CREATION_DEPUTY_CITY_RULE",
    SET_CREATION_DEPUTY_DISTRICT_RULE: "SET_CREATION_DEPUTY_DISTRICT_RULE",
    SET_CREATION_DEPUTY_CONSTITUENCY_RULE:
        "SET_CREATION_DEPUTY_CONSTITUENCY_RULE",
    SET_CREATION_DEPUTY_CONNECTED_RULE: "SET_CREATION_DEPUTY_CONNECTED_RULE",
    SET_CREATION_DEPUTY_RECEPTION_RULE: "SET_CREATION_DEPUTY_RECEPTION_RULE",
    SET_CREATION_DEPUTY_TWITTER_RULE: "SET_CREATION_DEPUTY_TWITTER_RULE",
    SET_CREATION_DEPUTY_TELEGRAM_RULE: "SET_CREATION_DEPUTY_TELEGRAM_RULE",
    SET_CREATION_DEPUTY_INSTAGRAM_RULE: "SET_CREATION_DEPUTY_INSTAGRAM_RULE",
    SET_CREATION_DEPUTY_FACEBOOK_RULE: "SET_CREATION_DEPUTY_FACEBOOK_RULE",
    SET_CREATION_DEPUTY_WEB_RULE: "SET_CREATION_DEPUTY_WEB_RULE",

    SET_EDITING_DEPUTY_ID_RULE: "SET_EDITING_DEPUTY_ID_RULE",
    SET_EDITING_DEPUTY_AVATAR_RULE: "SET_EDITING_DEPUTY_AVATAR_RULE",
    SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE:
        "SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE",
    SET_EDITING_DEPUTY_FIRST_NAME_RULE: "SET_EDITING_DEPUTY_FIRST_NAME_RULE",
    SET_EDITING_DEPUTY_MIDDLE_NAME_RULE: "SET_EDITING_DEPUTY_MIDDLE_NAME_RULE",
    SET_EDITING_DEPUTY_LAST_NAME_RULE: "SET_EDITING_DEPUTY_LAST_NAME_RULE",
    SET_EDITING_DEPUTY_PARTY_RULE: "SET_EDITING_DEPUTY_PARTY_RULE",
    SET_EDITING_DEPUTY_CITY_RULE: "SET_EDITING_DEPUTY_CITY_RULE",
    SET_EDITING_DEPUTY_DISTRICT_RULE: "SET_EDITING_DEPUTY_DISTRICT_RULE",
    SET_EDITING_DEPUTY_CONSTITUENCY_RULE:
        "SET_EDITING_DEPUTY_CONSTITUENCY_RULE",
    SET_EDITING_DEPUTY_CONNECTED_RULE: "SET_EDITING_DEPUTY_CONNECTED_RULE",
    SET_EDITING_DEPUTY_RECEPTION_RULE: "SET_EDITING_DEPUTY_RECEPTION_RULE",
    SET_EDITING_DEPUTY_TWITTER_RULE: "SET_EDITING_DEPUTY_TWITTER_RULE",
    SET_EDITING_DEPUTY_TELEGRAM_RULE: "SET_EDITING_DEPUTY_TELEGRAM_RULE",
    SET_EDITING_DEPUTY_INSTAGRAM_RULE: "SET_EDITING_DEPUTY_INSTAGRAM_RULE",
    SET_EDITING_DEPUTY_FACEBOOK_RULE: "SET_EDITING_DEPUTY_FACEBOOK_RULE",
    SET_EDITING_DEPUTY_WEB_RULE: "SET_EDITING_DEPUTY_WEB_RULE",

    SET_DEPUTY_DELETING_RULE: "SET_DEPUTY_DELETING_RULE",

    CREATE_DEPUTY: "CREATE_DEPUTY",
    EDIT_DEPUTY: "EDIT_DEPUTY",
    DELETE_DEPUTY: "DELETE_DEPUTY",

    RESET_DEPUTY_CREATION_RULE: "RESET_DEPUTY_CREATION_RULE",
};

export const DEPUTIES_GETTERS = {
    GET_DEPUTIES_CURSOR_CITY: "GET_DEPUTIES_CURSOR_CITY",
    GET_DEPUTIES_CURSOR_PARTY: "GET_DEPUTIES_CURSOR_PARTY",
    GET_DEPUTIES_CURSOR_CONNECTION: "GET_DEPUTIES_CURSOR_CONNECTION",
    GET_DEPUTIES_CREATION_RULE: "GET_DEPUTIES_CREATION_RULE",
    GET_DEPUTIES_EDITING_RULE: "GET_DEPUTIES_EDITING_RULE",
};

export function makeDeputiesModule(api: DeputiesApi) {
    return {
        state: new DeputiesState(),
        mutations: {
            [DEPUTIES_MUTATIONS.GET_DEPUTIES](
                state: DeputiesState,
                list: Deputy[]
            ): void {
                state.deputies = list;
            },
            [DEPUTIES_MUTATIONS.EXPAND_DEPUTIES_LIST](
                state: DeputiesState,
                list: Deputy[]
            ): void {
                list.forEach((deputy) => {
                    state.deputies.push(deputy);
                });
            },
            [DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_PAGE](
                state: DeputiesState,
                page: number
            ): void {
                state.cursor.cursor.page = page;
            },
            [DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_CITY_RULE](
                state: DeputiesState,
                city: DropdownItemModel
            ): void {
                state.cursor.city = city;
            },
            [DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_PARTY_RULE](
                state: DeputiesState,
                party: DropdownItemModel
            ): void {
                state.cursor.party = party;
            },
            [DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_CONNECTION_RULE](
                state: DeputiesState,
                connection: DropdownItemModel
            ): void {
                state.cursor.connection = connection;
            },
            [DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_NAME_RULE](
                state: DeputiesState,
                name: string
            ): void {
                state.cursor.searchName = name;
            },
            [DEPUTIES_MUTATIONS.SET_DEPUTIES_PAGINATION](
                state: DeputiesState,
                page: Page
            ): void {
                state.page = page;
            },

            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_AVATAR_RULE](
                state: DeputiesState,
                file: File
            ): void {
                state.creationRule.avatar = [file];
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_FIRST_NAME_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.firstName = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_MIDDLE_NAME_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.middleName = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_LAST_NAME_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.lastName = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_PARTY_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.creationRule.party = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_CITY_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.creationRule.city = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_DISTRICT_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.creationRule.district = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_CONSTITUENCY_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.creationRule.constituency = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_CONNECTED_RULE](
                state: DeputiesState,
                value: boolean
            ): void {
                state.creationRule.isConnected = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_RECEPTION_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.receptionAddress = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_TWITTER_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.socialNetworkTwitter = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_TELEGRAM_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.socialNetworkTelegram = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_INSTAGRAM_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.socialNetworkInstagram = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_FACEBOOK_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.socialNetworkFacebook = value;
            },
            [DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_WEB_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.creationRule.socialNetworkWeb = value;
            },

            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_ID_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.id = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_AVATAR_RULE](
                state: DeputiesState,
                file: File
            ): void {
                state.editingRule.avatar = [file];
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.avatarPreview = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_FIRST_NAME_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.firstName = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_MIDDLE_NAME_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.middleName = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_LAST_NAME_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.lastName = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_PARTY_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.editingRule.party = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_CITY_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.editingRule.city = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_DISTRICT_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.editingRule.district = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_CONSTITUENCY_RULE](
                state: DeputiesState,
                value: DropdownItemModel
            ): void {
                state.editingRule.constituency = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_CONNECTED_RULE](
                state: DeputiesState,
                value: boolean
            ): void {
                state.editingRule.isConnected = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_RECEPTION_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.receptionAddress = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_TWITTER_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.socialNetworkTwitter = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_TELEGRAM_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.socialNetworkTelegram = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_INSTAGRAM_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.socialNetworkInstagram = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_FACEBOOK_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.socialNetworkFacebook = value;
            },
            [DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_WEB_RULE](
                state: DeputiesState,
                value: string
            ): void {
                state.editingRule.socialNetworkWeb = value;
            },

            [DEPUTIES_MUTATIONS.SET_DEPUTY_DELETING_RULE](
                state: DeputiesState,
                rule: DeputyDeletingRule
            ): void {
                state.deletingRule = rule;
            },

            [DEPUTIES_MUTATIONS.RESET_DEPUTY_CREATION_RULE](
                state: DeputiesState
            ): void {
                state.creationRule = new DeputyCreationRule();
            },
        },
        actions: {
            [DEPUTIES_ACTIONS.GET_DEPUTIES]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const deputiesResponse = await api.list(state.cursor);
                commit(
                    DEPUTIES_MUTATIONS.GET_DEPUTIES,
                    deputiesResponse.deputies
                );
                commit(
                    DEPUTIES_MUTATIONS.SET_DEPUTIES_PAGINATION,
                    deputiesResponse.page
                );
            },
            [DEPUTIES_ACTIONS.EXPAND_DEPUTIES_LIST]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const deputiesResponse = await api.list(state.cursor);
                commit(
                    DEPUTIES_MUTATIONS.EXPAND_DEPUTIES_LIST,
                    deputiesResponse.deputies
                );
                commit(
                    DEPUTIES_MUTATIONS.SET_DEPUTIES_PAGINATION,
                    deputiesResponse.page
                );
            },

            [DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PAGE]: function (
                { commit, state }: any,
                page: number
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_PAGE, page);
            },

            [DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CITY_RULE]: function (
                { commit, state }: any,
                city: DropdownItemModel
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_CITY_RULE, city);
            },

            [DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PARTY_RULE]: function (
                { commit, state }: any,
                party: DropdownItemModel
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_PARTY_RULE,
                    party
                );
            },

            [DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CONNECTION_RULE]: function (
                { commit, state }: any,
                connection: DropdownItemModel
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_CONNECTION_RULE,
                    connection
                );
            },

            [DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_NAME_RULE]: function (
                { commit, state }: any,
                name: string
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_NAME_RULE, name);
            },

            [DEPUTIES_ACTIONS.RESET_DEPUTIES_CURSOR]: function ({
                commit,
                state,
            }: any): void {
                commit(DEPUTIES_MUTATIONS.SET_DEPUTIES_PAGINATION, new Page());
                commit(DEPUTIES_MUTATIONS.SET_DEPUTIES_CURSOR_PAGE, 1);
            },

            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_AVATAR_RULE]: function (
                { commit }: any,
                file: File
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_AVATAR_RULE,
                    file
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE]:
                function ({ commit }: any, value: string): void {
                    commit(
                        DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE,
                        value
                    );
                },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_FIRST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_FIRST_NAME_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_MIDDLE_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_MIDDLE_NAME_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_LAST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_LAST_NAME_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_PARTY_RULE]: function (
                { commit }: any,
                value: Party
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_PARTY_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CITY_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_CITY_RULE, value);
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_DISTRICT_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_DISTRICT_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CONSTITUENCY_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_CONSTITUENCY_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CONNECTED_RULE]: function (
                { commit }: any,
                value: boolean
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_CONNECTED_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_RECEPTION_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_RECEPTION_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_TWITTER_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_TWITTER_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_TELEGRAM_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_TELEGRAM_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_INSTAGRAM_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_INSTAGRAM_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_FACEBOOK_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_FACEBOOK_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_WEB_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_CREATION_DEPUTY_WEB_RULE, value);
            },

            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_ID_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_ID_RULE, value);
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_AVATAR_RULE]: function (
                { commit }: any,
                file: File
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_AVATAR_RULE, file);
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_FIRST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_FIRST_NAME_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_MIDDLE_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_MIDDLE_NAME_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_LAST_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_LAST_NAME_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_PARTY_RULE]: function (
                { commit }: any,
                value: Party
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_PARTY_RULE, value);
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_CITY_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_CITY_RULE, value);
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_DISTRICT_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_DISTRICT_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_CONSTITUENCY_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_CONSTITUENCY_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_CONNECTED_RULE]: function (
                { commit }: any,
                value: boolean
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_CONNECTED_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_RECEPTION_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_RECEPTION_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_TWITTER_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_TWITTER_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_TELEGRAM_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_TELEGRAM_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_INSTAGRAM_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_INSTAGRAM_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_FACEBOOK_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(
                    DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_FACEBOOK_RULE,
                    value
                );
            },
            [DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_WEB_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_EDITING_DEPUTY_WEB_RULE, value);
            },

            [DEPUTIES_ACTIONS.SET_DEPUTY_DELETING_RULE]: function (
                { commit, state }: any,
                rule: DeputyDeletingRule
            ): void {
                commit(DEPUTIES_MUTATIONS.SET_DEPUTY_DELETING_RULE, rule);
            },

            [DEPUTIES_ACTIONS.CREATE_DEPUTY]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const response = await api.create(state.creationRule);

                if (response.id && state.creationRule.avatar.length) {
                    await api.avatar(
                        new DeputyAvatarRule(
                            response.id,
                            state.creationRule.avatar
                        )
                    );
                }
            },
            [DEPUTIES_ACTIONS.EDIT_DEPUTY]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const response = await api.edit(state.editingRule);

                if (response.id && state.editingRule.avatar[0]) {
                    await api.avatar(
                        new DeputyAvatarRule(
                            response.id,
                            state.editingRule.avatar
                        )
                    );
                }
            },
            [DEPUTIES_ACTIONS.DELETE_DEPUTY]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                await api.delete(state.deletingRule);
            },

            [DEPUTIES_ACTIONS.RESET_DEPUTY_CREATION_RULE]: function ({
                commit,
                state,
            }: any): void {
                commit(DEPUTIES_MUTATIONS.RESET_DEPUTY_CREATION_RULE);
            },
        },

        getters: {
            [DEPUTIES_GETTERS.GET_DEPUTIES_CURSOR_CITY]: (state) => {
                return state.cursor.city;
            },
            [DEPUTIES_GETTERS.GET_DEPUTIES_CURSOR_PARTY]: (state) => {
                return state.cursor.party;
            },
            [DEPUTIES_GETTERS.GET_DEPUTIES_CURSOR_CONNECTION]: (state) => {
                return state.cursor.connection;
            },
            [DEPUTIES_GETTERS.GET_DEPUTIES_CREATION_RULE]: (state) => {
                return state.creationRule;
            },
            [DEPUTIES_GETTERS.GET_DEPUTIES_EDITING_RULE]: (state) => {
                return state.editingRule;
            },
        },
    };
}
