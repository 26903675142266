/**
 * HttpClient is a custom wrapper around fetch api.
 * Exposes get, post and delete methods for JSON strings.
 */
import { ParsedResponse } from "@/app/types/response";
import { CookieManager } from "@/app/utils/cookieManager";

export class HttpClient {
    // private static host: string = process.env.VUE_APP_API_HOST; // production
    // private static host = "https://admin.dev.deputy.com.ua";
    private static host = "";
    private static apiRoot = "/api/v0";

    /**
     *
     * @param method holds http method type
     * @param path
     * @param body serialized JSON
     */
    private async sendJSON(
        method: string,
        path: string,
        body: string | null
    ): Promise<ParsedResponse> {
        console.info(`[${method}] ${path}`);
        const request: RequestInit = {
            method: method,
            // mode: 'no-cors', // no-cors, *cors, same-origin
            body: body,
            referrerPolicy: "no-referrer",
        };

        request.headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${CookieManager.getToken()}`,
        };

        const fetchResponse = await fetch(
            HttpClient.host + HttpClient.apiRoot + path,
            request
        );
        if (!fetchResponse.ok) {
            console.error(fetchResponse.statusText);
            throw new Error(fetchResponse.statusText);
        }

        const response = await fetchResponse.json();
        console.info(response);

        if (response.error_code === 5002) {
            CookieManager.remove(CookieManager.COOKIE_KEY_TOKEN);
        }

        return new ParsedResponse(
            response.request_id,
            response.is_success,
            response.err_code,
            HttpClient.mapErr(response.error_code, response.error_message),
            response.data,
            response.page,
            response.total_count,
            response.unfiltered_count,
            response.next_page
        );
    }

    public async sendFile(
        path: string,
        file: File,
        headers: HeadersInit
    ): Promise<ParsedResponse> {
        console.info(`[UPLOAD FILE] ${path}`);
        const formData: FormData = new FormData();
        formData.append("media", file);
        const request: RequestInit = {
            method: "PUT",
            body: formData,
        };
        (headers as Headers).append(
            "Authorization",
            `Bearer ${CookieManager.getToken()}`
        );
        request.headers = headers;
        const fetchResponse = await fetch(
            HttpClient.host + HttpClient.apiRoot + path,
            request
        );
        if (!fetchResponse.ok) {
            console.error(fetchResponse.statusText);
            throw new Error(fetchResponse.statusText);
        }
        const response = await fetchResponse.json();

        return new ParsedResponse(
            response.request_id,
            response.is_success,
            response.err_code,
            HttpClient.mapErr(response.error_code, response.error_message),
            response.data,
            response.page,
            response.total_count,
            response.unfiltered_count,
            response.next_page
        );
    }

    private static mapErr(code: number, message: string): string {
        switch (code) {
            default:
                return message;
        }
    }

    /**
     * Performs POST http request with JSON body.
     * @param path
     * @param body serialized JSON
     */
    public async post(
        path: string,
        body: string | null
    ): Promise<ParsedResponse> {
        return this.sendJSON("POST", path, body);
    }

    /**
     * Performs PATCH http request with JSON body.
     * @param path
     * @param body serialized JSON
     */
    public async patch(
        path: string,
        body: string | null
    ): Promise<ParsedResponse> {
        return this.sendJSON("PATCH", path, body);
    }

    /**
     * Performs PUT http request with JSON body.
     * @param path
     * @param body serialized JSON
     * @param auth indicates if authentication is needed
     */
    public async put(
        path: string,
        body: string | null,
        auth: boolean | undefined = true
    ): Promise<ParsedResponse> {
        return this.sendJSON("PUT", path, body);
    }

    /**
     * Performs GET http request.
     * @param path
     * @param auth indicates if authentication is needed
     */
    public async get(
        path: string,
        auth: boolean | undefined = true
    ): Promise<ParsedResponse> {
        return this.sendJSON("GET", path, null);
    }

    public sendMultipart(path: string, file: ReadableStream) {
        // fileSystem.createReadStream()
    }

    /**
     * Performs DELETE http request.
     * @param path
     * @param auth indicates if authentication is needed
     */
    public async delete(
        path: string,
        auth: boolean | undefined = true
    ): Promise<ParsedResponse> {
        return this.sendJSON("DELETE", path, null);
    }
}
