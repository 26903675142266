import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import {
    CitiesCursor,
    CitiesPage,
    ConstituenciesListRule,
    ConstituencyDetailed,
    ConstituencyEditingRule,
    DistrictEditingRule,
    FiltersApi,
    PollingStationsListRule,
    StreetsCursor,
    StreetsPage,
} from "@/app/types/filters";
import { HttpClient } from "@/deputy/utils/httpClient";
import { StreetModel } from "@/app/types/streetModel";
import { Page } from "@/app/types/page";

export class FiltersHttpApi implements FiltersApi {
    private readonly client: HttpClient = new HttpClient();

    public async getCities(cursor: CitiesCursor): Promise<CitiesPage> {
        const response = await this.client.get(
            `/address/city?${cursor.toQuery()}`
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new CitiesPage();
        }

        return new CitiesPage(
            response.data.map(
                (item) => new DropdownItemModel(item.id, item.name)
            ),
            Page.fromJson(response)
        );
    }

    public async getDistricts(cityId: string): Promise<DropdownItemModel[]> {
        const response = await this.client.get(
            `/address/district?city_id=${cityId}`
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return [];
        }

        return response.data.map(
            (item) => new DropdownItemModel(item.id, item.name)
        );
    }

    public async getConstituencies(
        rule: ConstituenciesListRule
    ): Promise<ConstituencyDetailed[]> {
        const response = await this.client.get(
            `/address/constituency?${rule.toQuery()}`
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return [];
        }

        return response.data.map(
            (item) =>
                new ConstituencyDetailed(
                    new DropdownItemModel(item.id, item.local_number),
                    item.global_number,
                    item.districts.map((district) =>
                        DropdownItemModel.fromJson(district)
                    ),
                    "related_district_id" in item
                        ? new DropdownItemModel(
                              item.related_district_id,
                              item.related_district_name
                          )
                        : new DropdownItemModel()
                )
        );
    }

    public async getPollingStations(
        rule: PollingStationsListRule
    ): Promise<DropdownItemModel[]> {
        const response = await this.client.get(
            `/address/polling-station?${rule.toQuery()}`
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return [];
        }

        return response.data.map(
            (item) => new DropdownItemModel(item.id, item.number)
        );
    }

    public async getStreets(cursor: StreetsCursor): Promise<StreetsPage> {
        const response = await this.client.get(`/address?${cursor.toQuery()}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new StreetsPage();
        }

        return new StreetsPage(
            response.data.map(
                (item) => new StreetModel(item.id, item.street, item.number)
            ),
            Page.fromJson(response)
        );
    }

    public async editDistrict(rule: DistrictEditingRule): Promise<boolean> {
        const response = await this.client.put(
            `/address/district/${rule.id}`,
            JSON.stringify({ name: rule.districtNewName })
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }

    public async editConstituency(
        rule: ConstituencyEditingRule
    ): Promise<boolean> {
        const response = await this.client.put(
            `/address/constituency/${rule.id}`,
            JSON.stringify({
                local_number: +rule.constituencyNewNumber,
                related_district_id: rule.district.id,
            })
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }
}
