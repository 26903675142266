






























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class LinkDocument extends Vue {
    @Prop({ default: "" })
    private readonly href: string;

    @Prop({ default: "" })
    private readonly format: string;
}
