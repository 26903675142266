import Vue from "vue";
import Router from "vue-router";

import { NavigationLink } from "@/app/types/navigationLink";
import PartiesPage from "@/app/views/PartiesPage.vue";
import DeputiesPage from "@/app/views/DeputiesPage.vue";
import ManagersPage from "@/app/views/ManagersPage.vue";
import CitizensPage from "@/app/views/CitizensPage.vue";
import AddressPage from "@/app/views/AddressPage.vue";
import ReportsPage from "@/app/views/ReportsPage.vue";
import SignIn from "@/app/views/SignIn.vue";
import { CookieManager } from "@/app/utils/cookieManager";
import PhonesPage from "@/app/views/PhonesPage.vue";
import DeletingReasonsPage from "@/app/views/DeletingReasonsPage.vue";

Vue.use(Router);

/**
 * Router describes location mapping with components.
 */

export class RoutePaths {
    public static HOME: NavigationLink = new NavigationLink(
        "/",
        "home",
        { sidebar: true },
        SignIn
    );
    public static SIGN_IN: NavigationLink = new NavigationLink(
        "/sign-in",
        "sign-in",
        { sidebar: false },
        SignIn
    );
    public static PARTIES: NavigationLink = new NavigationLink(
        "/parties",
        "parties",
        { sidebar: true },
        PartiesPage
    );
    public static DEPUTIES: NavigationLink = new NavigationLink(
        "/deputies",
        "deputies",
        { sidebar: true },
        DeputiesPage
    );
    public static MANAGERS: NavigationLink = new NavigationLink(
        "/managers",
        "managers",
        { sidebar: true },
        ManagersPage
    );
    public static CITIZENS: NavigationLink = new NavigationLink(
        "/citizens",
        "citizens",
        { sidebar: true },
        CitizensPage
    );
    public static ADDRESSES: NavigationLink = new NavigationLink(
        "/addresses",
        "addresses",
        { sidebar: true },
        AddressPage
    );
    public static REPORTS: NavigationLink = new NavigationLink(
        "/reports",
        "reports",
        { sidebar: true },
        ReportsPage
    );
    public static PHONES: NavigationLink = new NavigationLink(
        "/phones",
        "phones",
        { sidebar: true },
        PhonesPage
    );
    public static DELETED_CITIZENS: NavigationLink = new NavigationLink(
        "/deleted-citizens",
        "deleted-citizens",
        { sidebar: true },
        DeletingReasonsPage
    );
}

export const router = new Router({
    mode: "history",
    routes: [
        {
            path: RoutePaths.HOME.path,
            name: RoutePaths.HOME.name,
            meta: RoutePaths.HOME.meta,
            component: RoutePaths.HOME.component,
        },
        {
            path: RoutePaths.SIGN_IN.path,
            name: RoutePaths.SIGN_IN.name,
            meta: RoutePaths.SIGN_IN.meta,
            component: RoutePaths.SIGN_IN.component,
        },
        {
            path: RoutePaths.PARTIES.path,
            name: RoutePaths.PARTIES.name,
            meta: RoutePaths.PARTIES.meta,
            component: RoutePaths.PARTIES.component,
        },
        {
            path: RoutePaths.DEPUTIES.path,
            name: RoutePaths.DEPUTIES.name,
            meta: RoutePaths.DEPUTIES.meta,
            component: RoutePaths.DEPUTIES.component,
        },
        {
            path: RoutePaths.MANAGERS.path,
            name: RoutePaths.MANAGERS.name,
            meta: RoutePaths.MANAGERS.meta,
            component: RoutePaths.MANAGERS.component,
        },
        {
            path: RoutePaths.CITIZENS.path,
            name: RoutePaths.CITIZENS.name,
            meta: RoutePaths.CITIZENS.meta,
            component: RoutePaths.CITIZENS.component,
        },
        {
            path: RoutePaths.ADDRESSES.path,
            name: RoutePaths.ADDRESSES.name,
            meta: RoutePaths.ADDRESSES.meta,
            component: RoutePaths.ADDRESSES.component,
        },
        {
            path: RoutePaths.REPORTS.path,
            name: RoutePaths.REPORTS.name,
            meta: RoutePaths.REPORTS.meta,
            component: RoutePaths.REPORTS.component,
        },
        {
            path: RoutePaths.PHONES.path,
            name: RoutePaths.PHONES.name,
            meta: RoutePaths.PHONES.meta,
            component: RoutePaths.PHONES.component,
        },
        {
            path: RoutePaths.DELETED_CITIZENS.path,
            name: RoutePaths.DELETED_CITIZENS.name,
            meta: RoutePaths.DELETED_CITIZENS.meta,
            component: RoutePaths.DELETED_CITIZENS.component,
        },
    ],
});

router.beforeEach(function (to, from, next) {
    if (to.name !== RoutePaths.SIGN_IN.name && !CookieManager.getToken())
        next({ name: RoutePaths.SIGN_IN.name });
    else next();
});
