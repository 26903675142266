export class AddressShort {
    public constructor(
        public city: string = "",
        public street: string = "",
        public number: string = "",
        public district: string = "",
        public constituency: string = "",
        public pollingStation: string = ""
    ) {}

    public static fromJson(obj: any): AddressShort {
        return new AddressShort(
            obj.city,
            obj.street,
            obj.number,
            obj.district,
            obj.constituency_number,
            obj.polling_station
        );
    }
}
