














































import SignInErrorMessage from "@/app/components/signIn/SignInErrorMessage.vue";
import { ErrorState } from "@/app/types/signInError";
import { Component, Vue, Watch } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";

import { RoutePaths } from "@/app/router";
import { AUTHORIZATION_ACTIONS } from "@/app/store/modules/authorization";
import { Credentials } from "@/app/types/authorization";

@Component({ components: { Button, InputField, SignInErrorMessage } })
export default class SignIn extends Vue {
    private login: string | undefined = "";
    private password: string | undefined = "";
    private errorState: ErrorState = new ErrorState();
    private hasError: boolean | undefined = false;

    private get formData(): Credentials {
        return new Credentials(this.login, this.password);
    }

    private async authorize(): Promise<void> {
        try {
            await this.$store.dispatch(
                AUTHORIZATION_ACTIONS.LOGIN,
                this.formData
            );
        } catch (e) {
            console.error(`error on signin.vue: ${e}`);
            this.errorState = new ErrorState(true, `error on signin.vue: ${e}`);
            this.hasError = true;

            return;
        }

        await this.$router.push(RoutePaths.PARTIES.path);
    }

    @Watch("formData")
    validate() {
        this.errorState = new ErrorState();
        this.hasError = false;
    }
}
