



















import { Component, Vue } from "vue-property-decorator";
import Table from "@/app/components/common/Table.vue";
import Button from "@/app/components/common/Button.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";
import Pagination from "@/app/components/common/Pagination.vue";
import Loader from "@/app/components/common/Loader.vue";
import PhoneRow from "@/app/components/phones/PhoneRow.vue";
import { PHONES_ACTIONS } from "@/app/store/modules/phones";
import { Phone } from "@/app/types/phone";
import { PaginationModel } from "@/app/types/paginationModel";

@Component({
    components: {
        PhoneRow,
        Table,
        Button,
        Pagination,
        Dropdown,
        Loader,
    },
})
export default class PhonesPage extends Vue {
    private phonesColumns: string[] = [
        "Номер",
        "Дата створення",
        "Підтверджений",
        "Опції",
    ];
    private loaderVisibility: boolean | undefined = false;

    private get phones(): Phone[] {
        return this.$store.state.phonesModule.phones;
    }

    private get pagination(): PaginationModel {
        return new PaginationModel(
            Math.ceil(
                this.$store.state.phonesModule.page.totalCount /
                    this.$store.state.phonesModule.cursor.cursor.limit
            ),
            this.$store.state.phonesModule.cursor.cursor.page,
            this.$store.state.phonesModule.page.nextPage
        );
    }

    private async getNewPhonesList(page) {
        try {
            await this.$store.dispatch(
                PHONES_ACTIONS.SET_PHONES_CURSOR_PAGE,
                page
            );
            await this.$store.dispatch(PHONES_ACTIONS.GET_PHONES_LIST);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    private async getInitialPhonesList(): Promise<void> {
        try {
            await this.$store.dispatch(PHONES_ACTIONS.GET_PHONES_LIST);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    async mounted() {
        await this.getInitialPhonesList();
    }
}
