






















import { Component, Vue } from "vue-property-decorator";
import Table from "@/app/components/common/Table.vue";
import Pagination from "@/app/components/common/Pagination.vue";
import Loader from "@/app/components/common/Loader.vue";
import { PaginationModel } from "@/app/types/paginationModel";
import DeletedCitizensRow from "@/app/components/deletedCitizens/DeletedCitizensRow.vue";
import { DeletedCitizen } from "@/app/types/deletedCitizens";
import { DELETED_CITIZENS_ACTIONS } from "@/app/store/modules/deletedCitizens";

@Component({
    components: {
        DeletedCitizensRow,
        Table,
        Pagination,
        Loader,
    },
})
export default class DeletingReasonsPage extends Vue {
    private citizensColumns: string[] = ["Дата видалення", "Причина видалення"];
    private loaderVisibility: boolean | undefined = false;

    private get citizens(): DeletedCitizen[] {
        return this.$store.state.deletedCitizensModule.deletedCitizens;
    }

    private get pagination(): PaginationModel {
        return new PaginationModel(
            Math.ceil(
                this.$store.state.deletedCitizensModule.page.totalCount /
                    this.$store.state.deletedCitizensModule.cursor.cursor.limit
            ),
            this.$store.state.deletedCitizensModule.cursor.cursor.page,
            this.$store.state.deletedCitizensModule.page.nextPage
        );
    }

    private async getNewDeletedCitizensList(page) {
        try {
            await this.$store.dispatch(
                DELETED_CITIZENS_ACTIONS.SET_DELETED_CITIZENS_CURSOR_PAGE,
                page
            );
            await this.$store.dispatch(
                DELETED_CITIZENS_ACTIONS.GET_DELETED_CITIZENS_LIST
            );
        } catch (e) {
            console.error(e);

            return;
        }
    }

    private async getInitialDeletedCitizensList(): Promise<void> {
        try {
            await this.$store.dispatch(
                DELETED_CITIZENS_ACTIONS.GET_DELETED_CITIZENS_LIST
            );
        } catch (e) {
            console.error(e);

            return;
        }
    }

    async mounted() {
        await this.getInitialDeletedCitizensList();
    }
}
