







































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Loader from "@/app/components/common/Loader.vue";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { StreetsListRule, StreetsListRuleIds } from "@/app/types/filters";
import { LoaderState } from "@/app/types/loaderState";
import { StreetModel } from "@/app/types/streetModel";

@Component({ components: { Loader } })
export default class StreetFilterInputField extends Vue {
    @Prop({ default: new StreetsListRuleIds() })
    private readonly rule: StreetsListRuleIds;

    @Prop({ default: "" })
    private readonly initial: string;

    @Prop({ default: "" })
    private readonly label: string;

    @Prop({ default: false })
    private readonly disabled: boolean;

    @Prop({ default: "" })
    private readonly placeholder: string;

    private value: string | undefined = "";
    private listVisibility: boolean | undefined = false;
    private allowSearching: boolean | undefined = true;
    private loaderVisibility: boolean | undefined = false;

    private get streetsList(): StreetModel[] {
        return this.$store.state.filtersModule.streetsState.list;
    }

    private get currentPage(): number {
        return this.$store.state.filtersModule.streetsState.cursor.cursor.page;
    }

    private get nextPage(): number {
        return this.$store.state.filtersModule.streetsState.page.nextPage;
    }

    private async openStreetsList(): Promise<void> {
        if (this.listVisibility) {
            return;
        }

        this.listVisibility = true;
        await this.searchStreet();
    }

    private closeStreetsList(): void {
        this.listVisibility = false;
    }

    private selectAddress(address: StreetModel): void {
        this.value = address.street;
        this.listVisibility = false;
        this.$emit("input", this.value);
    }

    private async searchStreet(): Promise<void> {
        let currentSearchValue: string | undefined = "";
        this.listVisibility = true;
        this.$emit("input", this.value);
        await this.$store.dispatch(FILTERS_ACTIONS.SET_STREETS_CURSOR_PAGE, 1);

        if (!this.allowSearching) {
            return;
        }

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_STREETS_LIST,
            new StreetsListRule(this.rule, this.value)
        );

        currentSearchValue = this.value;
        this.allowSearching = false;

        setTimeout(async () => {
            this.allowSearching = true;

            if (currentSearchValue === this.value) {
                return;
            }

            await this.$store.dispatch(
                FILTERS_ACTIONS.GET_STREETS_LIST,
                new StreetsListRule(this.rule, this.value)
            );

            currentSearchValue = this.value;
        }, 1000);
    }

    private async paginateStreetList(e): Promise<void> {
        const column = e.target;
        const scrollPosition = column.scrollTop + column.offsetHeight;

        if (this.nextPage === -1 || this.currentPage === this.nextPage) {
            return;
        }

        if (scrollPosition < column.scrollHeight - 38) {
            return;
        }

        try {
            await this.$store.dispatch(
                FILTERS_ACTIONS.SET_STREETS_CURSOR_PAGE,
                this.nextPage
            );
            await this.$store.dispatch(
                FILTERS_ACTIONS.EXPAND_STREETS_LIST,
                new StreetsListRule(this.rule, this.value)
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            this.loaderVisibility = true;
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.loaderVisibility = false;
            console.error(e);

            return;
        }

        setTimeout(async () => {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.loaderVisibility = false;
        }, 700);
    }

    @Watch("initial")
    resetDefault() {
        if (!this.initial) {
            this.value = "";
        }
    }

    mounted() {
        this.value = this.initial;
    }
}
