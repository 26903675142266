
























import { Component, Vue } from "vue-property-decorator";
import Table from "@/app/components/common/Table.vue";
import Button from "@/app/components/common/Button.vue";
import { PaginationModel } from "@/app/types/paginationModel";
import Pagination from "@/app/components/common/Pagination.vue";
import CitizenRow from "@/app/components/citizens/CitizenRow.vue";
import {
    CitiesListRule,
    ConstituenciesListRule,
    PollingStationsListRule
} from "@/app/types/filters";
import { Citizen } from "@/app/types/citizens";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { CITIZENS_ACTIONS } from "@/app/store/modules/citizens";
import { DefaultFilters } from "@/app/types/defaultFilters";
import CitizensFilterPanel from "@/app/components/citizens/CitizensFilterPanel.vue";
import Loader from "@/app/components/common/Loader.vue";

@Component({
    components: {
        CitizenRow,
        Table,
        Button,
        Pagination,
        CitizensFilterPanel,
        Loader,
    },
})
export default class CitizensPage extends Vue {
    private columns: string[] = [
        "Iм’я та прізвище",
        "Статус",
        "Телефон",
        "Округ / Дiльниця",
        "Адреса",
        "Опції",
    ];
    private loaderVisibility: boolean | undefined = false;

    private get citizens(): Citizen[] {
        return this.$store.state.citizensModule.citizens;
    }

    private get pagination(): PaginationModel {
        return new PaginationModel(
            Math.ceil(
                this.$store.state.citizensModule.page.totalCount /
                    this.$store.state.citizensModule.cursor.cursor.limit
            ),
            this.$store.state.citizensModule.cursor.cursor.page,
            this.$store.state.citizensModule.page.nextPage
        );
    }

    private get constituenciesListRule(): ConstituenciesListRule {
        return new ConstituenciesListRule(
            this.$store.state.citizensModule.cursor.filter.city.id,
            this.$store.state.citizensModule.cursor.filter.district.id
        );
    }

    private get pollingStationsListRule(): PollingStationsListRule {
        return new PollingStationsListRule(
            this.$store.state.citizensModule.cursor.filter.city.id,
            this.$store.state.citizensModule.cursor.filter.district.id,
            this.$store.state.citizensModule.cursor.filter.constituency.id
        );
    }

    private toggleLoader(value): void {
        this.loaderVisibility = value;
    }

    private async getCitizens(page) {
        try {
            await this.$store.dispatch(
                CITIZENS_ACTIONS.SET_CITIZENS_CURSOR_PAGE,
                page
            );
            await this.$store.dispatch(CITIZENS_ACTIONS.GET_CITIZENS_LIST);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    async mounted() {
        await this.$store.dispatch(CITIZENS_ACTIONS.RESET_CITIZENS_FILTERS);
        await this.$store.dispatch(CITIZENS_ACTIONS.RESET_CITIZENS_CURSOR);

        await this.$store.dispatch(FILTERS_ACTIONS.GET_CITIES_LIST, new CitiesListRule(DefaultFilters.DEFAULT_CITY));

        const cities = this.$store.state.filtersModule.citiesState.list;
        const defaultCity =
            cities.find((city) => city.item === DefaultFilters.DEFAULT_CITY) ||
            cities[0];

        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_CITY_RULE,
            defaultCity
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_DISTRICTS_LIST,
            defaultCity.id
        );
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            this.constituenciesListRule
        );
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_POLLING_STATIONS_LIST,
            this.pollingStationsListRule
        );

        await this.$store.dispatch(CITIZENS_ACTIONS.GET_CITIZENS_LIST);
    }
}
