



























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/app/components/common/Button.vue";
import { Deputy, DeputyDeletingRule } from "@/app/types/deputy";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";
import DeputyDeletingWindow from "@/app/components/popups/DeputyDeletingWindow.vue";
import DeputyEditingWindow from "@/app/components/popups/DeputyEditingWindow.vue";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { CitiesListRule, ConstituenciesListRule } from "@/app/types/filters";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { RoutePaths } from "@/app/router";
import { MANAGERS_ACTIONS } from "@/app/store/modules/managers";
import { DefaultFilters } from "@/app/types/defaultFilters";

@Component({ components: { Button } })
export default class DeputyRow extends Vue {
    @Prop({ default: new Deputy() })
    private readonly row: Deputy;

    private get isConnected(): string {
        return this.row.isConnected ? "Так" : "Ні";
    }

    private async openEditingPopup(): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_ID_RULE,
            this.row.id
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_FIRST_NAME_RULE,
            this.row.firstName
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_MIDDLE_NAME_RULE,
            this.row.middleName
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_LAST_NAME_RULE,
            this.row.lastName
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_AVATAR_PREVIEW_RULE,
            this.row.avatar
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_CONNECTED_RULE,
            this.row.isConnected
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_RECEPTION_RULE,
            this.row.receptionAddress
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_TWITTER_RULE,
            this.row.socialNetworkTwitter
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_TELEGRAM_RULE,
            this.row.socialNetworkTelegram
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_INSTAGRAM_RULE,
            this.row.socialNetworkInstagram
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_FACEBOOK_RULE,
            this.row.socialNetworkFacebook
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_WEB_RULE,
            this.row.socialNetworkWeb
        );

        await this.$store.dispatch(FILTERS_ACTIONS.GET_CITIES_LIST, new CitiesListRule(DefaultFilters.DEFAULT_CITY));
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_CITY_RULE,
            this.row.city
        );

        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            this.row.city
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);

        if (this.row.party) {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_PARTY_RULE,
                this.row.party
            );
        } else {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_PARTY_RULE,
                new DropdownItemModel()
            );
        }

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_DISTRICTS_LIST,
            this.row.city.id
        );
        if (this.row.district) {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_DISTRICT_RULE,
                this.row.district
            );
        } else {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_DISTRICT_RULE,
                new DropdownItemModel()
            );
        }

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            new ConstituenciesListRule(
                String(this.row.city.id),
                String(this.row.district.id)
            )
        );
        if (this.row.constituency) {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_CONSTITUENCY_RULE,
                this.row.constituency
            );
        } else {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_EDITING_DEPUTY_CONSTITUENCY_RULE,
                new DropdownItemModel()
            );
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(DeputyEditingWindow.componentName, true, false)
        );
    }

    private openDeletingPopup(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(DeputyDeletingWindow.componentName, true, false)
        );
        this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTY_DELETING_RULE,
            new DeputyDeletingRule(this.row.id)
        );
    }

    private openManagersPage(): void {
        this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_CITY_RULE,
            this.row.city
        );
        this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_DEPUTY_RULE,
            new DropdownItemModel(this.row.id, `${this.row.firstName} ${this.row.middleName} ${this.row.lastName}`)
        );
        this.$router.push({name: RoutePaths.MANAGERS.name});
    }
}
