import { Page } from "@/app/types/page";
import { HttpClient } from "@/deputy/utils/httpClient";
import {
    Phone,
    PhonesApi,
    PhonesCursor,
    PhonesDeletingRule,
    PhonesPage,
} from "@/app/types/phone";

export class PhonesHttpApi implements PhonesApi {
    private readonly client: HttpClient = new HttpClient();

    public async list(cursor: PhonesCursor): Promise<PhonesPage> {
        const response = await this.client.get(`/phone?${cursor.toQuery()}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new PhonesPage([], Page.fromJson(response));
        }

        return new PhonesPage(
            response.data.map((phone) => {
                return Phone.fromJson(phone);
            }),
            Page.fromJson(response)
        );
    }

    public async delete(rule: PhonesDeletingRule): Promise<boolean> {
        const response = await this.client.delete(`/phone/${rule.id}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }
}
