import { Page } from "@/app/types/page";
import { Cursor } from "@/app/types/cursor";
import { Document } from "@/app/types/document";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { AddressShort } from "@/app/types/addressShort";
import { Citizen } from "@/app/types/citizens";

export class Report {
    public constructor(
        public id: string = "",
        public applicationPublicId: string = "",
        public applicationId: string = "",
        public applicationTitle: string = "",
        public type: ReportType = ReportType.Undefined,
        public status: DropdownItemModel = new DropdownItemModel(),
        public date: Date = new Date(),
        public text: string = "",
        public author: Citizen = new Citizen(),
        public reportedUser: Citizen = new Citizen()
    ) {}

    public static fromJson(obj: any): Report {
        return new Report(
            obj.id,
            obj.application_public_id,
            obj.instance_id,
            obj.application_title,
            obj.report_type,
            getStatusObj(obj.status),
            obj.created_at,
            obj.text,
            Citizen.fromJson(obj.author),
            Citizen.fromJson(obj.instance_user)
        );
    }
}

export class ReportApplication {
    public constructor(
        public id: string = "",
        public publicID: number = 0,
        public categoryID: string = "",
        public isDeactivated: boolean = false,
        public categoryTitle: string = "",
        public creationDate: string = "",
        public title: string = "",
        public description: string = "",
        public status: ApplicationStatus = ApplicationStatus.Undefined,
        public address: AddressShort = new AddressShort(),
        public author: Citizen = new Citizen(),
        public documents: Document[] = []
    ) {}

    public static fromJson(obj: any): ReportApplication {
        return new ReportApplication(
            obj.id,
            obj.public_id,
            obj.category_id,
            obj.is_deactivated,
            obj.category_title,
            obj.created_at,
            obj.title,
            obj.description,
            obj.status,
            AddressShort.fromJson(obj.address),
            Citizen.fromJson(obj.author),
            obj.documents
                ? obj.documents.map((doc) => Document.fromJson(doc))
                : []
        );
    }
}

function getStatusObj(status: ReportStatus): DropdownItemModel {
    switch (status) {
        case ReportStatus.New:
            return new DropdownItemModel(status, "Нова");
        case ReportStatus.Implemented:
            return new DropdownItemModel(status, "Опрацьована");
        case ReportStatus.Irrelevant:
            return new DropdownItemModel(status, "Не актуальна");
        case ReportStatus.Hidden:
            return new DropdownItemModel(status, "Прихована");
        case ReportStatus.Undefined:
        default:
            return new DropdownItemModel();
    }
}

export enum ApplicationStatus {
    Undefined = 0,
    New = 1,
    Issued = 2,
    InProgress = 3,
    ResponseReceived = 4,
    Implemented = 5,
}

export class ReportsState {
    public constructor(
        public reports: Report[] = [],
        public currentReport: Report = new Report(),
        public page: Page = new Page(),
        public cursor: ReportsCursor = new ReportsCursor(),
        public application: ReportApplication = new ReportApplication(),
        public currentReportMaterialId: string = ""
    ) {}
}

export class ReportsPage {
    public constructor(
        public reports: Report[] = [],
        public page: Page = new Page()
    ) {}
}

export class ReportStatusItem {
    public constructor(
        public id: ReportStatus = ReportStatus.Undefined,
        public title: string = ""
    ) {}

    public static fromJson(obj: any): ReportStatusItem {
        return new ReportStatusItem(obj.id, obj.title);
    }
}

export class statusChangingRule {
    public constructor(
        public id: string = "",
        public newStatus: DropdownItemModel = new DropdownItemModel()
    ) {}
}

export enum ReportType {
    Undefined = 0,
    Else = 1,
    Spam = 2,
    Insults = 3,
    Error = 4,
}

export enum ReportStatus {
    Undefined = 0,
    New = 1,
    Implemented = 2,
    Irrelevant = 3,
    Hidden = 4,
}

export class ReportsCursor {
    public constructor(
        public cursor: Cursor = new Cursor(),
        public status: DropdownItemModel = new DropdownItemModel(),
        public type: ReportType = ReportType.Else
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        a.append("status", String(this.status.id));

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

export interface ReportsApi {
    list(cursor: ReportsCursor): Promise<ReportsPage>;

    getReport(id: string): Promise<Report>;

    changeStatus(rule: statusChangingRule): Promise<void>;

    getApplication(id: string): Promise<ReportApplication>;

    activateApplication(id: string): Promise<void>;

    deactivateApplication(id: string): Promise<void>;

    removeApplicationMaterial(id: string): Promise<void>;
}
