
















































import { Component, Vue } from "vue-property-decorator";
import DistrictRow from "@/app/components/addresses/DistrictRow.vue";
import ConstituencyRow from "@/app/components/addresses/ConstituencyRow.vue";
import Table from "@/app/components/common/Table.vue";
import Button from "@/app/components/common/Button.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";
import Pagination from "@/app/components/common/Pagination.vue";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import {
    CitiesListRule,
    ConstituenciesListRule,
    ConstituencyDetailed,
} from "@/app/types/filters";
import { DefaultFilters } from "@/app/types/defaultFilters";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";
import AddressesFilterPanel from "@/app/components/addresses/AddressesFilterPanel.vue";
import Loader from "@/app/components/common/Loader.vue";

@Component({
    components: {
        DistrictRow,
        ConstituencyRow,
        Table,
        Button,
        Pagination,
        Dropdown,
        CityFilterInputField,
        AddressesFilterPanel,
        Loader,
    },
})
export default class AddressPage extends Vue {
    private districtColumns: string[] = ["Район", "Опції"];
    private constituencyColumns: string[] = [
        "Округ",
        "Оригінальний <br>номер",
        "Район <br>(належність)",
        "Опції",
    ];
    private loaderVisibility: boolean | undefined = false;

    private get districtsRows(): DropdownItemModel[] {
        return this.$store.state.filtersModule.districtsList;
    }

    private get constituenciesRows(): ConstituencyDetailed[] {
        return this.$store.state.filtersModule.constituenciesList;
    }

    private toggleLoader(value): void {
        this.loaderVisibility = value;
    }

    async mounted() {
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CITIES_LIST,
            new CitiesListRule(DefaultFilters.DEFAULT_CITY)
        );

        let cities = this.$store.state.filtersModule.citiesState.list;
        let defaultCity =
            cities.find((city) => city.name === DefaultFilters.DEFAULT_CITY) ||
            cities[0];

        await this.$store.dispatch(
            FILTERS_ACTIONS.SET_ADDRESS_PAGE_CURSOR_CITY_RULE,
            defaultCity
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_DISTRICTS_LIST,
            defaultCity.id
        );
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            new ConstituenciesListRule(defaultCity.id, "")
        );
    }
}
