










































import { Component, Prop, Vue } from "vue-property-decorator";
import { fileFormats } from "@/app/types/fileFormats";

@Component({})
export default class AvatarUpload extends Vue {
    @Prop({ default: true })
    private readonly isEmpty: boolean;

    @Prop({ default: "" })
    private readonly initialImg: string;

    private file: File[] = [];

    private get imageUrl(): string {
        return this.file[0] ? URL.createObjectURL(this.file[0]) : "";
    }

    private get typesList(): string[] {
        let array: string[] = [];

        for (const [key, value] of Object.entries(fileFormats)) {
            array.push(value.type);
        }

        return array;
    }

    private get hasError(): boolean {
        if (!this.file.length) {
            return false;
        }

        const uploadedFileFormat = this.file[0].type;
        if (this.file[0].size > 20971520) {
            return true;
        }

        let array: string[] = [];

        for (const [key, value] of Object.entries(fileFormats)) {
            array.push(value.check);
        }

        return !array.includes(uploadedFileFormat);
    }

    private onFileChanged(event: any): void {
        this.file = [];
        this.file.push(event.target.files[0]);

        if (this.hasError) {
            this.$emit("file_selected", null);
        }

        if (!this.hasError) {
            this.$emit("file_selected", this.file[0]);
        }

        const input = this.$refs.uploadAvatar as HTMLInputElement;
        input.value = "";
    }
}
