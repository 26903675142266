











import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";

@Component({ components: { Button } })
export default class PartyDeletingWindow extends Vue {
    public static componentName: string | undefined = "PartyDeletingWindow";

    private async deleteParty(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(PARTIES_ACTIONS.DELETE_PARTY);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Партію не вдалося видалити")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Партію успішно видалено")
        );
        await this.$store.dispatch(PARTIES_ACTIONS.SET_PARTIES_CURSOR_PAGE, 1);
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
    }

    private cancel(): void {
        console.log("cancel deleting");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
