import { AuthorizationApi, Credentials } from "@/app/types/authorization";
import { HttpClient } from "@/deputy/utils/httpClient";

export class AuthorizationHttpApi implements AuthorizationApi {
    private readonly client: HttpClient = new HttpClient();

    public async login(cred: Credentials): Promise<string> {
        const response = await this.client.post(
            "/auth/login",
            JSON.stringify(cred)
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        return response.data[0];
    }

    public async resendInvitation(id: string): Promise<boolean> {
        const response = await this.client.post(
            `/manager/${id}/resend-invitation`,
            null
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        return response.is_success;
    }
}
