export class DropdownItemModel {
    public constructor(
        public id: string | number = "",
        public name: string = ""
    ) {}

    public static fromJson(obj: any): DropdownItemModel {
        return new DropdownItemModel(obj.id, obj.name);
    }
}
