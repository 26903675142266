import { Page } from "@/app/types/page";
import { Cursor } from "@/app/types/cursor";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

export class Deputy {
    public constructor(
        public id: string = "",
        public firstName: string = "",
        public middleName: string = "",
        public lastName: string = "",
        public birthday: Date = new Date(),
        public party: DropdownItemModel = new DropdownItemModel(),
        public city: DropdownItemModel = new DropdownItemModel(),
        public district: DropdownItemModel = new DropdownItemModel(),
        public constituency: DropdownItemModel = new DropdownItemModel(),
        public isConnected: boolean = false,
        public receptionAddress: string = "",
        public socialNetworkTwitter: string = "",
        public socialNetworkTelegram: string = "",
        public socialNetworkInstagram: string = "",
        public socialNetworkFacebook: string = "",
        public socialNetworkWeb: string = "",
        public avatar: string = ""
    ) {}

    public static fromJson(obj: any): Deputy {
        return new Deputy(
            obj.id,
            obj.first_name,
            obj.middle_name,
            obj.last_name,
            obj.birthday,
            new DropdownItemModel(obj.party_id, obj.party),
            new DropdownItemModel(obj.city_id, obj.city),
            new DropdownItemModel(obj.district_id, obj.district),
            new DropdownItemModel(obj.constituency_id, obj.constituency),
            obj.is_connected,
            obj.reception_address,
            obj.social_network_twitter,
            obj.social_network_telegram,
            obj.social_network_instagram,
            obj.social_network_facebook,
            obj.social_network_web,
            obj.image && obj.image.preview_image ? obj.image.preview_image : "",
        );
    }
}

export class DeputiesState {
    public constructor(
        public deputies: Deputy[] = [],
        public creationRule: DeputyCreationRule = new DeputyCreationRule(),
        public editingRule: DeputyEditingRule = new DeputyEditingRule(),
        public deletingRule: DeputyDeletingRule = new DeputyDeletingRule(),
        public page: Page = new Page(),
        public cursor: DeputiesCursor = new DeputiesCursor()
    ) {}
}

export class DeputyCreationRule {
    public constructor(
        public firstName: string = "",
        public middleName: string = "",
        public lastName: string = "",
        public party: DropdownItemModel = new DropdownItemModel(),
        public city: DropdownItemModel = new DropdownItemModel(),
        public district: DropdownItemModel = new DropdownItemModel(),
        public constituency: DropdownItemModel = new DropdownItemModel(),
        public isConnected: boolean = false,
        public receptionAddress: string = "",
        public socialNetworkTwitter: string = "",
        public socialNetworkTelegram: string = "",
        public socialNetworkInstagram: string = "",
        public socialNetworkFacebook: string = "",
        public socialNetworkWeb: string = "",
        public avatar: File[] = []
    ) {}

    public toJson(): string {
        const obj = {
            first_name: this.firstName,
            middle_name: this.middleName,
            last_name: this.lastName,
            party_id: this.party.id,
            city_id: this.city.id,
            district_id: this.district.id,
            constituency_id: this.constituency.id,
            is_connected: this.isConnected,
            reception_address: this.receptionAddress,
            social_network_twitter: this.socialNetworkTwitter,
            social_network_telegram: this.socialNetworkTelegram,
            social_network_instagram: this.socialNetworkInstagram,
            social_network_facebook: this.socialNetworkFacebook,
            social_network_web: this.socialNetworkWeb,
        };

        return JSON.stringify(obj);
    }
}

export class DeputyEditingRule {
    public constructor(
        public id: string = "",
        public firstName: string = "",
        public middleName: string = "",
        public lastName: string = "",
        public party: DropdownItemModel = new DropdownItemModel(),
        public city: DropdownItemModel = new DropdownItemModel(),
        public district: DropdownItemModel = new DropdownItemModel(),
        public constituency: DropdownItemModel = new DropdownItemModel(),
        public isConnected: boolean = false,
        public receptionAddress: string = "",
        public socialNetworkTwitter: string = "",
        public socialNetworkTelegram: string = "",
        public socialNetworkInstagram: string = "",
        public socialNetworkFacebook: string = "",
        public socialNetworkWeb: string = "",
        public avatar: File[] = [],
        public avatarPreview: string = ""
    ) {}

    public toJson(): string {
        const obj = {
            first_name: this.firstName,
            middle_name: this.middleName,
            last_name: this.lastName,
            party_id: this.party.id,
            city_id: this.city.id,
            district_id: this.district.id,
            constituency_id: this.constituency.id,
            is_connected: this.isConnected,
            reception_address: this.receptionAddress,
            social_network_twitter: this.socialNetworkTwitter,
            social_network_telegram: this.socialNetworkTelegram,
            social_network_instagram: this.socialNetworkInstagram,
            social_network_facebook: this.socialNetworkFacebook,
            social_network_web: this.socialNetworkWeb,
        };

        return JSON.stringify(obj);
    }
}

export class DeputyDeletingRule {
    public constructor(public id: string = "") {}
}

export class DeputyAvatarRule {
    public constructor(
        public deputyId: string = '',
        public avatar: File[] = []
    ) {}
}

export class DeputiesPage {
    public constructor(
        public deputies: Deputy[] = [],
        public page: Page = new Page()
    ) {}
}

export class DeputiesCursor {
    public constructor(
        public cursor: Cursor = new Cursor(),
        public city: DropdownItemModel = new DropdownItemModel(),
        public party: DropdownItemModel = new DropdownItemModel(),
        public connection: DropdownItemModel = new DropdownItemModel(),
        public searchName: string = ""
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        if (!isEmpty(this.city.id)) {
            a.append("city_id", String(this.city.id));
        }

        if (!isEmpty(this.party.id)) {
            a.append("party_id", String(this.party.id));
        }

        if (!isEmpty(this.connection.id)) {
            a.append("connected_filter", String(this.connection.id));
        }

        if (!isEmpty(this.searchName)) {
            a.append("search_name", this.searchName);
        }

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

function isEmpty(s: string | number): boolean {
    return !s || String(s).length === 0;
}

export enum ConnectionStatus {
    Undefined = 0,
    All = 1,
    Connected = 2,
    NotConnected = 3,
}

export interface DeputiesApi {
    list(cursor: DeputiesCursor): Promise<DeputiesPage>;

    create(rule: DeputyCreationRule): Promise<Deputy>;

    edit(rule: DeputyEditingRule): Promise<Deputy>;

    delete(rule: DeputyDeletingRule): Promise<boolean>;

    avatar(rule: DeputyAvatarRule): Promise<boolean>;
}
