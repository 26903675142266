


































import { Component, Vue } from "vue-property-decorator";
import Table from "@/app/components/common/Table.vue";
import Button from "@/app/components/common/Button.vue";
import { PaginationModel } from "@/app/types/paginationModel";
import Pagination from "@/app/components/common/Pagination.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";
import ReportRow from "@/app/components/reports/ReportRow.vue";
import { Report, ReportStatus } from "@/app/types/report";
import { REPORTS_ACTIONS } from "@/app/store/modules/reports";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

@Component({ components: { ReportRow, Table, Button, Pagination, Dropdown } })
export default class ReportsPage extends Vue {
    private columns: string[] = [
        "ID Заявки",
        "Тип",
        "Статус",
        "Автор скарги",
        "Оскаржений мешканець",
        "Текст",
        "Дата",
    ];

    private get currentActiveTab(): ReportStatus {
        return this.$store.state.reportsModule.cursor.status.id;
    }

    private get pagination(): PaginationModel {
        return new PaginationModel(
            Math.ceil(
                this.$store.state.reportsModule.page.totalCount /
                    this.$store.state.reportsModule.cursor.cursor.limit
            ),
            this.$store.state.reportsModule.cursor.cursor.page,
            this.$store.state.reportsModule.page.nextPage
        );
    }

    private get totalCount(): number {
        return this.$store.state.reportsModule.page.totalCount;
    }

    private get statusTabsList(): DropdownItemModel[] {
        return [
            new DropdownItemModel(ReportStatus.New, "Нові"),
            new DropdownItemModel(ReportStatus.Implemented, "Опрацьовані"),
            new DropdownItemModel(ReportStatus.Irrelevant, "Не актуальні"),
            new DropdownItemModel(ReportStatus.Hidden, "Приховані"),
        ];
    }

    private get rows(): Report[] {
        return this.$store.state.reportsModule.reports;
    }

    private async selectTab(status): Promise<void> {
        await this.$store.dispatch(REPORTS_ACTIONS.SET_REPORTS_CURSOR_PAGE, 1);
        await this.$store.dispatch(
            REPORTS_ACTIONS.SET_REPORTS_STATUS_RULE,
            status
        );

        try {
            await this.$store.dispatch(REPORTS_ACTIONS.GET_REPORTS);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    private async getNewReports(page) {
        try {
            await this.$store.dispatch(
                REPORTS_ACTIONS.SET_REPORTS_CURSOR_PAGE,
                page
            );
            await this.$store.dispatch(REPORTS_ACTIONS.GET_REPORTS);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    async mounted() {
        await this.$store.dispatch(
            REPORTS_ACTIONS.SET_REPORTS_STATUS_RULE,
            new DropdownItemModel(ReportStatus.New, "Нова")
        );
        await this.$store.dispatch(REPORTS_ACTIONS.GET_REPORTS);
    }
}
