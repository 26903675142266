
























import { Component, Vue } from "vue-property-decorator";
import DeputyRow from "@/app/components/deputies/DeputyRow.vue";
import Table from "@/app/components/common/Table.vue";
import Button from "@/app/components/common/Button.vue";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";
import { ConnectionStatus, Deputy } from "@/app/types/deputy";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { PaginationModel } from "@/app/types/paginationModel";
import Pagination from "@/app/components/common/Pagination.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { DefaultFilters } from "@/app/types/defaultFilters";
import { CitiesListRule } from "@/app/types/filters";
import DeputiesFilterPanel from "@/app/components/deputies/DeputiesFilterPanel.vue";
import Loader from "@/app/components/common/Loader.vue";

@Component({
    components: {
        DeputiesFilterPanel,
        DeputyRow,
        Table,
        Button,
        Pagination,
        Dropdown,
        Loader,
    },
})
export default class DeputiesPage extends Vue {
    private columns: string[] = [
        "Фото",
        "ПІБ",
        "Партія",
        "Місто",
        "Район",
        "Округ",
    ];

    private loaderVisibility: boolean | undefined = false;

    private get connectionStatusesList(): DropdownItemModel[] {
        return [
            new DropdownItemModel(ConnectionStatus.All, "Всі"),
            new DropdownItemModel(ConnectionStatus.Connected, "Приєднані"),
            new DropdownItemModel(
                ConnectionStatus.NotConnected,
                "Не приєднані"
            ),
        ];
    }

    private get pagination(): PaginationModel {
        return new PaginationModel(
            Math.ceil(
                this.$store.state.deputiesModule.page.totalCount /
                    this.$store.state.deputiesModule.cursor.cursor.limit
            ),
            this.$store.state.deputiesModule.cursor.cursor.page,
            this.$store.state.deputiesModule.page.nextPage
        );
    }

    private get rows(): Deputy[] {
        return this.$store.state.deputiesModule.deputies;
    }

    private toggleLoader(value): void {
        this.loaderVisibility = value;
    }

    private async getNewDeputies(page) {
        try {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PAGE,
                page
            );
            await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    async mounted() {
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CITIES_LIST,
            new CitiesListRule(DefaultFilters.DEFAULT_CITY)
        );
        let cities = this.$store.state.filtersModule.citiesState.list;
        let defaultCity =
            cities.find((city) => city.name === DefaultFilters.DEFAULT_CITY) ||
            cities[0];

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CITY_RULE,
            defaultCity
        );

        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            defaultCity
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PAGE,
            1
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_NAME_RULE,
            ""
        );
        let defaultConnectionStatus =
            this.connectionStatusesList.find(
                (status) =>
                    status.name === DefaultFilters.DEFAULT_CONNECTION_STATUS
            ) || this.connectionStatusesList[0];

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CONNECTION_RULE,
            defaultConnectionStatus
        );

        await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);
    }
}
