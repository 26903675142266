export class AuthResponse {
    public constructor(
        public isSuccess: boolean = false,
        public errorCode: number = 0,
        public errorMessage: string = "",
        public requestId: string = "",
        public data: string[]
    ) {}
}

export class AuthorizationStatus {
    public constructor(public loggedIn: boolean = false) {}
}

export class Credentials {
    public constructor(
        public login: string = "",
        public password: string = ""
    ) {}
}

export interface AuthorizationApi {
    login(cred: Credentials): Promise<string>;

    resendInvitation(id: string): Promise<boolean>;
}
