import {
    Citizen,
    CitizensApi,
    CitizensCursor,
    CitizensStatePage,
} from "@/app/types/citizens";
import { Page } from "@/app/types/page";
import { HttpClient } from "@/deputy/utils/httpClient";

export class CitizensHttpApi implements CitizensApi {
    private readonly client: HttpClient = new HttpClient();

    public async list(cursor: CitizensCursor): Promise<CitizensStatePage> {
        const response = await this.client.get(`/citizen?${cursor.toQuery()}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new CitizensStatePage([], Page.fromJson(response));
        }

        return new CitizensStatePage(
            response.data.map((citizen) => {
                return Citizen.fromJson(citizen);
            }),
            Page.fromJson(response)
        );
    }

    public async block(id: string): Promise<void> {
        const response = await this.client.post(`/citizen/${id}/block`, null);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }
    }

    public async unblock(id: string): Promise<void> {
        const response = await this.client.delete(`/citizen/${id}/block`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }
    }
}
