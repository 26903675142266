
























import { Component, Vue } from "vue-property-decorator";
import ManagerRow from "@/app/components/managers/ManagerRow.vue";
import Table from "@/app/components/common/Table.vue";
import Button from "@/app/components/common/Button.vue";
import {
    MANAGERS_ACTIONS,
    MANAGERS_GETTERS,
} from "@/app/store/modules/managers";
import { Manager } from "@/app/types/manager";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";
import { PaginationModel } from "@/app/types/paginationModel";
import Pagination from "@/app/components/common/Pagination.vue";
import DeputiesSearchInputField from "@/app/components/common/DeputiesSearchInputField.vue";
import ManagersFilterPanel from "@/app/components/managers/ManagersFilterPanel.vue";
import Loader from "@/app/components/common/Loader.vue";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { CitiesListRule } from "@/app/types/filters";
import { DefaultFilters } from "@/app/types/defaultFilters";

@Component({
    components: {
        ManagerRow,
        Table,
        Button,
        Pagination,
        Loader,
        DeputiesSearchInputField,
        ManagersFilterPanel,
    },
})
export default class ManagersPage extends Vue {
    private columns: string[] = ["Логін", "ПІБ", "Депутат", "Роль", "Опції"];
    private loaderVisibility: boolean | undefined = false;

    private get pagination(): PaginationModel {
        return new PaginationModel(
            Math.ceil(
                this.$store.state.managersModule.page.totalCount /
                    this.$store.state.managersModule.cursor.cursor.limit
            ),
            this.$store.state.managersModule.cursor.cursor.page,
            this.$store.state.managersModule.page.nextPage
        );
    }

    private get totalCount(): number {
        return this.$store.state.managersModule.page.totalCount;
    }

    private get rows(): Manager[] {
        return this.$store.state.managersModule.managers;
    }

    private toggleLoader(value): void {
        this.loaderVisibility = value;
    }

    private async getNewManagers(page) {
        try {
            await this.$store.dispatch(
                MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_PAGE,
                page
            );
            await this.$store.dispatch(MANAGERS_ACTIONS.GET_MANAGERS);
        } catch (e) {
            console.error(e);

            return;
        }
    }

    async mounted() {
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CITIES_LIST,
            new CitiesListRule(DefaultFilters.DEFAULT_CITY)
        );
        let cities = this.$store.state.filtersModule.citiesState.list;
        let defaultCity =
            cities.find((city) => city.name === DefaultFilters.DEFAULT_CITY) ||
            cities[0];

        let filterByCityRule = this.$store.getters[
            MANAGERS_GETTERS.GET_MANAGERS_CURSOR_CITY
        ].id
            ? this.$store.getters[MANAGERS_GETTERS.GET_MANAGERS_CURSOR_CITY]
            : defaultCity;

        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_CITY_RULE,
            filterByCityRule
        );

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CITY_RULE,
            filterByCityRule
        );

        await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);
        await this.$store.dispatch(MANAGERS_ACTIONS.GET_MANAGERS);
    }
}
