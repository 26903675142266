




































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { ConstituenciesListRule } from "@/app/types/filters";
import {
    DEPUTIES_ACTIONS,
    DEPUTIES_GETTERS,
} from "@/app/store/modules/deputies";
import Checkbox from "@/app/components/common/Checkbox.vue";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import AvatarUpload from "@/app/components/common/AvatarUpload.vue";
import { DeputyCreationRule } from "@/app/types/deputy";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";

@Component({
    components: {
        Button,
        InputField,
        Dropdown,
        Checkbox,
        AvatarUpload,
        CityFilterInputField
    },
})
export default class DeputyCreatingWindow extends Vue {
    public static componentName: string | undefined = "DeputyCreatingWindow";

    private photo: File[] = [];
    private firstName: string | undefined = "";
    private middleName: string | undefined = "";
    private lastName: string | undefined = "";
    private isConnected: boolean | undefined = false;

    private partyOption: DropdownItemModel = this.creationRule.party;
    private districtOption: DropdownItemModel = this.creationRule.district;
    private constituencyOption: DropdownItemModel =
        this.creationRule.constituency;

    private receptionAddress: string | undefined = "";
    private socialNetworkTwitter: string | undefined = "";
    private socialNetworkTelegram: string | undefined = "";
    private socialNetworkInstagram: string | undefined = "";
    private socialNetworkFacebook: string | undefined = "";
    private socialNetworkWeb: string | undefined = "";

    private get creationRule(): DeputyCreationRule {
        return this.$store.getters[DEPUTIES_GETTERS.GET_DEPUTIES_CREATION_RULE];
    }

    private get cityOption(): DropdownItemModel {
        return this.creationRule.city;
    }

    private get areAllFieldsFilled(): boolean {
        return (
            !!this.firstName &&
            !!this.lastName &&
            !!this.cityOption.id
        );
    }

    private get constituenciesListRule(): ConstituenciesListRule {
        return new ConstituenciesListRule(
            String(this.cityOption.id),
            String(this.districtOption.id)
        );
    }

    private get partiesList(): DropdownItemModel[] {
        return this.$store.state.partiesModule.parties.map((party) => {
            return new DropdownItemModel(party.id, party.title);
        });
    }

    private get districtsList(): DropdownItemModel[] {
        return this.$store.state.filtersModule.districtsList;
    }

    private get constituenciesList(): DropdownItemModel[] {
        return this.$store.state.filtersModule.constituenciesList.map(
            (item) => item.constituency
        );
    }

    private get isDistrictsFilterDisabled(): boolean {
        return !this.cityOption.id;
    }

    private get isConstituenciesFilterDisabled(): boolean {
        return !this.cityOption.id;
    }

    private selectPhoto(file: File) {
        this.photo = [];
        if (file !== null) {
            this.photo.push(file);
            this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_AVATAR_RULE,
                this.photo[0]
            );
        }
    }

    private async selectParty(value): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_PARTY_RULE,
            value
        );
    }

    private async selectCity(value: DropdownItemModel): Promise<void> {
        if (this.cityOption.id === value.id) {
            return;
        }

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_PARTY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_PARTIES_CURSOR_CITY,
            value
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CITY_RULE,
            value
        );

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_DISTRICT_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CONSTITUENCY_RULE,
            new DropdownItemModel()
        );

        if (!value.id) {
            return;
        }

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_DISTRICTS_LIST,
            value.id
        );
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            this.constituenciesListRule
        );
    }

    private async selectDistrict(value): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_DISTRICT_RULE,
            value
        );

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CONSTITUENCY_RULE,
            new DropdownItemModel()
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            this.constituenciesListRule
        );
    }

    private async selectConstituency(value): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CONSTITUENCY_RULE,
            value
        );
    }

    private async createDeputy(): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_FIRST_NAME_RULE,
            this.firstName
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_MIDDLE_NAME_RULE,
            this.middleName
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_LAST_NAME_RULE,
            this.lastName
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_CONNECTED_RULE,
            this.isConnected
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_RECEPTION_RULE,
            this.receptionAddress
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_TWITTER_RULE,
            this.socialNetworkTwitter
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_TELEGRAM_RULE,
            this.socialNetworkTelegram
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_INSTAGRAM_RULE,
            this.socialNetworkInstagram
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_FACEBOOK_RULE,
            this.socialNetworkFacebook
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_CREATION_DEPUTY_WEB_RULE,
            this.socialNetworkWeb
        );

        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(DEPUTIES_ACTIONS.CREATE_DEPUTY);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Депутата не вдалося створити")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Депутата успішно створено")
        );

        await this.$store.dispatch(DEPUTIES_ACTIONS.RESET_DEPUTY_CREATION_RULE);

        await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }

    @Watch("creationRule", { immediate: true, deep: true })
    resetDefault() {
        this.partyOption = this.creationRule.party;
        this.districtOption = this.creationRule.district;
        this.constituencyOption = this.creationRule.constituency;
    }
}
