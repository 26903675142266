









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import Loader from "@/app/components/common/Loader.vue";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { LoaderState } from "@/app/types/loaderState";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";

@Component({ components: { Loader } })
export default class DeputiesSearchInputField extends Vue {
    @Prop({ default: new DropdownItemModel() })
    private readonly initial: DropdownItemModel;

    @Prop({ default: new DropdownItemModel() })
    private readonly currentRule: DropdownItemModel;

    @Prop({ default: false })
    private readonly isRequired: boolean;

    @Prop({ default: "" })
    private readonly label: string;

    @Prop({ default: "" })
    private readonly placeholder: string;

    private value: string | undefined = "";
    private listVisibility: boolean | undefined = false;
    private allowSearching: boolean | undefined = true;
    private loaderVisibility: boolean | undefined = false;

    private get deputiesList(): DropdownItemModel[] {
        return this.$store.state.deputiesModule.deputies.map((deputy) => {
            return new DropdownItemModel(
                deputy.id,
                `${deputy.firstName} ${deputy.middleName} ${deputy.lastName}`
            );
        });
    }

    private get currentPage(): number {
        return this.$store.state.deputiesModule.cursor.cursor.page;
    }

    private get nextPage(): number {
        return this.$store.state.deputiesModule.page.nextPage;
    }

    private async openDeputiesList(): Promise<void> {
        if (this.listVisibility) {
            return;
        }

        this.listVisibility = true;
        await this.searchDeputy();
    }

    private closeDeputiesList(): void {
        this.listVisibility = false;
    }

    private selectDeputy(deputy: DropdownItemModel): void {
        this.value = deputy.name;
        this.listVisibility = false;
        this.$emit("input", deputy);
    }

    private async searchDeputy(): Promise<void> {
        let currentSearchValue: string | undefined = "";
        this.listVisibility = true;
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PAGE,
            1
        );

        if (this.value !== this.currentRule.name) {
            this.$emit("input", new DropdownItemModel("", this.value));
        }

        if (!this.allowSearching) {
            return;
        }

        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_NAME_RULE,
            this.value
        );
        await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);

        currentSearchValue = this.value;
        this.allowSearching = false;

        setTimeout(async () => {
            this.allowSearching = true;

            if (currentSearchValue === this.value) {
                return;
            }

            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_NAME_RULE,
                this.value
            );
            await this.$store.dispatch(DEPUTIES_ACTIONS.GET_DEPUTIES);

            currentSearchValue = this.value;
        }, 1000);
    }

    private async paginateDeputyList(e): Promise<void> {
        const column = e.target;
        const scrollPosition = column.scrollTop + column.offsetHeight;

        if (this.nextPage === -1 || this.currentPage === this.nextPage) {
            return;
        }

        if (scrollPosition < column.scrollHeight - 38) {
            return;
        }

        try {
            await this.$store.dispatch(
                DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_PAGE,
                this.nextPage
            );
            await this.$store.dispatch(DEPUTIES_ACTIONS.EXPAND_DEPUTIES_LIST);
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            this.loaderVisibility = true;
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.loaderVisibility = false;
            console.error(e);

            return;
        }

        setTimeout(async () => {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.loaderVisibility = false;
        }, 700);
    }

    mounted() {
        this.value = this.initial.name;
    }

    @Watch("currentRule", { immediate: true, deep: true })
    reset() {
        if (!this.currentRule.name) {
            this.value = "";
        }
    }
}
