











































































































































































































































































































































































































































































import SignOutWindow from "@/app/components/popups/SignOutWindow.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import { Component, Vue } from "vue-property-decorator";

import { RoutePaths } from "@/app/router";

@Component({})
export default class SidebarNav extends Vue {
    private get routePaths(): any {
        return RoutePaths;
    }

    private openSignoutPopup(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(SignOutWindow.componentName, true, false)
        );
    }
}
