


































































































import { Component, Vue } from "vue-property-decorator";

import Dropdown from "@/app/components/common/Dropdown.vue";
import InputField from "@/app/components/common/InputField.vue";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";

@Component({ components: { Dropdown, InputField } })
export default class FilterDropdown extends Vue {
    private get visibility(): boolean {
        return this.$store.state.appStateModule.filtersPanelVisibility;
    }

    private toggleFilterDropdown(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY,
            !this.visibility
        );
    }

    private closeFilterDropdown(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY,
            false
        );
    }
}
