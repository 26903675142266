





















import { Component, Vue, Watch } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { PARTIES_ACTIONS } from "@/app/store/modules/parties";
import { PartyEditingRule } from "@/app/types/party";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { ConstituenciesListRule, DistrictEditingRule } from "@/app/types/filters";
import { FILTERS_ACTIONS, FILTERS_GETTERS } from "@/app/store/modules/filters";
import { DefaultFilters } from "@/app/types/defaultFilters";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

@Component({ components: { Button, InputField, Dropdown } })
export default class DistrictEditingWindow extends Vue {
    public static componentName: string | undefined = "DistrictEditingWindow";
    private title: string = this.editingRule.districtNewName;

    private get editingRule(): DistrictEditingRule {
        return this.$store.getters[FILTERS_GETTERS.DISTRICT_EDITING_RULE];
    }

    private get cursorCity(): DropdownItemModel {
        return this.$store.state.filtersModule.cursor.city;
    }

    private async editDistrict(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(
                FILTERS_ACTIONS.SET_DISTRICT_EDITING_RULE,
                new DistrictEditingRule(String(this.editingRule.id), this.title)
            );
            await this.$store.dispatch(FILTERS_ACTIONS.EDIT_DISTRICT);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Район не вдалося відредагувати")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Район успішно змінено")
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_DISTRICTS_LIST,
            this.cursorCity.id
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            new ConstituenciesListRule(String(this.cursorCity.id), '')
        );
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
