import { Page } from "@/app/types/page";
import { HttpClient } from "@/deputy/utils/httpClient";
import {
    DeletedCitizen,
    DeletedCitizensApi,
    DeletedCitizensCursor,
    DeletedCitizensPage,
} from "@/app/types/deletedCitizens";

export class DeletedCitizensHttpApi implements DeletedCitizensApi {
    private readonly client: HttpClient = new HttpClient();

    public async list(
        cursor: DeletedCitizensCursor
    ): Promise<DeletedCitizensPage> {
        const response = await this.client.get(
            `/deleted-citizens?${cursor.toQuery()}`
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new DeletedCitizensPage([], Page.fromJson(response));
        }

        return new DeletedCitizensPage(
            response.data.map((citizen) => {
                return DeletedCitizen.fromJson(citizen);
            }),
            Page.fromJson(response)
        );
    }
}
