







































































import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/app/components/common/Button.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import { Phone, PhonesDeletingRule } from "@/app/types/phone";
import PhoneDeletingWindow from "@/app/components/popups/PhoneDeletingWindow.vue";
import { PHONES_ACTIONS } from "@/app/store/modules/phones";

@Component({ components: { Button } })
export default class PhoneRow extends Vue {
    @Prop({ default: new Phone() })
    private readonly row: Phone;

    private get date(): string {
        const formatter = new Intl.DateTimeFormat("uk-UA", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        });

        return formatter.format(new Date(this.row.createdAt));
    }

    private get isVerifiedString(): string {
        return this.row.isVerified ? "Так" : "Ні";
    }

    private openDeletingPopup(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(PhoneDeletingWindow.componentName, true, false)
        );
        this.$store.dispatch(
            PHONES_ACTIONS.SET_PHONES_DELETING_RULE,
            new PhonesDeletingRule(this.row.id)
        );
    }
}
