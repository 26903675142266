import Vue from "vue";

export class CookieManager {
    public static COOKIE_KEY_TOKEN = "cookie_token";

    public static setToken(token: string): void {
        CookieManager.set(CookieManager.COOKIE_KEY_TOKEN, token);
    }

    public static getToken(): string {
        return Vue.$cookies.get(CookieManager.COOKIE_KEY_TOKEN);
    }

    private static set(key: string, value: any): void {
        Vue.$cookies.set(key, value, "21d");
        // Vue.$cookies.set(key, value, '21d', '', '', true, 'Strict');
    }

    public static remove(key: string): void {
        Vue.$cookies.remove(key);
    }
}
