











































import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import InputField from "@/app/components/common/InputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { PARTIES_ACTIONS, PARTIES_GETTERS } from "@/app/store/modules/parties";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import { PartyCreationRule } from "@/app/types/party";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";
import AvatarUpload from "@/app/components/common/AvatarUpload.vue";

@Component({
    components: {
        Button,
        InputField,
        Dropdown,
        CityFilterInputField,
        AvatarUpload,
    },
})
export default class PartyCreatingWindow extends Vue {
    public static componentName: string | undefined = "PartyCreatingWindow";
    private title: string | undefined = "";
    private photo: File[] = [];

    private get creationRule(): PartyCreationRule {
        return this.$store.getters[PARTIES_GETTERS.GET_PARTIES_CREATION_RULE];
    }

    private get cityOption(): DropdownItemModel {
        return this.creationRule.city;
    }

    private get areAllFieldsFilled(): boolean {
        return !!this.title && !!this.cityOption.id;
    }

    private selectPhoto(file: File) {
        this.photo = [];
        if (file !== null) {
            this.photo.push(file);
            this.$store.dispatch(
                PARTIES_ACTIONS.SET_CREATION_PARTY_AVATAR_RULE,
                this.photo[0]
            );
        }
    }

    private async selectCity(value: DropdownItemModel): Promise<void> {
        if (this.cityOption.id === value.id) {
            return;
        }

        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_CREATION_PARTY_CITY_RULE,
            value
        );
    }

    private async createParty(): Promise<void> {
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_CREATION_PARTY_TITLE_RULE,
            this.title
        );

        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(PARTIES_ACTIONS.CREATE_PARTY);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Партію не вдалося створити")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Партію успішно створено")
        );
        await this.$store.dispatch(PARTIES_ACTIONS.GET_PARTIES);

        this.title = "";
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_CREATION_PARTY_TITLE_RULE,
            ""
        );
        await this.$store.dispatch(
            PARTIES_ACTIONS.SET_CREATION_PARTY_CITY_RULE,
            new DropdownItemModel()
        );
    }

    private cancel(): void {
        console.log("cancel editing");
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
