



























































import { Component, Vue, Watch } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import {
    MANAGERS_ACTIONS,
    MANAGERS_GETTERS,
} from "@/app/store/modules/managers";
import DeputiesSearchInputField from "@/app/components/common/DeputiesSearchInputField.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { LoaderState } from "@/app/types/loaderState";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";

@Component({ components: { Dropdown, DeputiesSearchInputField, Button, CityFilterInputField } })
export default class ManagersFilterDropdownInner extends Vue {
    private get currentCityRule(): DropdownItemModel {
        return this.$store.getters[MANAGERS_GETTERS.GET_MANAGERS_CURSOR_CITY];
    }

    private get currentDeputyRule(): DropdownItemModel {
        return this.$store.getters[MANAGERS_GETTERS.GET_MANAGERS_CURSOR_DEPUTY];
    }

    private async selectCity(value): Promise<void> {
        if (this.currentCityRule.id === value.id) {
            return;
        }

        await this.$store.dispatch(DEPUTIES_ACTIONS.RESET_DEPUTIES_CURSOR);
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_DEPUTY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CITY_RULE,
            value
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_CITY_RULE,
            value
        );
    }

    private async setDeputyRule(value: DropdownItemModel): Promise<void> {
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_DEPUTY_RULE,
            value
        );
    }

    private async resetFilters(): Promise<void> {
        await this.$store.dispatch(
            DEPUTIES_ACTIONS.SET_DEPUTIES_CURSOR_CITY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_CITY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            MANAGERS_ACTIONS.SET_MANAGERS_CURSOR_DEPUTY_RULE,
            new DropdownItemModel()
        );
        await this.filter();
    }

    private async filter(): Promise<void> {
        await this.$store.dispatch(MANAGERS_ACTIONS.RESET_MANAGERS_CURSOR);

        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            await this.$store.dispatch(MANAGERS_ACTIONS.GET_MANAGERS);
            this.$emit("toggleLoader", true);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.$emit("toggleLoader", false);
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState().Bounded()
        );
        this.$emit("toggleLoader", false);
        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY,
            false
        );
    }
}
