export class Cursor {
    public constructor(public page: number = 1, public limit: number = 10) {}

    public toURLParams(): URLSearchParams {
        const a = new URLSearchParams();

        a.append("page", this.page.toString());
        a.append("limit", this.limit.toString());

        return a;
    }
}
