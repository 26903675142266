






































































import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/app/components/common/Button.vue";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import {
    ConstituencyDetailed,
    ConstituencyEditingRule,
} from "@/app/types/filters";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import ConstituencyEditingWindow from "@/app/components/popups/ConstituencyEditingWindow.vue";

@Component({ components: { Button } })
export default class ConstituencyRow extends Vue {
    @Prop({ default: new ConstituencyDetailed() })
    private readonly row: ConstituencyDetailed;

    private async openEditingPopup(): Promise<void> {
        await this.$store.dispatch(
            FILTERS_ACTIONS.SET_CONSTITUENCY_EDITING_RULE,
            new ConstituencyEditingRule(
                String(this.row.constituency.id),
                this.row.relatedDistrictBelonging,
                +this.row.constituency.name
            )
        );

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(ConstituencyEditingWindow.componentName, true, false)
        );
    }
}
