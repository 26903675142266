import Vue from "vue";
import Vuex from "vuex";

import { appStateModule } from "./modules/appState";

import { makeAuthorizationModule } from "@/app/store/modules/authorization";
import { makePartiesModule } from "@/app/store/modules/parties";
import { makeDeputiesModule } from "@/app/store/modules/deputies";
import { makeManagersModule } from "@/app/store/modules/managers";
import { makeCitizensModule } from "@/app/store/modules/citizens";
import { makeFiltersModule } from "@/app/store/modules/filters";
import { makeReportsModule } from "@/app/store/modules/reports";
import { makePhonesModule } from "@/app/store/modules/phones";
import { makeDeletedCitizensModule } from "@/app/store/modules/deletedCitizens";

import { AuthorizationHttpApi } from "@/deputy/api/authorization";
import { PartiesHttpApi } from "@/deputy/api/parties";
import { DeputiesHttpApi } from "@/deputy/api/deputies";
import { ManagersHttpApi } from "@/deputy/api/managers";
import { CitizensHttpApi } from "@/deputy/api/citizens";
import { FiltersHttpApi } from "@/deputy/api/filters";
import { ReportsHttpApi } from "@/deputy/api/reports";
import { PhonesHttpApi } from "@/deputy/api/phones";
import { DeletedCitizensHttpApi } from "@/deputy/api/deletedCitizens";

const authorizationApi = new AuthorizationHttpApi();
const partiesApi = new PartiesHttpApi();
const deputiesApi = new DeputiesHttpApi();
const managersApi = new ManagersHttpApi();
const citizensApi = new CitizensHttpApi();
const filtersApi = new FiltersHttpApi();
const reportsApi = new ReportsHttpApi();
const phonesApi = new PhonesHttpApi();
const deletedCitizensApi = new DeletedCitizensHttpApi();

Vue.use(Vuex);

/**
 * storage node store (vuex)
 */
export const store = new Vuex.Store({
    modules: {
        appStateModule,
        authorizationModule: makeAuthorizationModule(authorizationApi),
        partiesModule: makePartiesModule(partiesApi),
        deputiesModule: makeDeputiesModule(deputiesApi),
        managersModule: makeManagersModule(managersApi),
        citizensModule: makeCitizensModule(citizensApi),
        filtersModule: makeFiltersModule(filtersApi),
        reportsModule: makeReportsModule(reportsApi),
        phonesModule: makePhonesModule(phonesApi),
        deletedCitizensModule: makeDeletedCitizensModule(deletedCitizensApi),
    },
});

export default store;
