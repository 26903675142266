import { Page } from "@/app/types/page";
import {
    PartiesApi,
    PartiesCursor,
    PartiesPage,
    Party,
    PartyAvatarRule,
    PartyCreationRule,
    PartyDeletingRule,
    PartyEditingRule,
} from "@/app/types/party";
import { HttpClient } from "@/deputy/utils/httpClient";

export class PartiesHttpApi implements PartiesApi {
    private readonly client: HttpClient = new HttpClient();

    public async list(cursor: PartiesCursor): Promise<PartiesPage> {
        const response = await this.client.get(`/party?${cursor.toQuery()}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new PartiesPage([], Page.fromJson(response));
        }

        return new PartiesPage(
            response.data.map((party) => {
                return Party.fromJson(party);
            }),
            Page.fromJson(response)
        );
    }

    public async create(rule: PartyCreationRule): Promise<Party> {
        const response = await this.client.post("/party", rule.toJson());
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new Party();
        }

        return Party.fromJson(response.data[0]);
    }

    public async edit(rule: PartyEditingRule): Promise<Party> {
        const response = await this.client.put(
            `/party/${rule.id}`,
            rule.toJson()
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return new Party();
        }

        return Party.fromJson(response.data[0]);
    }

    public async delete(rule: PartyDeletingRule): Promise<boolean> {
        const response = await this.client.delete(`/party/${rule.id}`);
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }

    public async logo(rule: PartyAvatarRule): Promise<boolean> {
        const response = await this.client.sendFile(
            `/party/${rule.partyId}/logo`,
            rule.avatar[0],
            new Headers()
        );
        if (!response.is_success) {
            throw new Error(response.error_message);
        }

        if (!response.data || !response.data.length) {
            return false;
        }

        return true;
    }
}
