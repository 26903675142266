export class Image {
    public constructor(
        public id: string = '',
        public name: string = '',
        public originalURL: string = '',
        public previewURL: string = '',
    ) {
    }

    static fromJson(json: any): Image {
        if (json === null || json === undefined) {
            return new Image();
        }

        return new Image(
            json.id,
            json.name,
            json.original_image,
            json.preview_image,
        );
    }
}
