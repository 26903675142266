import { Page } from "@/app/types/page";
import {
    Report,
    ReportApplication,
    ReportsApi,
    ReportsState,
    statusChangingRule,
} from "@/app/types/report";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

export const REPORTS_MUTATIONS = {
    SET_REPORTS: "SET_REPORTS",
    GET_REPORT: "GET_REPORT",
    SET_REPORTS_CURSOR_PAGE: "SET_REPORTS_CURSOR_PAGE",
    SET_REPORTS_PAGINATION: "SET_REPORTS_PAGINATION",
    SET_REPORTS_STATUS_RULE: "SET_REPORTS_STATUS_RULE",

    SET_REPORT_APPLICATION: "SET_REPORT_APPLICATION",
    SET_CURRENT_REPORT_MATERIAL_ID: "SET_CURRENT_REPORT_MATERIAL_ID",
};

export const REPORTS_ACTIONS = {
    GET_REPORTS: "GET_REPORTS",
    SET_REPORT: "SET_REPORT",
    SET_REPORTS_CURSOR_PAGE: "SET_REPORTS_CURSOR_PAGE",
    SET_REPORTS_STATUS_RULE: "SET_REPORTS_STATUS_RULE",
    CHANGE_REPORT_STATUS: "CHANGE_REPORT_STATUS",

    SET_REPORT_APPLICATION: "SET_REPORT_APPLICATION",
    ACTIVATE_REPORT_APPLICATION: "ACTIVATE_REPORT_APPLICATION",
    DEACTIVATE_REPORT_APPLICATION: "DEACTIVATE_REPORT_APPLICATION",
    SET_CURRENT_REPORT_MATERIAL_ID: "SET_CURRENT_REPORT_MATERIAL_ID",
    REMOVE_REPORT_APPLICATION_MATERIAL: "REMOVE_REPORT_APPLICATION_MATERIAL",
};

export function makeReportsModule(api: ReportsApi) {
    return {
        state: new ReportsState(),
        mutations: {
            [REPORTS_MUTATIONS.SET_REPORTS](
                state: ReportsState,
                list: Report[]
            ): void {
                state.reports = list;
            },
            [REPORTS_MUTATIONS.GET_REPORT](
                state: ReportsState,
                report: Report
            ): void {
                state.currentReport = report;
            },
            [REPORTS_MUTATIONS.SET_REPORTS_CURSOR_PAGE](
                state: ReportsState,
                page: number
            ): void {
                state.cursor.cursor.page = page;
            },
            [REPORTS_MUTATIONS.SET_REPORTS_PAGINATION](
                state: ReportsState,
                page: Page
            ): void {
                state.page = page;
            },
            [REPORTS_MUTATIONS.SET_REPORTS_STATUS_RULE](
                state: ReportsState,
                status: DropdownItemModel
            ): void {
                state.cursor.status = status;
            },

            [REPORTS_MUTATIONS.SET_REPORT_APPLICATION](
                state: ReportsState,
                application: ReportApplication
            ): void {
                state.application = application;
            },
            [REPORTS_MUTATIONS.SET_CURRENT_REPORT_MATERIAL_ID](
                state: ReportsState,
                id: string
            ): void {
                state.currentReportMaterialId = id;
            },
        },
        actions: {
            [REPORTS_ACTIONS.GET_REPORTS]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const response = await api.list(state.cursor);
                commit(REPORTS_MUTATIONS.SET_REPORTS, response.reports);
                commit(REPORTS_MUTATIONS.SET_REPORTS_PAGINATION, response.page);
            },
            [REPORTS_ACTIONS.SET_REPORT]: async function (
                { commit, state }: any,
                id: string
            ): Promise<void> {
                const report = await api.getReport(id);
                commit(REPORTS_MUTATIONS.GET_REPORT, report);
            },
            [REPORTS_ACTIONS.SET_REPORTS_CURSOR_PAGE]: function (
                { commit, state }: any,
                page: number
            ): void {
                commit(REPORTS_MUTATIONS.SET_REPORTS_CURSOR_PAGE, page);
            },
            [REPORTS_ACTIONS.SET_REPORTS_STATUS_RULE]: function (
                { commit }: any,
                status: DropdownItemModel
            ): void {
                commit(REPORTS_MUTATIONS.SET_REPORTS_STATUS_RULE, status);
            },
            [REPORTS_ACTIONS.CHANGE_REPORT_STATUS]: async function (
                { commit, state }: any,
                rule: statusChangingRule
            ): Promise<void> {
                await api.changeStatus(rule);
            },

            [REPORTS_ACTIONS.SET_REPORT_APPLICATION]: async function (
                { commit, state }: any,
                id: string
            ): Promise<void> {
                const application = await api.getApplication(id);
                commit(REPORTS_MUTATIONS.SET_REPORT_APPLICATION, application);
            },
            [REPORTS_ACTIONS.ACTIVATE_REPORT_APPLICATION]: async function (
                { commit, state }: any,
                id: string
            ): Promise<void> {
                await api.activateApplication(id);
            },
            [REPORTS_ACTIONS.DEACTIVATE_REPORT_APPLICATION]: async function (
                { commit, state }: any,
                id: string
            ): Promise<void> {
                await api.deactivateApplication(id);
            },
            [REPORTS_ACTIONS.SET_CURRENT_REPORT_MATERIAL_ID]: function (
                { commit, state }: any,
                id: string
            ): void {
                commit(REPORTS_MUTATIONS.SET_CURRENT_REPORT_MATERIAL_ID, id);
            },
            [REPORTS_ACTIONS.REMOVE_REPORT_APPLICATION_MATERIAL]:
                async function ({ commit, state }: any): Promise<void> {
                    await api.removeApplicationMaterial(
                        state.currentReportMaterialId
                    );
                },
        },
    };
}
