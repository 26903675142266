export class ParsedResponse {
    public constructor(
        public request_id: string = "",
        public is_success: boolean = false,
        public error_code: number = 0,
        public error_message: string = "",
        public data: any,
        public page: number = 0,
        public total_count: number = 0,
        public unfiltered_count: number = 0,
        public next_page: number = 0
    ) {}
}
