import { AddressShort } from "@/app/types/addressShort";
import { Cursor } from "@/app/types/cursor";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { Page } from "@/app/types/page";

export class Citizen {
    public constructor(
        public id: number = 0,
        public fullName: string = "",
        public phone: string = "",
        public isBlocked: boolean = false,
        public isFinishedRegistration: boolean = true,
        public address: AddressShort = new AddressShort()
    ) {}

    public static fromJson(obj: any): Citizen {
        return new Citizen(
            obj.id,
            obj.full_name,
            obj.phone_number,
            obj.is_blocked,
            obj.is_finished_registration,
            obj.address
                ? AddressShort.fromJson(obj.address)
                : new AddressShort()
        );
    }
}

export class CitizensStatePage {
    public constructor(
        public citizens: Citizen[] = [],
        public page: Page = new Page()
    ) {}
}

export class CitizensState {
    public constructor(
        public citizens: Citizen[] = [],
        public cursor: CitizensCursor = new CitizensCursor(),
        public page: Page = new Page()
    ) {}
}

export class CitizensCursor {
    public constructor(
        public cursor: Cursor = new Cursor(),
        public filter: CitizensFilter = new CitizensFilter()
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        if (!isEmpty(this.filter.city.id)) {
            a.append("city_id", String(this.filter.city.id));
        }

        if (!isEmpty(this.filter.district.id)) {
            a.append("district_id", String(this.filter.district.id));
        }

        if (!isEmpty(this.filter.constituency.id)) {
            a.append("constituency_id", String(this.filter.constituency.id));
        }

        if (!isEmpty(this.filter.pollingStation.id)) {
            a.append(
                "polling_station_id",
                String(this.filter.pollingStation.id)
            );
        }

        if (!isEmpty(this.filter.streetName)) {
            a.append("street_name", this.filter.streetName);
        }

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

export class CitizensFilter {
    public constructor(
        public city: DropdownItemModel = new DropdownItemModel(),
        public district: DropdownItemModel = new DropdownItemModel(),
        public constituency: DropdownItemModel = new DropdownItemModel(),
        public pollingStation: DropdownItemModel = new DropdownItemModel(),
        public streetName: string = ""
    ) {}
}

function isEmpty(s: string | number): boolean {
    return !s || String(s).length === 0;
}

export interface CitizensApi {
    list(cursor: CitizensCursor): Promise<CitizensStatePage>;

    block(id: string): Promise<void>;

    unblock(id: string): Promise<void>;
}
