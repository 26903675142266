export class LoaderState {
    public constructor(
        public isVisible: boolean = false,
        public allowFullscreen: boolean = true
    ) {}

    public Visible(): LoaderState {
        this.isVisible = true;

        return this;
    }

    public Bounded(): LoaderState {
        this.allowFullscreen = false;

        return this;
    }
}
