











import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";

import { RoutePaths } from "@/app/router";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { AUTHORIZATION_ACTIONS } from "@/app/store/modules/authorization";

@Component({ components: { Button } })
export default class SignOutWindow extends Vue {
    public static componentName: string | undefined = "SignOutWindow";

    private logout(): void {
        this.$store.dispatch(AUTHORIZATION_ACTIONS.LOGOUT);
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        this.$router.push(RoutePaths.SIGN_IN.path);
    }

    private stay(): void {
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
