











import { Component, Vue } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { DEPUTIES_ACTIONS } from "@/app/store/modules/deputies";
import { LoaderState } from "@/app/types/loaderState";
import { Alert } from "@/app/types/alert";
import { PHONES_ACTIONS } from "@/app/store/modules/phones";

@Component({ components: { Button } })
export default class PhoneDeletingWindow extends Vue {
    public static componentName: string | undefined = "PhoneDeletingWindow";

    private async deletePhone(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible()
            );
            await this.$store.dispatch(PHONES_ACTIONS.DELETE_PHONE);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState()
            );
            await this.$store.dispatch(
                APPSTATE_ACTIONS.START_ALERT_STATE,
                new Alert("error", true, "Телефон не вдалося видалити")
            );
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState()
        );
        await this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
        await this.$store.dispatch(
            APPSTATE_ACTIONS.START_ALERT_STATE,
            new Alert("success", true, "Телефон успішно видалено")
        );
        await this.$store.dispatch(PHONES_ACTIONS.SET_PHONES_CURSOR_PAGE, 1);
        await this.$store.dispatch(PHONES_ACTIONS.GET_PHONES_LIST);
    }

    private cancel(): void {
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
        });
    }
}
