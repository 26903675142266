import { Citizen, CitizensApi, CitizensState } from "@/app/types/citizens";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { Page } from "@/app/types/page";

export const CITIZENS_MUTATIONS = {
    SET_CITIZENS_LIST: "SET_CITIZENS_LIST",
    SET_CITIZENS_PAGINATION: "SET_CITIZENS_PAGINATION",
    SET_CITIZENS_CURSOR_PAGE: "SET_CITIZENS_CURSOR_PAGE",

    SET_CITIZENS_CITY_RULE: "SET_CITIZENS_CITY_RULE",
    SET_CITIZENS_DISTRICT_RULE: "SET_CITIZENS_DISTRICT_RULE",
    SET_CITIZENS_CONSTITUENCY_RULE: "SET_CITIZENS_CONSTITUENCY_RULE",
    SET_CITIZENS_POLLING_STATION_RULE: "SET_CITIZENS_POLLING_STATION_RULE",
    SET_CITIZENS_STREET_NAME_RULE: "SET_CITIZENS_STREET_NAME_RULE",
};

export const CITIZENS_ACTIONS = {
    GET_CITIZENS_LIST: "GET_CITIZENS_LIST",
    RESET_CITIZENS_CURSOR: "RESET_CITIZENS_CURSOR",
    SET_CITIZENS_CURSOR_PAGE: "SET_CITIZENS_CURSOR_PAGE",

    SET_CITIZENS_CITY_RULE: "SET_CITIZENS_CITY_RULE",
    SET_CITIZENS_DISTRICT_RULE: "SET_CITIZENS_DISTRICT_RULE",
    SET_CITIZENS_CONSTITUENCY_RULE: "SET_CITIZENS_CONSTITUENCY_RULE",
    SET_CITIZENS_POLLING_STATION_RULE: "SET_CITIZENS_POLLING_STATION_RULE",
    SET_CITIZENS_STREET_NAME_RULE: "SET_CITIZENS_STREET_NAME_RULE",
    RESET_CITIZENS_FILTERS: "RESET_CITIZENS_FILTERS",

    BLOCK_CITIZEN: "BLOCK_CITIZEN",
    UNBLOCK_CITIZEN: "UNBLOCK_CITIZEN",
};

export const CITIZENS_GETTERS = {
    CITIZENS_CURSOR: "CITIZENS_CURSOR",
};

export function makeCitizensModule(api: CitizensApi) {
    return {
        state: new CitizensState(),
        mutations: {
            [CITIZENS_MUTATIONS.SET_CITIZENS_LIST](
                state: CitizensState,
                citizens: Citizen[]
            ): void {
                state.citizens = citizens;
            },
            [CITIZENS_MUTATIONS.SET_CITIZENS_PAGINATION](
                state: CitizensState,
                page: Page
            ): void {
                state.page = page;
            },
            [CITIZENS_MUTATIONS.SET_CITIZENS_CURSOR_PAGE](
                state: CitizensState,
                page: number
            ): void {
                state.cursor.cursor.page = page;
            },

            [CITIZENS_MUTATIONS.SET_CITIZENS_CITY_RULE](
                state: CitizensState,
                value: DropdownItemModel
            ): void {
                state.cursor.filter.city = value;
            },
            [CITIZENS_MUTATIONS.SET_CITIZENS_DISTRICT_RULE](
                state: CitizensState,
                value: DropdownItemModel
            ): void {
                state.cursor.filter.district = value;
            },
            [CITIZENS_MUTATIONS.SET_CITIZENS_CONSTITUENCY_RULE](
                state: CitizensState,
                value: DropdownItemModel
            ): void {
                state.cursor.filter.constituency = value;
            },
            [CITIZENS_MUTATIONS.SET_CITIZENS_POLLING_STATION_RULE](
                state: CitizensState,
                value: DropdownItemModel
            ): void {
                state.cursor.filter.pollingStation = value;
            },
            [CITIZENS_MUTATIONS.SET_CITIZENS_STREET_NAME_RULE](
                state: CitizensState,
                value: string
            ): void {
                state.cursor.filter.streetName = value;
            },
        },
        actions: {
            [CITIZENS_ACTIONS.GET_CITIZENS_LIST]: async function ({
                commit,
                state,
            }: any): Promise<void> {
                const response = await api.list(state.cursor);
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_LIST, response.citizens);
                commit(
                    CITIZENS_MUTATIONS.SET_CITIZENS_PAGINATION,
                    response.page
                );
            },
            [CITIZENS_ACTIONS.RESET_CITIZENS_CURSOR]: function ({
                commit,
            }: any): void {
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_PAGINATION, new Page());
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_CURSOR_PAGE, 1);
            },
            [CITIZENS_ACTIONS.SET_CITIZENS_CURSOR_PAGE]: function (
                { commit, state }: any,
                page: number
            ): void {
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_CURSOR_PAGE, page);
            },

            [CITIZENS_ACTIONS.SET_CITIZENS_CITY_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_CITY_RULE, value);
            },
            [CITIZENS_ACTIONS.SET_CITIZENS_DISTRICT_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_DISTRICT_RULE, value);
            },
            [CITIZENS_ACTIONS.SET_CITIZENS_CONSTITUENCY_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(
                    CITIZENS_MUTATIONS.SET_CITIZENS_CONSTITUENCY_RULE,
                    value
                );
            },
            [CITIZENS_ACTIONS.SET_CITIZENS_POLLING_STATION_RULE]: function (
                { commit }: any,
                value: DropdownItemModel
            ): void {
                commit(
                    CITIZENS_MUTATIONS.SET_CITIZENS_POLLING_STATION_RULE,
                    value
                );
            },
            [CITIZENS_ACTIONS.SET_CITIZENS_STREET_NAME_RULE]: function (
                { commit }: any,
                value: string
            ): void {
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_STREET_NAME_RULE, value);
            },
            [CITIZENS_ACTIONS.RESET_CITIZENS_FILTERS]: function ({
                commit,
            }: any): void {
                commit(
                    CITIZENS_MUTATIONS.SET_CITIZENS_CITY_RULE,
                    new DropdownItemModel()
                );
                commit(
                    CITIZENS_MUTATIONS.SET_CITIZENS_DISTRICT_RULE,
                    new DropdownItemModel()
                );
                commit(
                    CITIZENS_MUTATIONS.SET_CITIZENS_CONSTITUENCY_RULE,
                    new DropdownItemModel()
                );
                commit(
                    CITIZENS_MUTATIONS.SET_CITIZENS_POLLING_STATION_RULE,
                    new DropdownItemModel()
                );
                commit(CITIZENS_MUTATIONS.SET_CITIZENS_STREET_NAME_RULE, "");
            },
            [CITIZENS_ACTIONS.BLOCK_CITIZEN]: async function (
                { commit }: any,
                id: string
            ): Promise<void> {
                await api.block(id);
            },
            [CITIZENS_ACTIONS.UNBLOCK_CITIZEN]: async function (
                { commit }: any,
                id: string
            ): Promise<void> {
                await api.unblock(id);
            },
        },
        getters: {
            [CITIZENS_GETTERS.CITIZENS_CURSOR]: (state) => {
                return state.cursor.filter;
            },
        },
    };
}
