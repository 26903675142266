
































































































import { Component, Vue, Watch } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";
import StreetFilterInputField from "@/app/components/common/StreetFilterInputField.vue";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import {
    CITIZENS_ACTIONS,
    CITIZENS_GETTERS,
} from "@/app/store/modules/citizens";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { CitizensFilter } from "@/app/types/citizens";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import {
    ConstituenciesListRule,
    PollingStationsListRule,
    StreetsListRuleIds,
} from "@/app/types/filters";
import { LoaderState } from "@/app/types/loaderState";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";

@Component({
    components: {
        Dropdown,
        StreetFilterInputField,
        Button,
        CityFilterInputField,
    },
})
export default class CitizensFilterDropdownInner extends Vue {
    private districtOption: DropdownItemModel = this.moduleCursor.district;
    private constituencyOption: DropdownItemModel =
        this.moduleCursor.constituency;
    private pollingStationOption: DropdownItemModel =
        this.moduleCursor.pollingStation;

    private get cityOption(): DropdownItemModel {
        return this.moduleCursor.city;
    }

    private get street(): string {
        return this.moduleCursor.streetName;
    }

    private get moduleCursor(): CitizensFilter {
        return this.$store.getters[CITIZENS_GETTERS.CITIZENS_CURSOR];
    }

    private get constituenciesListRule(): ConstituenciesListRule {
        return new ConstituenciesListRule(
            this.$store.state.citizensModule.cursor.filter.city.id,
            this.$store.state.citizensModule.cursor.filter.district.id
        );
    }

    private get pollingStationsListRule(): PollingStationsListRule {
        return new PollingStationsListRule(
            this.$store.state.citizensModule.cursor.filter.city.id,
            this.$store.state.citizensModule.cursor.filter.district.id,
            this.$store.state.citizensModule.cursor.filter.constituency.id
        );
    }

    private get streetsListRule(): StreetsListRuleIds {
        return new StreetsListRuleIds(
            this.$store.state.citizensModule.cursor.filter.city.id,
            this.$store.state.citizensModule.cursor.filter.district.id,
            this.$store.state.citizensModule.cursor.filter.constituency.id,
            this.$store.state.citizensModule.cursor.filter.pollingStation.id
        );
    }

    private get districtsList(): DropdownItemModel[] {
        return this.$store.state.filtersModule.districtsList;
    }

    private get constituenciesList(): DropdownItemModel[] {
        return this.$store.state.filtersModule.constituenciesList.map(
            (item) => item.constituency
        );
    }

    private get pollingStationsList(): DropdownItemModel[] {
        return this.$store.state.filtersModule.pollingStationsList;
    }

    private get isDistrictsFilterDisabled(): boolean {
        return !this.$store.state.citizensModule.cursor.filter.city.id;
    }

    private get isConstituenciesFilterDisabled(): boolean {
        return !this.$store.state.citizensModule.cursor.filter.city.id;
    }

    private get isPollingStationsFilterDisabled(): boolean {
        return !this.$store.state.citizensModule.cursor.filter.city.id;
    }

    private get isStreetFilterDisabled(): boolean {
        return !this.$store.state.citizensModule.cursor.filter.city.id;
    }

    private async selectCity(value: DropdownItemModel): Promise<void> {
        if (this.cityOption.id === value.id) {
            return;
        }
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_CITY_RULE,
            value
        );

        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_DISTRICT_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_CONSTITUENCY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_POLLING_STATION_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_STREET_NAME_RULE,
            ""
        );

        if (!value.id) {
            return;
        }

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_DISTRICTS_LIST,
            value.id
        );
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            this.constituenciesListRule
        );
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_POLLING_STATIONS_LIST,
            this.pollingStationsListRule
        );
    }

    private async selectDistrict(value): Promise<void> {
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_DISTRICT_RULE,
            value
        );

        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_CONSTITUENCY_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_POLLING_STATION_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_STREET_NAME_RULE,
            ""
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
            this.constituenciesListRule
        );
        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_POLLING_STATIONS_LIST,
            this.pollingStationsListRule
        );
    }

    private async selectConstituency(value): Promise<void> {
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_CONSTITUENCY_RULE,
            value
        );

        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_POLLING_STATION_RULE,
            new DropdownItemModel()
        );
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_STREET_NAME_RULE,
            ""
        );

        await this.$store.dispatch(
            FILTERS_ACTIONS.GET_POLLING_STATIONS_LIST,
            this.pollingStationsListRule
        );
    }

    private async selectPollingStation(value): Promise<void> {
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_POLLING_STATION_RULE,
            value
        );

        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_STREET_NAME_RULE,
            ""
        );
    }

    private async selectStreet(value): Promise<void> {
        await this.$store.dispatch(
            CITIZENS_ACTIONS.SET_CITIZENS_STREET_NAME_RULE,
            value
        );
    }

    private async resetFilters(): Promise<void> {
        await this.$store.dispatch(CITIZENS_ACTIONS.RESET_CITIZENS_FILTERS);
        await this.$store.dispatch(CITIZENS_ACTIONS.RESET_CITIZENS_CURSOR);
        await this.filter();
    }

    private async filter(): Promise<void> {
        await this.$store.dispatch(CITIZENS_ACTIONS.RESET_CITIZENS_CURSOR);

        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            await this.$store.dispatch(CITIZENS_ACTIONS.GET_CITIZENS_LIST);
            this.$emit("toggleLoader", true);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.$emit("toggleLoader", false);
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState().Bounded()
        );
        this.$emit("toggleLoader", false);

        setTimeout(async () => {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY,
                false
            );
        }, 600);
    }

    @Watch("moduleCursor", { immediate: true, deep: true })
    resetDefault() {
        this.districtOption = this.moduleCursor.district;
        this.constituencyOption = this.moduleCursor.constituency;
        this.pollingStationOption = this.moduleCursor.pollingStation;
    }
}
