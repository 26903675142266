

































import { Component, Vue } from "vue-property-decorator";

import FilterDropdown from "@/app/components/common/FilterDropdown.vue";
import Button from "@/app/components/common/Button.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Popup } from "@/app/types/popup";
import PartyCreatingWindow from "@/app/components/popups/PartyCreatingWindow.vue";
import PartiesFilterDropdownInner from "@/app/components/parties/PartiesFilterDropdownInner.vue";

@Component({
    components: { FilterDropdown, Button, PartiesFilterDropdownInner },
})
export default class PartiesFilterPanel extends Vue {
    private toggleLoader(value): void {
        if (value) {
            this.$emit("toggleLoader", true);

            return;
        }

        setTimeout(() => {
            this.$emit("toggleLoader", false);
        }, 700);
    }

    private openCreatingPopup(): void {
        this.$store.dispatch(
            APPSTATE_ACTIONS.SET_POPUP_STATE,
            new Popup(PartyCreatingWindow.componentName, true, false)
        );
    }
}
