










import { Component, Emit, Prop, Vue } from "vue-property-decorator";

import { DropdownItemModel } from "@/app/types/dropdownItemModel";

@Component({})
export default class DropdownItem extends Vue {
    @Prop({})
    private readonly option: DropdownItemModel;

    @Prop({ default: false })
    private readonly active: boolean;

    @Prop({ default: false })
    private readonly borderShort: boolean;

    private select(): void {
        this.$emit("select");
    }
}
