
















import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Button extends Vue {
    @Prop({ default: "" })
    private readonly label: string;

    @Prop({ default: false })
    private readonly hasIcon: boolean;

    @Prop({ default: false })
    private readonly disabled: boolean;

    @Prop({ default: "middle" })
    private readonly size: string;

    @Prop({ default: "default" })
    private readonly type: string;

    @Prop({ default: "" })
    private readonly display: string;

    private get containerClassList(): string {
        const classList: string[] = [];

        switch (this.size) {
            case "small":
                classList.push("button_sm");
                break;
            case "middle":
                classList.push("button_md");
                break;
            case "large":
                classList.push("button_lg");
                break;
        }

        switch (this.type) {
            case "default":
                classList.push("button_default");
                break;
            case "cta":
                classList.push("button_cta");
                break;
            case "primary":
                classList.push("button_primary");
                break;
            case "cancel":
                classList.push("button_cancel");
                break;
        }

        switch (this.display) {
            case "inline":
                classList.push("button_inline");
                break;
            case "full":
                classList.push("button_full");
                break;
        }

        return classList.join(" ");
    }

    private clickHandler(e): void {
        this.$emit("click", e);
    }
}
