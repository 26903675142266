import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { Cursor } from "@/app/types/cursor";
import { StreetModel } from "@/app/types/streetModel";
import { Page } from "@/app/types/page";

export class FiltersListState {
    public constructor(
        public citiesState: CitiesState = new CitiesState(),
        public districtsList: DropdownItemModel[] = [],
        public constituenciesList: ConstituencyDetailed[] = [],
        public pollingStationsList: DropdownItemModel[] = [],
        public streetsState: StreetsState = new StreetsState(),
        public districtEditingRule: DistrictEditingRule = new DistrictEditingRule(),
        public constituencyEditingRule: ConstituencyEditingRule = new ConstituencyEditingRule(),
        public cursor: AddressPageCursor = new AddressPageCursor()
    ) {}
}

export class ConstituencyDetailed {
    public constructor(
        public constituency: DropdownItemModel = new DropdownItemModel(),
        public constituencyGeneral: string = "",
        public districtBelonging: DropdownItemModel[] = [],
        public relatedDistrictBelonging: DropdownItemModel = new DropdownItemModel()
    ) {}
}

export class CitiesState {
    public constructor(
        public list: DropdownItemModel[] = [],
        public cursor: CitiesCursor = new CitiesCursor(),
        public page: Page = new Page()
    ) {}
}

export class StreetsState {
    public constructor(
        public list: StreetModel[] = [],
        public cursor: StreetsCursor = new StreetsCursor(),
        public page: Page = new Page()
    ) {}
}

export class PollingStationsListRule {
    public constructor(
        public cityId: string = "",
        public districtId: string = "",
        public constituencyId: string = ""
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        if (!isEmpty(this.cityId)) {
            a.append("city_id", this.cityId);
        }

        if (!isEmpty(this.districtId)) {
            a.append("district_id", this.districtId);
        }

        if (!isEmpty(this.constituencyId)) {
            a.append("constituency_id", this.constituencyId);
        }

        return a.toString();
    }
}

export class CitiesListRule {
    public constructor(public inputValue: string = "") {}
}

export class CitiesPage {
    public constructor(
        public list: DropdownItemModel[] = [],
        public page: Page = new Page()
    ) {}
}

export class CitiesCursor {
    public constructor(
        public inputValue: string = "",
        public cursor: Cursor = new Cursor()
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        if (!isEmpty(this.inputValue)) {
            a.append("search_name", this.inputValue);
        }

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

export class StreetsListRule {
    public constructor(
        public ids: StreetsListRuleIds = new StreetsListRuleIds(),
        public inputValue: string = ""
    ) {}
}

export class StreetsListRuleIds {
    public constructor(
        public cityId: string = "",
        public districtId: string = "",
        public constituencyId: string = "",
        public pollingStationId: string = ""
    ) {}
}

export class StreetsPage {
    public constructor(
        public list: StreetModel[] = [],
        public page: Page = new Page()
    ) {}
}

export class StreetsCursor {
    public constructor(
        public cityId: string = "",
        public districtId: string = "",
        public constituencyId: string = "",
        public pollingStationId: string = "",
        public inputValue: string = "",
        public cursor: Cursor = new Cursor()
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        if (!isEmpty(this.cityId)) {
            a.append("city_id", this.cityId);
        }

        if (!isEmpty(this.districtId)) {
            a.append("district_id", this.districtId);
        }

        if (!isEmpty(this.constituencyId)) {
            a.append("constituency_id", this.constituencyId);
        }

        if (!isEmpty(this.pollingStationId)) {
            a.append("polling_station_id", this.pollingStationId);
        }

        if (!isEmpty(this.inputValue)) {
            a.append("address", this.inputValue);
        }

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

export class DistrictEditingRule {
    public constructor(
        public id: string = "",
        public districtNewName: string = ""
    ) {}
}

export class ConstituencyEditingRule {
    public constructor(
        public id: string = "",
        public district: DropdownItemModel = new DropdownItemModel(),
        public constituencyNewNumber: number = 0
    ) {}
}

export class ConstituenciesListRule {
    public constructor(
        public cityId: string = "",
        public districtId: string = ""
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        if (!isEmpty(this.cityId)) {
            a.append("city_id", this.cityId);
        }

        if (!isEmpty(this.districtId)) {
            a.append("district_id", this.districtId);
        }

        return a.toString();
    }
}

export class AddressPageCursor {
    public constructor(
        public cursor: Cursor = new Cursor(),
        public city: DropdownItemModel = new DropdownItemModel()
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        a.append("city_id", String(this.city.id));

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

function isEmpty(s: string): boolean {
    return s == null || s.length == 0;
}

export interface FiltersApi {
    getCities(cursor: CitiesCursor): Promise<CitiesPage>;

    getDistricts(cityId: string): Promise<DropdownItemModel[]>;

    getConstituencies(
        rule: ConstituenciesListRule
    ): Promise<ConstituencyDetailed[]>;

    editDistrict(rule: DistrictEditingRule): Promise<boolean>;

    editConstituency(rule: ConstituencyEditingRule): Promise<boolean>;

    getPollingStations(
        rule: PollingStationsListRule
    ): Promise<DropdownItemModel[]>;

    getStreets(cursor: StreetsCursor): Promise<StreetsPage>;
}
