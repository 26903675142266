import { Page } from "@/app/types/page";
import { Cursor } from "@/app/types/cursor";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";

export class Manager {
    public constructor(
        public id: string = "",
        public deputyId: string = "",
        public deputyName: string = "",
        public role: number = 1,
        public login: string = "",
        public firstName: string = "",
        public middleName: string = "",
        public lastName: string = "",
        public isFinishedRegistration: boolean = false
    ) {}

    public static fromJson(obj: any): Manager {
        return new Manager(
            obj.id,
            obj.deputy_id,
            obj.deputy_full_name,
            obj.role,
            obj.login,
            obj.first_name,
            obj.middle_name,
            obj.last_name,
            obj.is_finished_registration
        );
    }
}

export class ManagersState {
    public constructor(
        public managers: Manager[] = [],
        public creationRule: ManagerCreationRule = new ManagerCreationRule(),
        public editingRule: ManagerEditingRule = new ManagerEditingRule(),
        public deletingRule: ManagerDeletingRule = new ManagerDeletingRule(),
        public page: Page = new Page(),
        public cursor: ManagersCursor = new ManagersCursor()
    ) {}
}

export class ManagerCreationRule {
    public constructor(
        public deputy: DropdownItemModel = new DropdownItemModel(),
        public login: string = "",
        public role: number = 1,
        public firstName: string = "",
        public middleName: string = "",
        public lastName: string = ""
    ) {}

    public toJson(): string {
        const obj = {
            deputy_id: this.deputy.id,
            login: this.login,
            role: this.role,
            first_name: this.firstName,
            middle_name: this.middleName,
            last_name: this.lastName,
        };

        return JSON.stringify(obj);
    }
}

export class ManagerEditingRule {
    public constructor(
        public id: string = "",
        public role: number = 1,
        public firstName: string = "",
        public middleName: string = "",
        public lastName: string = "",
        public isFinishedRegistration: boolean = false
    ) {}

    public toJson(): string {
        const obj = {
            role: this.role,
            first_name: this.firstName,
            middle_name: this.middleName,
            last_name: this.lastName,
        };

        return JSON.stringify(obj);
    }
}

export class ManagerDeletingRule {
    public constructor(public id: string = "") {}
}

export class ManagersPage {
    public constructor(
        public managers: Manager[] = [],
        public page: Page = new Page()
    ) {}
}

export class ManagersCursor {
    public constructor(
        public cursor: Cursor = new Cursor(),
        public city: DropdownItemModel = new DropdownItemModel(),
        public deputy: DropdownItemModel = new DropdownItemModel()
    ) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        if (!isEmpty(this.city.id)) {
            a.append("city_id", String(this.city.id));
        }

        if (!isEmpty(this.deputy.id)) {
            a.append("deputy_id", String(this.deputy.id));
        }

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

function isEmpty(s: string | number): boolean {
    return !s || String(s).length === 0;
}

export interface ManagersApi {
    list(cursor: ManagersCursor): Promise<ManagersPage>;

    create(rule: ManagerCreationRule): Promise<boolean>;

    edit(rule: ManagerEditingRule): Promise<boolean>;

    delete(rule: ManagerDeletingRule): Promise<boolean>;
}
