import { Page } from "@/app/types/page";
import { Cursor } from "@/app/types/cursor";

export class DeletedCitizensState {
    public constructor(
        public deletedCitizens: DeletedCitizen[] = [],
        public cursor: DeletedCitizensCursor = new DeletedCitizensCursor(),
        public page: Page = new Page()
    ) {}
}

export class DeletedCitizen {
    public constructor(
        public deletedCitizenId: string = "",
        public deletedAt: string = "",
        public explanation: string = ""
    ) {}

    public static fromJson(obj: any): DeletedCitizen {
        return new DeletedCitizen(
            obj.citizen_id,
            obj.deleted_at,
            obj.explanation
        );
    }
}

export class DeletedCitizensPage {
    public constructor(
        public deletedCitizens: DeletedCitizen[] = [],
        public page: Page = new Page()
    ) {}
}

export class DeletedCitizensCursor {
    public constructor(public cursor: Cursor = new Cursor()) {}

    public toQuery(): string {
        const a = new URLSearchParams();

        this.cursor.toURLParams().forEach((value, key) => a.append(key, value));

        return a.toString();
    }
}

function isEmpty(s: string | number): boolean {
    return !s || String(s).length === 0;
}

export interface DeletedCitizensApi {
    list(cursor: DeletedCitizensCursor): Promise<DeletedCitizensPage>;
}
