
























import { Component, Vue, Watch } from "vue-property-decorator";

import Button from "@/app/components/common/Button.vue";
import Dropdown from "@/app/components/common/Dropdown.vue";

import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { FILTERS_ACTIONS } from "@/app/store/modules/filters";
import { DropdownItemModel } from "@/app/types/dropdownItemModel";
import { ConstituenciesListRule } from "@/app/types/filters";
import { LoaderState } from "@/app/types/loaderState";
import CityFilterInputField from "@/app/components/common/CityFilterInputField.vue";

@Component({ components: { Dropdown, CityFilterInputField, Button } })
export default class AddressesFilterDropdownInner extends Vue {
    private get cityOption(): DropdownItemModel {
        return this.$store.state.filtersModule.cursor.city;
    }

    private get constituenciesListRule(): ConstituenciesListRule {
        return new ConstituenciesListRule(String(this.cityOption.id), "");
    }

    private async selectCity(value): Promise<void> {
        if (this.cityOption.id === value.id) {
            return;
        }

        await this.$store.dispatch(
            FILTERS_ACTIONS.SET_ADDRESS_PAGE_CURSOR_CITY_RULE,
            value
        );
    }

    private async filter(): Promise<void> {
        try {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Visible().Bounded()
            );
            await this.$store.dispatch(
                FILTERS_ACTIONS.GET_DISTRICTS_LIST,
                this.cityOption.id
            );
            await this.$store.dispatch(
                FILTERS_ACTIONS.GET_CONSTITUENCIES_LIST,
                this.constituenciesListRule
            );
            this.$emit("toggleLoader", true);
        } catch (e) {
            await this.$store.dispatch(
                APPSTATE_ACTIONS.SET_LOADING_STATE,
                new LoaderState().Bounded()
            );
            this.$emit("toggleLoader", false);
            console.error(e);

            return;
        }

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_LOADING_STATE,
            new LoaderState().Bounded()
        );
        this.$emit("toggleLoader", false);

        await this.$store.dispatch(
            APPSTATE_ACTIONS.SET_FILTERS_PANEL_VISIBILITY,
            false
        );
    }
}
