import { render, staticRenderFns } from "./DeletingReasonsPage.vue?vue&type=template&id=27fbad1e&scoped=true&"
import script from "./DeletingReasonsPage.vue?vue&type=script&lang=ts&"
export * from "./DeletingReasonsPage.vue?vue&type=script&lang=ts&"
import style0 from "./DeletingReasonsPage.vue?vue&type=style&index=0&id=27fbad1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27fbad1e",
  null
  
)

export default component.exports