





































import SignOutWindow from "@/app/components/popups/SignOutWindow.vue";
import { APPSTATE_ACTIONS } from "@/app/store/modules/appState";
import { Component, Vue, Watch } from "vue-property-decorator";
import PartyCreatingWindow from "@/app/components/popups/PartyCreatingWindow.vue";
import PartyEditingWindow from "@/app/components/popups/PartyEditingWindow.vue";
import PartyDeletingWindow from "@/app/components/popups/PartyDeletingWindow.vue";
import DeputyCreatingWindow from "@/app/components/popups/DeputyCreatingWindow.vue";
import DeputyDeletingWindow from "@/app/components/popups/DeputyDeletingWindow.vue";
import DeputyEditingWindow from "@/app/components/popups/DeputyEditingWindow.vue";
import ManagerCreatingWindow from "@/app/components/popups/ManagerCreatingWindow.vue";
import ManagerDeletingWindow from "@/app/components/popups/ManagerDeletingWindow.vue";
import ManagerEditingWindow from "@/app/components/popups/ManagerEditingWindow.vue";
import DistrictEditingWindow from "@/app/components/popups/DistrictEditingWindow.vue";
import ConstituencyEditingWindow from "@/app/components/popups/ConstituencyEditingWindow.vue";
import ReportApplicationWindow from "@/app/components/popups/ReportApplicationWindow.vue";
import ReportActivationApprovalWindow from "@/app/components/popups/ReportActivationApprovalWindow.vue";
import ReportDeactivationApprovalWindow from "@/app/components/popups/ReportDeactivationApprovalWindow.vue";
import ReportMaterialRemovingApprovalWindow from "@/app/components/popups/ReportMaterialRemovingApprovalWindow.vue";
import ReportFullTextWindow from "@/app/components/popups/ReportFullTextWindow.vue";
import PhoneDeletingWindow from "@/app/components/popups/PhoneDeletingWindow.vue";

@Component({
    components: {
        SignOutWindow,
        PartyCreatingWindow,
        PartyDeletingWindow,
        PartyEditingWindow,
        DeputyCreatingWindow,
        DeputyEditingWindow,
        DeputyDeletingWindow,
        ManagerCreatingWindow,
        ManagerEditingWindow,
        ManagerDeletingWindow,
        DistrictEditingWindow,
        ConstituencyEditingWindow,
        ReportApplicationWindow,
        ReportActivationApprovalWindow,
        ReportDeactivationApprovalWindow,
        ReportMaterialRemovingApprovalWindow,
        ReportFullTextWindow,
        PhoneDeletingWindow,
    },
})
export default class Popup extends Vue {
    private get visibility(): boolean {
        return this.$store.state.appStateModule.popup.visibility;
    }

    private get component(): string {
        return this.$store.state.appStateModule.popup.component;
    }

    private get shift(): boolean {
        return this.$store.state.appStateModule.popup.shift;
    }

    private close(): void {
        this.$store.dispatch(APPSTATE_ACTIONS.SET_POPUP_STATE, {
            visibility: false,
            shift: this.shift,
        });
    }
}
